import React from "react";

import { Route as Router, Redirect } from "react-router-dom";

export default function Route(props) {
  if (props.private && !localStorage.getItem("@TOKEN")) {
    return <Redirect to="/login" />;
  }

  if (props.unprivate && localStorage.getItem("@TOKEN")) {
    return <Redirect to="/" />;
  }

  return <Router {...props} />;
}

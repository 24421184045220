import "./aside.css";
import { useContext, useEffect, useState } from "react";
import { FunctionContext as Context } from "../../context";
import { FaPowerOff } from "react-icons/fa";
import { Link, useParams, useRouteMatch } from "react-router-dom";

export default function Aside() {
  const [selected, setSelected] = useState([null]);
  const context = useContext(Context);
  const params = useParams();

  useEffect(() => {
    const location = window.location.pathname;
    const splitLocation = location.split("/");

    switch (splitLocation[1]) {
      case "orders":
        setSelected(["orders", splitLocation[2]]);
        break;

      default:
        setSelected([splitLocation[1], splitLocation[2]]);
        break;
    }
  }, [params]);
  return (
    <aside
      className="w-100 h-100 bg-dark text-light pt-0 aside px-0"
      style={{ minHeight: "90vh" }}
    >
      <div className="d-flex flex-column">
        <Link
          style={{
            background: useRouteMatch("/my-account/orders")
              ? "#369f9b"
              : "transparent",
          }}
          to="/my-account/orders?page=1"
          className="my-2 px-2 font-weight-bold text-light py-2"
        >
          Orders
        </Link>
        <Link
          style={{
            background: useRouteMatch("/my-account/my-mares")
              ? "#369f9b"
              : "transparent",
          }}
          to="/my-account/my-mares"
          className="my-2 px-2 font-weight-bold text-light py-2"
        >
          My mares
        </Link>
        <Link
          style={{
            background: useRouteMatch("/my-account/my-address")
              ? "#369f9b"
              : "transparent",
          }}
          to="/my-account/my-address"
          className="my-2 px-2 font-weight-bold text-light py-2"
        >
          Return Ordering
        </Link>
        <Link
          style={{
            background: useRouteMatch("/my-account/settings")
              ? "#369f9b"
              : "transparent",
          }}
          to="/my-account/settings"
          className="my-2 px-2 font-weight-bold text-light py-2"
        >
          Settings
        </Link>
        <Link to="/" className="my-2 px-2 font-weight-bold text-light py-2">
          Return shopping
        </Link>
        <Link
          className="my-2 px-2 font-weight-bold text-danger"
          onClick={() => localStorage.clear()}
        >
          <FaPowerOff color="#dc3545" className="mr-1" />
          Logout
        </Link>
      </div>
    </aside>
  );
}

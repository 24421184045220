import { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SuccesPayment from "../../Components/PayStatus/Success";
import RefusedPayment from "../../Components/PayStatus/Refused";
import PendingPayment from "../../Components/PayStatus/Pending";
import { FunctionContext } from "../../context";
import api from "../../services/api";

export default function PayRest() {
  // const { information } = useParams();
  const context = useContext(FunctionContext);
  const [hasError, setHasError] = useState(false);
  const information = new URLSearchParams(window.location.search).get(
    "redirect_status"
  );
  useEffect(() => {
    (async () => {
      const paymentData = await JSON.parse(
        await localStorage.getItem("@PAYMENT_DATA")
      );

      console.log(
        paymentData,
        await localStorage.getItem("@PAYMENT_DATA"),
        Object.keys(paymentData || {}).length <= 1,
        Object.keys(paymentData || {})
      );

      if (Object.keys(paymentData || {}).length <= 1) {
        setHasError(true);
      } else {
        await api
          .post("/api/pagamentos/criar", {
            ...paymentData,
            information:
              information === "succeeded"
                ? "authorised"
                : information === "failed"
                ? "canceled"
                : information === "pending"
                ? "pending"
                : "",
          })
          .finally(async (res) => {
            await localStorage.removeItem("@PAYMENT_DATA");
          });
        console.log(paymentData);
        setTimeout(() => {
          window.location.href = "fastsemen://app";
        }, 5000);
      }
    })();
  }, []);
  return (
    <>
      <Header />
      {!hasError ? (
        <>
          {information === "succeeded" ? (
            <SuccesPayment />
          ) : information === "failed" ? (
            <RefusedPayment />
          ) : information === "pending" ? (
            <PendingPayment />
          ) : information === "received" ? (
            <PendingPayment />
          ) : (
            ""
          )}
        </>
      ) : (
        <RefusedPayment />
      )}

      <Footer />
    </>
  );
}

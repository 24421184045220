import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <h2 className="mb-5 text-center">Privacy Policy </h2>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Fast Semen LLC is committed to protecting your privacy online. This
          Privacy Policy explains why and how we collect and use your Personal
          Information and what control you have over your Personal Information.
          <br />
          <br />
          Your use of our Platform is subject to our Terms of Service, and this
          Privacy Policy will include some terms that are defined and used in
          the Terms of Service. Please carefully read the Terms of Service
          before you use our Platform to ensure that you understand those terms
          and this Privacy Policy. When you use our Platform, you accept and
          agree to the Terms of Service and this Privacy Policy.
          <br />
          <br />
          We may update the Terms of Service and this Privacy Policy at any time
          without prior notice to you; however, we will notify you if we plan to
          change the way we collect or use your Personal Information. You accept
          and agree to any changes to our Privacy Policy if you continue to use
          our Platform.
          <br />
          <br />
          This Privacy Policy is effective as of February 3, 2021.
          <br />
          <br />
          <h4>INFORMATION WE COLLECT</h4>
          <br />
          <br />
          In order for you to use our Platform, we ask you to provide Personal
          Information, such as your name, e-mail address, personal or work
          telephone number, delivery address, billing address, and credit card
          or bank information. If any of your Personal Information changes, it
          is your responsibility to promptly update your Account with the new
          information to avoid any delays or mistakes in the services available
          through our Platform.
          <br />
          <br />
          We only collect Personal Information from you that is necessary for us
          to provide you access to and use of our Platform.
          <br />
          <br />
          <h4>USE OF YOUR PERSONAL INFORMATION</h4>
          <br />
          <br />
          We may use your Personal Information to allow you secure access to and
          use of our Platform to search the Marketplace, connect with Providers,
          place Orders, and process payments. We may also use your Personal
          Information to communicate with you and to enforce compliance with our
          Terms of Service and other policies.
          <br />
          <br />
          At no time will we provide or sell your Personal Information to a
          third party, except that your Personal Information may be shared with
          Providers and our payment processor to the extent necessary for your
          communication or financial transaction using our Platform.
          <br />
          <br />
          We many also share your Personal Information, only to the extent
          necessary, when we reasonably believe it is necessary to (i) satisfy
          any applicable law, regulation, legal process, or governmental
          request, (ii) detect, prevent, or otherwise address fraud, security,
          or technical issues, (iii) respond to Customer support requests, or
          (iv) protect the rights, property, or safety of Fast Semen, its users,
          and the public.
          <br />
          <br />
          <h4>RETENTION OF PERSONAL INFORMATION</h4>
          <br />
          <br />
          We will retain your Personal Information for as long as your Account
          is active or as needed to provide you services and to maintain a
          record of your transactions for financial reporting purposes. We will
          also retain and use your Personal Information as necessary to comply
          with our legal obligations, resolve disputes, and enforce our
          agreements. USE OF CUSTOMER CONTENT
          <br />
          <br />
          We may provide you with interactive opportunities through the
          Platform, including, for example, the ability to post Customer ratings
          and reviews. By posting Customer Content, you grant us a license to
          use your name or username or other Customer profile information,
          including your ratings history, to attribute Customer Content to you
          in connection with our Uses. This license includes the right for other
          Customers to access and use your Customer Content when they use our
          Platform. We may also access, read, retain, and share any of your
          Customer Content as we reasonably believe is necessary to (i) satisfy
          any applicable law, regulation, legal process, or governmental
          request, (ii) enforce this Agreement, including investigation of
          potential violations hereof, (iii) detect, prevent, or otherwise
          address fraud, security, or technical issues, (iv) respond to Customer
          support requests, or (e) protect the rights, property, or safety of
          Fast Semen, its users, and the public. The license you grant us to use
          your Customer Content will survive termination of the Platform or your
          Account.
          <br />
          <br />
          <h4>
            YOUR CONTROL OVER YOUR PERSONAL INFORMATION AND CUSTOMER CONTENT
          </h4>
          <br />
          <br />
          Your Personal Information and Customer Content are your property. You
          and your authorized agents may access and correct or delete your
          Personal Information and Customer Content by logging into your
          Account. In addition, you may request that we correct or delete any
          Personal Information we have collected and retain about you.
          <br />
          <br />
          If you would like to know what information we have collected and
          retain about you, or if you would like to request that we correct or
          delete information that we retain about you, please email us at
          contact@fastsemen.com or call +1 936 662 6920. We may require that you
          provide us with certain Personal Information to allow us to verify
          your identity before we take action on your request.
          <br />
          <br />
          In some instances, we may be unable to delete your Personal
          Information. We may retain certain Personal Information if it is
          required to:
          <br />
          <br />
          <div className="ml-3">
            (i) complete the transaction for which the Personal Information was
            collected, fulfill the terms of a written warranty or product recall
            conducted in accordance with federal law, provide a good or service
            requested by you, or reasonably anticipated within the context of
            our ongoing business relationship with you, or otherwise perform a
            contract between Fast Semen and you;
            <br />
            <br />
            (ii) detect security incidents, protect against malicious,
            deceptive, fraudulent, or illegal activity; or prosecute those
            responsible for that activity;
            <br />
            <br />
            (iii) debug to identify and repair errors that impair existing
            intended functionality;
            <br />
            <br />
            (iv) exercise free speech, ensure the right of another consumer to
            exercise that consumer’s right of free speech, or exercise another
            right provided for by law;
            <br />
            <br />
            (v) comply with the California Electronic Communications Privacy Act
            pursuant to Chapter 3.6 (commencing with Section 1546) of Title 12
            of Part 2 of the Penal Code; or to comply with any other state or
            federal law;
            <br />
            <br />
            (vi) engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when our deletion of the
            information is likely to render impossible or seriously impair the
            achievement of such research, if you have provided informed consent;
            <br />
            <br />
            (vii) enable solely internal uses that are reasonably aligned with
            your expectations based on the your relationship with the Fast
            Semen;
            <br />
            <br />
            (viii) comply with a legal obligation.
            <br />
            <br />
            (ix) otherwise use the Personal Information, internally, in a lawful
            manner that is compatible with the context in which you provided the
            information.
            <br />
            <br />
          </div>
          <br />
          <br />
          We do not discriminate against anyone for choosing to exercise their
          rights in their Personal Information and Customer Content. You may
          continuing using the Platform after deleting such information, but
          please be aware that some uses of the Platform may not be possible
          without certain Personal Information.
          <br />
          <br />
          <h4>LINKS TO THIRD PARTY SITES</h4>
          <br />
          <br />
          Our Platform may include links to other websites (“linked sites”).
          Including a link to linked site is not an endorsement by Fast Semen of
          that linked site and does not indicate that we are associated with its
          operators. The linked sites are not controlled by Fast Semen, and we
          are not responsible for their content, privacy policies, practices, or
          terms of service. Please familiarize yourself with the policies and
          practices of linked sites you visit.
          <br />
          <br />
          <h4>SECURITY</h4>
          <br />
          <br />
          We care about the security of your Personal Information and our
          Platform. We have implemented reasonable security procedures and
          practices to protect your Personal Information from unauthorized or
          illegal access, destruction, use, modification, or disclosure. Please
          be aware that despite our efforts to protect your Personal
          Information, no system is ever perfectly secure or free of errors. We
          will alert you if there has been any breach of our system that may
          expose your Personal Information.
          <br />
          <br />
          You also have an obligation and the opportunity to safeguard your
          Personal Information when you are online and when you are using the
          Platform. Please select secure passwords and change your password
          regularly. Please ensure that the device you are using to access the
          Platform has appropriate protections against security breaches, such
          as malware.
          <br />
          <br />
          If you have reason to believe that your Account is compromised or is
          being used by an unauthorized person, or if you believe the Platform
          is not secure, please promptly contact us and let us know of your
          concern.
          <br />
          <br />
          <h4>APPLICABLE LAW</h4>
          <br />
          <br />
          Fast Semen LLC is a Texas company based in the United States. We are
          subject to and follow the laws of the United States. By using our
          Platform, you consent to our Privacy Policy and the privacy laws of
          the United States regardless of which country you are in.
          <br />
          <br />
          <h4>CONTACT US</h4>
          <br />
          <br />
          Please feel free to contact us if you have questions about our Privacy
          Policy, or if you would like to know more about, correct, or delete
          your Personal Information we have collected.
          <br />
          <br />
          Email: Contact@fastsemen.com
          <br />
          <br />
          Phone: +1 936 662 6920
          <br />
          <br />
          Address: 330 Lookout Pt
          <br />
          <br />
          Stephenville, TX
          <br />
          <br />
          76401
        </p>
      </div>
      <Footer />
    </>
  );
}

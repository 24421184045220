import { useEffect, useState, useContext } from "react";
import api from "../../services/api";

import Aside from "../../Components/Aside";
import Header from "../../Components/Header";
import { Spinner } from "react-bootstrap";
import { FaPenSquare } from "react-icons/fa";
import Button from "../../Components/Button";
import ModalDataUser from "../../Components/Modals/ModalDataUser";
import { FunctionContext as Context } from "../../context";

export default function Settings() {
  const context = useContext(Context);
  const [infortationsUser, setInformationsUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mostConfigs, setMostConfigs] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      context.setShowAside(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get("/usuario").catch(() => {
        setLoading(false);
        window.location.reload();
      });
      setInformationsUser(data);
      setLoading(false);
    })();
  }, []);

  async function getUser() {
    setLoading(true);
    const { data } = await api.get("/usuario").catch(() => {
      setLoading(false);
      window.location.reload();
    });
    setInformationsUser(data);
    setLoading(false);
  }
  return (
    <>
      <Header />
      <ModalDataUser
        show={mostConfigs}
        onHide={() => {
          setMostConfigs(false);
          getUser();
        }}
      />
      <div className="d-flex" style={{ minHeight: "90vh" }}>
        <div
          className="bg-dark col-12 col-sm-3 col-lg-2 px-0"
          style={{
            display: context.showAside ? "none" : "block",
          }}
        >
          <Aside />
        </div>
        <main
          className={
            context.showAside
              ? "p-3 px-5 bg-light col-12 h-100"
              : "d-none d-sm-block p-3 px-5 bg-light col-12 col-sm-9 col-lg-10 h-100"
          }
          style={{ minHeight: "90vh" }}
        >
          <h3 className="text-capitalize">Settings</h3>
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <>
              <div
                style={{ background: "#fff" }}
                className="p-3 px-0 px-md-5 mt-5"
              >
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setMostConfigs(true)}>
                    Edit Information
                    <FaPenSquare size={30} className="ml-2" />
                  </Button>
                </div>
                <div className="table-responsive">
                  {infortationsUser.length >= 1 &&
                  Object.keys(infortationsUser[0]) ? (
                    <>
                      {infortationsUser?.map((item) => {
                        const keys = Object.keys(item);
                        const values = Object.values(item);
                        return (
                          <table className="w-100 my-4">
                            <thead className="bg-dark font-weight-bold text-light text-capitalize">
                              <tr>
                                {keys.map((key) => {
                                  if (key === "telefoneEwhatsapp") return;
                                  return (
                                    <th className="col-3">
                                      {key === null
                                        ? "None"
                                        : String(key)
                                            .replace("_", " ")
                                            .replace("_", " ")}
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {values.map((val,indxVal) => {
                                  if (indxVal === 3) return;
                                  return (
                                    <td className="col-3">
                                      {val === null
                                        ? "None"
                                        : String(val)
                                            .replace("_", " ")
                                            .replace("_", " ")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </table>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}
        </main>
      </div>
    </>
  );
}

import "./styles.css";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import AsideFilter from "../../Components/AsideFilter";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import api from "../../services/api";
import { FunctionContext as Context } from "../../context";
import ModalAdicionais from "../../Components/ModalFiltroCavalo/Adicionais";
import CookieBanner from 'react-cookie-banner';

export default function SearchResulsts() {
  const {
    configFilter: DataSearch,
    setConfigFilter,
    setSelectedAdicionais,
    setSelectedTipoEnvio,
    cavalo: cavaloContext,
    setCavalo: setCavaloContext,
    userToken,
  } = useContext(Context);
  const [garanhaoRes, setGaranhaoRes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cavalo, setCavalo] = useState({});
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      if (!!DataSearch.name) {
        const { data } = await api
          .get(`/search/garanhao/name/${DataSearch.name}`)
          .catch(() => {
            setLoading(false);
            window.location.reload();
          });
        setGaranhaoRes(data);
        setConfigFilter({});
      } else if (DataSearch.idgaranhao) {
        const { data } = await api
          .get(`/search/garanhao/idcavalo/${DataSearch.idgaranhao}`)
          .catch(() => {
            setLoading(false);
            window.location.reload();
          });
        setConfigFilter({});
        setGaranhaoRes(data);
      } else if (DataSearch.saller) {
        const { data } = await api
          .get(`/search/garanhao/vendedor/${DataSearch.saller}`)
          .catch(() => {
            setLoading(false);
            window.location.reload();
          });
        setGaranhaoRes(data);
        setConfigFilter({});
      }
      setLoading(false);
    })();
  }, [DataSearch]);
  return (
    <>
      <Header />
      {loading && (
        <div
          style={{ height: "20vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner animation="border" />
        </div>
      )}
       
      {!loading && garanhaoRes.length >= 1 && (
        
        <div className="w-100 my-5 grid-search-res  container">
          
          <div
            className="text-light px-4 align-self-start col-12"
            style={{ backgroundColor: "#e9e9e9" }}
          >
            {garanhaoRes.map((cav, index) => {
              return (
                <div
                  key={cav.idgaranhao}
                  onClick={() => {
                    if (!userToken) {
                      window.location.href = "/login";
                    }
                    setCavalo(cav);
                    setCavaloContext(cav);
                    setShowModal(true);
                    setSelectedAdicionais({});
                  }}
                  style={{ cursor: "pointer", backgroundColor: "#e9e9e9" }}
                >
                  {index > 0 && (
                    <hr
                      style={{
                        backgroundColor: "#000",
                        color: "#000",
                        margin: "auto",
                      }}
                    />
                  )}
                  <div
                    className="cavalo my-5 d-flex flex-column flex-lg-row"
                    style={{ backgroundColor: "#e9e9e9" , width:600}}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}${cav.diretorio}`}
                      className="col-12 col-md-4 align-items-start justify-items-start"
                      style={{ height: 200 }}
                    />
                    <div className="d-flex flex-column mt-3 mt-sm-0 col-sm-12 col-lg-8 ">
                      <div className="d-flex flex-column flex-md-row">
                        <div className="col-9 px-0">
                          <h3 className="text-dark">{cav.nome}</h3>
                          <p className="text-dark">
                            Stallion Station: <strong>{cav.vendedor}</strong>
                          </p>
                          <h6 className="text-dark col-10 ml-0 pl-0">
                            {cav.detalhes}
                          </h6>
                        </div>
                        <div className="d-flex flex-column-reverse flex-md-column mt-3 mt-md-0 ml-0 ml-md-auto">
                          <h5 className="text-dark d-flex text-nowrap">
                            US ${cav.chutefee.toFixed(2)}
                          </h5>
                          <button className="btn btn-outline-info mb-1 mb-md-3 mt-0 align-self-start ml-0 ml-md-auto">
                            Order Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}{" "}
      {!loading && garanhaoRes.length <= 0 && (
        <div className="grid">
          <AsideFilter />
          <h2 className="text-center mt-5">No results for your search</h2>
        </div>
      )}
      <Footer />
      {showModal && (
        <ModalAdicionais
          cavalo={cavalo}
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setSelectedAdicionais({});
            setCavaloContext({});
          }}
          quantidade={1}
          cart={false}
          imgModal={cavalo}
        />
      )}
    </>
  );
}

import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

export default function Conduct() {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <h2 className="mb-5 text-center">FAST SEMEN CODE OF CONDUCT</h2>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Fast Semen LLC (“Fast Semen”) is committed to operating in compliance
          with all applicable laws and regulations and to fostering a
          cooperative and beneficial relationship with and between all users of
          the Fast Semen Platform. To accomplish these objectives, we require
          all Providers and Customers, including their respective agents,
          employees, and other representatives, (collectively, “User”) to abide
          by this Code of Conduct. If a User fails to comply with our Code of
          Conduct, that User will be in breach of its Agreement with us, and we
          are entitled to immediately terminate our relationship with the User.
          <br />
          <br />
          This Code of Conduct is effective as of February 3, 2021.
        </p>
        <br />
        <br />
        <h2 className="mb-5 text-center">COMPLIANCE WITH LAWS</h2>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          All Users must comply with all applicable laws and regulations that
          apply to their operations, communications, and transactions,
          including, but not limited to those listed below.
        </p>
        <br />
        <br />
        <h3 className="mb-5">License and Permit</h3>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Users must ensure that all persons and facilities associated with
          business transacted on the Platform have the requisite, valid license
          or permit required to conduct such business in the jurisdiction in
          which the User is located.
        </p>
        <br />
        <br />
        <h3 className="mb-5">Animal Welfare</h3>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Users must comply with all applicable animal welfare standards, law,
          and regulations, including but not limited to all equine anti-doping
          or controlled-medications regulations.
        </p>
        <br />
        <br />
        <h3 className="mb-5">Consumer Protection; Truth in Advertising</h3>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Providers must not violate any applicable consumer-protection laws.
          Providers must ensure that all Marketplace Listings are accurate and
          truthful and that the Product delivered to the Customer is the correct
          Product as described in the Listing.
        </p>
        <br />
        <br />
        <h3 className="mb-5">Discrimination or Harassment</h3>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Users must not engage in unlawful discrimination or harassment, which
          includes any behavior that creates an intimidating, unsafe, or hostile
          work environment.
        </p>
        <br />
        <br />
        <h3 className="mb-5">Anti-Corruption</h3>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Users must comply with all applicable anti-corruption laws and
          regulations prohibiting public or commercial bribery, extortion,
          kickbacks, or other unlawful or improper means of conducting business.
        </p>
        <br />
        <br />
        <h3 className="mb-5">Competition</h3>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Users must comply with all applicable laws and regulations regarding
          fair competition and antitrust.
        </p>
        <br />
        <br />
        <h3 className="mb-5">Data and Information Privacy</h3>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Users must comply with all applicable data privacy laws and
          regulations when processing the personal or identifying information of
          anyone with whom they conduct business or anyone with whom they
          communicate through the Platform. Users must also strictly safeguard
          the data and information privacy of any Fast Semen-related
          information. Users agree to make reasonable efforts to keep data
          secure and confidential at all times.
        </p>
        <br />
        <br />
        <h3 className="mb-5">
          Intellectual Property and Confidential Information
        </h3>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Users may not share or disclose Fast Semen’s intellectual property,
          confidential information, or any other proprietary information that
          the User acquires through its engagement with Fast Semen to third
          parties. This includes, but is not limited to, information developed
          by the User in connection with its engagement with Fast Semen, and any
          information relating to products, consumers, pricing, costs,
          strategies, programs, processes, and practices.
        </p>
        <br />
        <br />
        <h3 className="mb-5">International Trade and Export Controls</h3>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Users must comply with all applicable import, export, customs,
          sanctions, embargoes, boycott and other trade compliance laws and
          regulations.
        </p>
        <h2 className="mb-5 text-center">
          ACCURATE BOOKS AND RECORDS AND AUDIT RIGHTS
        </h2>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Users must keep accurate books, accounts, and records for all
          transactions related to business with Fast Semen and through the
          Platform. As part of its commitment to transparency, Users are
          required to cooperate fully with Fast Semen in any government audits
          to the extent relevant and applicable to User.
        </p>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Users are prohibited from using fictitious, inaccurate, or misleading
          documents to support transactions related to business with Fast Semen,
          and are prohibited from engaging in false or misleading accounting
          practices, such as using undisclosed or unrecorded payments.
        </p>
        <h2 className="mb-5 text-center">COURTESY TO OTHERS</h2>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Users must treat other Users and Fast Semen personnel with dignity and
          respect. Users must not engage in any intimidating, harassing,
          abusive, discriminatory, derogatory, or demeaning conduct toward other
          Users and Fast Semen personnel.
        </p>
        <h2 className="mb-5 text-center">REPORT CONCERNS</h2>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          It is the obligation of every User to immediately report any known or
          suspected violations of this code of conduct by User or its employees
          or representatives; by other Users or their employees or
          representatives; or by Fast Semen personnel. Reports can be made by
          sending an email to contact@fastsemen.com .
        </p>
      </div>
      <Footer />
    </>
  );
}

import "./styles.css";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";

// import { Container } from './styles';

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();

    await api.post("/contato", { name, email, phone, title, message });
    setMessage("");
    setName("");
    setPhone("");
    setTitle("");
    setEmail("");
    toast.info(
      "Thank you very much for contacting us, soon we will answer you!"
    );
  };
  return (
    <>
      <Header />
      <div className="container my-5 contact p-0 p-md-5">
        <form className="w-100 col-12 col-md-8" onSubmit={handleSubmit}>
          <h2>Contact</h2>
          <label>
            Name:
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
          </label>
          <label>
            Email:
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
          </label>
          <label>
            Phone:
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="tel"
            />
          </label>
          <label>
            Subject:
            <input value={title} onChange={(e) => setTitle(e.target.value)} />
          </label>
          <label>
            Message:
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              style={{ minHeight: 150 }}
            ></textarea>
          </label>
          <label>
            Note: You can contact us to delete your account and data. Please note, once your account is deleted, you will not be able to login in web and mobile app.
            </label>
          <button
            type="submit"
            className="btn btn-info col-12 col-lg-3 ml-auto"
          >
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default Contact;

import "./styles.css";
import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import FinalityTasks from "../../Components/FinalityTask";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { FunctionContext } from "../../context";
import TableInfoProduct from "../../Components/TableInfoProduct";

export default function Chekout() {
  const context = useContext(FunctionContext);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState({ cart: [] });
  const { state } = useLocation();

  const history = useHistory();

  const PrecoOrder = ({ item: cav }) => {
    const preco =
      (cav.isChutefee === 1 ? 0 : cav.preco * cav.quantity) +
      (cav.precoAdicional || 0) +
      (cav.tipoSelected.length >= 1
        ? cav.free_shipping === 0
          ? cav.tipoSelected[0].valor
          : 0
        : 0);

    if (preco >= 1) {
      return <strong>US ${preco.toFixed(2)}</strong>;
    } else {
      return <strong className="text-success">FREE</strong>;
    }
  };

  useEffect(() => {
    setItems(state);
    setTotal(
      state.cart.reduce((acc, cav) => {
        const preco =
          (cav.isChutefee === 1 ? 0 : cav.preco * cav.quantity) +
          (cav.precoAdicional || 0) +
          (cav.tipoSelected.length >= 1
            ? cav.free_shipping === 0
              ? cav.tipoSelected[0].valor
              : 0
            : 0);

        return +preco + +acc;
      }, 0)
    );
  }, []);

  return (
    <>
      <Header />
      <div className="container chekout">
        <FinalityTasks
          itens={[
            { title: "Order Chekout", finality: true },
            { title: "Payment Method", finality: false },
            { title: "Order Finalized", finality: false },
          ]}
        />
        <h2 className="mt-3">Chekout order information</h2>
        <h3 className="mt-2">Products</h3>

        <div className="d-flex flex-column-reverse flex-lg-row">
          <div className="cavalos col-12 col-lg-8 mx-auto mx-lg-0">
            {items.cart.map((cav, index, array) => {
              let object = {};
              const adicional = !!cav.adicionalSelected
                ? cav.adicionalSelected.map((item) => {
                    const key = item.adicional;
                    return { title: "Additional orders", [key]: item.preco };
                  })
                : {};

              const informations = [
                {
                  title: "Order item",
                  "Colection Date": `${cav.mes}/${cav.dia}`,
                  Stallion: cav.garanhao,
                  "type of Semen": cav.tipo_semem,
                },
                {
                  title: "Delivery address",
                  "Name of the street": cav.endereco.rua,
                  Number: !!cav.endereco.numero
                    ? cav.endereco.numero
                    : "unnumbered",
                  State: cav.endereco.estado,
                  City: cav.endereco.cidade,
                },
                {
                  title: "Details",
                  Broodmare: cav.egua.nome,
                  "Shipping Option": cav.tipoSelected[0].tipos,
                  "Do you have a Chute Fee?":
                    cav.isChutefee === 1 ? "Yes" : "No",
                },
                adicional[0] || {},
              ];
              return (
                <Fragment key={cav.idcarrinho}>
                  <div className="cavalo my-5 d-flex flex-column">
                    <div className="d-flex flex-column flex-lg-row">
                      <img
                        src={`${process.env.REACT_APP_API_URL}${cav.diretorio}`}
                        className="col-12 col-lg-4"
                      />
                      <div className="d-flex flex-column mt-3 mt-sm-0 col-sm-8">
                        <div className="d-flex ml-auto ">
                          <button className="ml-5 text-info btn mb-3 mt-0 text-danger">
                            Delete
                          </button>
                        </div>
                        <p>
                          STALLION STATION: <strong>{cav.nome}</strong>
                        </p>
                        <h3>{cav.garanhao}</h3>
                        <p>Quantity : {cav.quantity}</p>
                        <h5>${cav.preco + (cav.chutefee || 0)}</h5>
                        <div className="d-flex flex-column flex-lg-row align-items-center">
                          <div className="d-flex">
                            <span
                              className="d-flex flex-row align-items-center  mr-5 bg-light px-3 py-1 "
                              style={{ borderRadius: 10 }}
                            >
                              <strong>Month</strong>
                              <p className="p-0 m-0 ml-3">{cav.mes}</p>
                            </span>
                            <span
                              className="d-flex flex-row align-items-center bg-light px-3 py-1 "
                              style={{ borderRadius: 10 }}
                            >
                              <strong>Day</strong>
                              <p className="p-0 m-0 ml-3">{cav.dia}</p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-info mt-3 ml-3 shipInfo">
                      <h4>Shipping information</h4>
                      <TableInfoProduct data={informations} />
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </div>
          <div className="total col-12 col-lg-4 mt-5 px-4 py-3 sumary align-self-start">
            <h3 className="text-center">Purchase Sumary</h3>
            {items.cart.map((cav) => {
              return (
                <div className="order d-flex justify-content-between align-items-baseline">
                  <p>{cav.garanhao}</p>
                  <p>
                    <PrecoOrder item={cav} />
                  </p>
                </div>
              );
            })}
            <hr />
            <div className="d-flex justify-content-between align-items-baseline">
              <h3>Total</h3>
              <p>
                <strong>${total.toFixed(2)}</strong>
              </p>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-outline-info w-100 my-2 py-3 mr-3"
                onClick={() => (window.location.href = "/cart")}
              >
                Come back
              </button>
              <button
                className="btn btn-info w-100 my-2 py-3 ml-3"
                onClick={() => {
                  history.push("/payment", {
                    cart: true,
                    data: items,
                    value: total,
                  });
                }}
              >
                Advance
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useLocation } from "react-router";

export default function ContactCheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // console.log({
    //   amount: {
    //     currency: "USD",
    //     value: this.props.location.state.value * 100,
    //   },
    //   cart: !!this.props.location.state.cart ? true : false,
    //   cavalo: this.props.location.state.data,
    // });

    try {
      setIsLoading(true);
      // alert(`${window.location.origin}/payment-data`);
      const { error, ...rest } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          //   // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/contract-payment-data`,
          // return_url: window.location.href,
        },
      });
      console.log({ error, rest });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
        alert(error.message);
      } else {
        console.log(error);
        setMessage(error.message);
        // alert("An unexpected error occured.");
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      alert("error");
      setIsLoading(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./routes";

import "./index.css";
import FunctionContextProvider from "./context";
function App() {
  return (
    <FunctionContextProvider>
      <Routes />
    </FunctionContextProvider>
  );
}

export default App;

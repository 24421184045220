import "./styles.css";
import Header from "../../Components/Header";
import Filtro from "../../Components/Filter";
import Footer from "../../Components/Footer";
import CookieBanner from 'react-cookie-banner';

export default function Home() {
  return (
    <>
      <Header />
      <div>
          <CookieBanner
            message="Cookies help us to improve. By continuing to browse the site you're accepting our cookies."
            onAccept={() => {}}
            styles={{
              banner: {   height: 'auto' },
              message: { fontWeight: 400 }
            }}
            cookie="user-has-accepted-cookies" />
        </div>
      <div className="home">
        <Filtro />
      </div>
      
      <Footer />
    </>
  );
}

import "./styles.css";
import SuccessPay from "../../../assets/successpay.svg";
export default function SuccesPayment() {
  return (
    <div className="screen">
      <img src={SuccessPay} />
      <br />

      <h4>YOUR ORDER WAS SUCCESSFULLY RECEIVED!</h4>
      <br />
      <br />
      <h5>
        THE FOLLOW-UP CODE AND ITS STATES WILL BE INFORMED BY THE CENTRAL IN
        UPDATING THE ORDER.
      </h5>
    </div>
  );
}

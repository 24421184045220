import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../services/api";

export default function ModalForgotPass(props) {
  const [email, setEmail] = useState("");
  const submitEmail = async (e) => {
    e.preventDefault();

    try {
      const { data } = await api.post("/recuperar-senha", {
        email,
      });
      localStorage.setItem("@USEREMAIL", email);

      toast.info(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="p-4">
        <h5>Enter your email.</h5>
        <form onSubmit={submitEmail}>
          <input
            className="form-control"
            placeholder="Please enter your email here"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="email"
            required
          />
          <button type="submit" className="btn btn-primary mt-2 float-right">
            Submit
          </button>
        </form>
      </div>
    </Modal>
  );
}

import classNames from "classnames";
import "./styles.css";
import React from "react";
export default function FinalityTasks({ itens }) {
  return (
    <div className="d-flex finalityTasks align-items-center">
      {itens.map((item, index) => {
        const classNameDiv = classNames("circle", {
          finaly: item.finality,
        });
        const classNameDivider = classNames("divider", "w-100", {
          current: item.finality,
        });
        if (index <= 0) {
          return (
            <>
              <span className="d-flex flex-column align-items-center">
                <div className="circle finaly"></div>
                <p>{item.title}</p>
              </span>
            </>
          );
        } else {
          return (
            <>
              <div
                className={
                  index === 1 ? "w-100 divider current " : classNameDivider
                }
              />
              <span className="d-flex flex-column align-items-center">
                <div className={classNameDiv}></div>
                <p>{item.title}</p>
              </span>
            </>
          );
        }
      })}
    </div>
  );
}

import "./styles.css";
import { useHistory } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";

import Footer from "../../Components/Footer";
import api from "../../services/api";
import Header from "../../Components/Header";
import ModalAdicionais from "../../Components/ModalFiltroCavalo/Adicionais";
import { toast } from "react-toastify";
import { FunctionContext as Context } from "../../context";
import TrashImage from "../../assets/delete.svg";
import TicketImage from "../../assets/ticket.png";

export default function Cart() {
  const context = useContext(Context);
  // const { carrinho: total } = context;
  const [cavalo, setCavalo] = useState([]);
  const [total, setTotal] = useState(0);
  const [mostAdicionais, setMostAdicionais] = useState(false);
  const [cavaloDetails, setCavaloDetails] = useState({
    tipoSelected: [{ valor: 0 }],
    adicionalSelected: [{ preco: 0 }],
  });
  const [adicionais, setAdicionais] = useState([]);
  const [tipos_envio, setTipo_envio] = useState([]);
  const [cupom, setCupom] = useState("");

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const cavalos = await api.get("/carrinho");
      setCavalo(cavalos.data);

      const totalProducts = cavalos.data.reduce((acc, cav) => {
        const preco =
          (cav.isChutefee === 1 ? 0 : cav.preco * cav.quantity) +
          (cav.precoAdicional || 0) +
          (cav.tipoSelected.length >= 1
            ? cav.free_shipping === 0
              ? cav.tipoSelected[0].valor
              : 0
            : 0);

        return +preco + +acc;
      }, 0);

      setTotal(totalProducts);
    })();
  }, []);

  useEffect(() => {
    setAdicionais(cavaloDetails.adicionais || []);
    setTipo_envio(cavaloDetails.tipo_envio || []);
  }, [cavaloDetails]);

  const PrecoOrder = ({ item: cav }) => {
    const preco =
      (cav.isChutefee === 1 ? 0 : cav.preco * cav.quantity) +
      (cav.precoAdicional || 0) +
      (cav.tipoSelected.length >= 1
        ? cav.free_shipping === 0
          ? cav.tipoSelected[0].valor
          : 0
        : 0);

    if (preco >= 1) {
      return <strong>US ${preco.toFixed(2)}</strong>;
    } else {
      return <strong className="text-success">FREE</strong>;
    }
  };

  const submitCreateChutefee = async ({
    idcarrinho,
    idvendedor,
    cupomText,
  }) => {
    try {
      await api.post("/carrinho/inserir-cupom", {
        idcarrinho: idcarrinho,
        cupom: cupomText,
        idvendedor,
      });
      toast.info("Coupon inserted successfully");
      window.location.reload();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Header />
      <div className="container mb-5">
        <h2 className="mb-5 mt-4 text-center">Review your order</h2>
        <div className="cart w-100 d-flex">
          {cavalo.length <= 0 && (
            <h1 className="mx-auto my-5">Cart is empty</h1>
          )}

          {cavalo.length >= 1 && (
            <div className="d-flex flex-column-reverse flex-lg-row justify-content-lg- col-12">
              <div className="cavalos col-12 col-lg-8 col-md-12 mx-0">
                {cavalo.map((cav, index, array) => {
                  return (
                    <Fragment key={cav.idcarrinho}>
                      {index > 0 && <hr />}
                      <div
                        className={
                          index > 0
                            ? "cavalo my-2 d-flex flex-column flex-lg-row"
                            : "cavalo my-0 d-flex flex-column flex-lg-row"
                        }
                      >
                        <img
                          src={`${process.env.REACT_APP_API_URL}${cav.diretorio}`}
                          className="col-12 col-md-4 mx-auto mx-md-0 text-center text-md-left"
                          style={{ height: 200 }}
                        />
                        <div className="d-flex flex-column mt-3 mt-sm-0 col-sm-8 px-0">
                          <span className="d-flex mb-0 flex-column flex-lg-row">
                            <h3>{cav.garanhao}</h3>
                            <div className="ml-auto mb-0">
                              <h5 className="text-right"></h5>
                            </div>
                            <div
                              className="text-danger px-3 btn pt-0"
                              style={{ height: 40 }}
                              onClick={async () => {
                                await api.delete(`/carrinho/${cav.idcarrinho}`);
                                window.location.reload();
                              }}
                            >
                              <img className="trash-cart" src={TrashImage} />
                              Delete
                            </div>
                          </span>

                          <p className="mt-0 mb-2">
                            Stallion Station: <strong>{cav.nome}</strong>
                          </p>
                          <div className="d-flex flex-column flex-lg-row align-items-center">
                            <div className="d-flex flex-column">
                              <p className="mb-0">Pickup Schedule:</p>
                              <div
                                className="d-flex flex-column flex-md-row"
                                style={{ marginLeft: -16 }}
                              >
                                <div>
                                  <span
                                    className="d-flex flex-row align-items-center ml-3 mr-3 bg-light px-3 py-2 "
                                    style={{ borderRadius: 10 }}
                                  >
                                    <strong>Month</strong>
                                    <p className="p-0 m-0 ml-3">{cav.mes}</p>
                                  </span>
                                  <span
                                    className="d-flex flex-row align-items-center bg-light px-3 py-2 mx-3 mx-md-0 my-3 my-md-0"
                                    style={{ borderRadius: 10 }}
                                  >
                                    <strong>Day</strong>
                                    <p className="p-0 m-0 ml-3">{cav.dia}</p>
                                  </span>
                                </div>
                                {cav.isChutefee === 1 ? (
                                  <span
                                    className="d-flex flex-row align-items-center bg-light px-3 py-2 ml-3"
                                    style={{ borderRadius: 10 }}
                                  >
                                    <strong>Coupon</strong>
                                    <p className="p-0 m-0 ml-3">{cav.cupom}</p>
                                    <img
                                      className="trash-cart ml-2"
                                      style={{ cursor: "pointer" }}
                                      src={TrashImage}
                                      onClick={async () => {
                                        await api.delete(
                                          `/carrinho/delete-chutefee/${cav.idcarrinho}/${cav.cupom}`
                                        );
                                        window.location.reload();
                                      }}
                                    />
                                  </span>
                                ) : (
                                  <div className="d-flex ml-3 ">
                                    <input
                                      className="form-control text-center"
                                      required
                                      onChange={(e) => {
                                        cav.cupomText = e.target.value;
                                        setCavalo([
                                          cav,
                                          ...cavalo.filter(
                                            (cavItem) =>
                                              cavItem.idcarrinho !==
                                              cav.idcarrinho
                                          ),
                                        ]);
                                      }}
                                      value={cav.cupomText}
                                    />
                                    <button
                                      className="btn btn-info ml-2"
                                      style={{ alignSelf: "flex-start" }}
                                      onClick={() => {
                                        submitCreateChutefee({
                                          idcarrinho: cav.idcarrinho,
                                          idvendedor: cav.central,
                                          cupomText: cav.cupomText,
                                        });
                                      }}
                                    >
                                      <img
                                        src={TicketImage}
                                        style={{ width: 20, height: 20 }}
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </div>

              <div className="total col-12 col-lg-4 ml-0 ml-md-4">
                <h3 className="text-center mb-4">Summary</h3>
                {cavalo.map((cav) => {
                  return (
                    <div className="order d-flex justify-content-between align-items-baseline">
                      <p>{cav.garanhao}</p>
                      <p>
                        <PrecoOrder item={cav} />
                      </p>
                    </div>
                  );
                })}
                <hr />
                <div className="d-flex justify-content-between align-items-baseline">
                  <h3>Total</h3>
                  <p>
                    {total >= 1 ? (
                      <strong>US ${total.toFixed(2)}</strong>
                    ) : (
                      <p className="text-success font-weight-bold">FREE</p>
                    )}
                  </p>
                </div>
                <button
                  className="btn btn-info w-100 my-2 py-3"
                  onClick={() => history.push("/chekout", { cart: cavalo })}
                >
                  Buy now
                </button>
                <br />
                <button
                  className="btn btn-outline-info w-100 my-2 py-3"
                  onClick={() => (window.location.href = "/")}
                >
                  Continue shopping
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

import "./styles.css";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";

export default function ForgotPassword() {
  const params = useParams();
  const passRef = useRef();
  const passConfirmRef = useRef();

  const [mostPass, setMostPass] = useState(false);
  const [pass, setPass] = useState("");
  const [comfirmPass, setComfirmPass] = useState("");

  useEffect(() => {
    if (mostPass) {
      passRef.current.type = "text";
      passConfirmRef.current.type = "text";
    } else {
      passRef.current.type = "password";
      passConfirmRef.current.type = "password";
    }
  }, [mostPass]);

  const submit = async (e) => {
    e.preventDefault();
    if (pass !== comfirmPass) {
      return toast.error("Your passwords do not match");
    }

    try {
      await api.post("/troca-senha", {
        email: localStorage.getItem("@USEREMAIL"),
        token: params.token,
        senha: pass,
      });
      toast.success("Password changed successfully!");
      window.location.href = "/login";
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <Header />
      <div className="col-5 mx-auto container-forgot my-5 p-4">
        <h4>Enter a new password</h4>
        <form className="d-flex flex-column" onSubmit={submit}>
          <label className="label-control">
            Enter your new password
            <input
              onChange={(e) => setPass(e.target.value)}
              value={pass}
              ref={passRef}
              type="password"
              className="form-control"
              required
            />
          </label>
          <label className="label-control">
            Confirm your new password
            <input
              onChange={(e) => setComfirmPass(e.target.value)}
              value={comfirmPass}
              ref={passConfirmRef}
              type="password"
              className="form-control"
              required
            />
          </label>
          <div className="d-flex align-items-baseline justify-content-between">
            <label>
              <input
                type="checkbox"
                onChange={() => setMostPass(!mostPass)}
                checked={mostPass}
              />
              Show password
            </label>
            <button className="btn btn-info mt-2" type="submit">
              Change Password
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

import "./styles.css";
import "react-toastify/dist/ReactToastify.css";

import api from "../../services/api";
import { useContext, useEffect, useRef, useState } from "react";
import FiltroCavalo from "../FiltroCavalo";
import Autocomplete from "react-autocomplete";

import { useHistory } from "react-router-dom";
import { FunctionContext } from "../../context";

export default function Filtro(props) {
  const form = useRef(null);
  const history = useHistory();
  const context = useContext(FunctionContext);

  const [vendedoresApi, setVendedorApi] = useState([]);
  const [racasVendedorApi, setRacaVendedorApi] = useState([]);

  const [vendedorSelecionalo, setVendedorSelecionalo] = useState(null);
  const [garanhaoSelecionalo, setGaranhaoSelecionalo] = useState(null);

  const [cavaloVendedor, setCavaloVendedor] = useState("");
  const [cavaloVendedorVal, setCavaloVendedorVal] = useState({});

  const [resCavalo, setResCavalo] = useState(false);

  useEffect(() => {
    api.get("/vendedor").then((res) => {
      return setVendedorApi(res.data.vendedores);
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    api
      .get(`/resultado/${cavaloVendedorVal.idgaranhao}`)
      .then((res) => setResCavalo(res.data));
  };

  const setaRaca = async (idvendedor) => {
    const { data } = await api.get(`/garanhao/${idvendedor}`);
    setRacaVendedorApi(data);
  };
  return (
    <div className="container filtro-conteiner d-flex flex-column align-itens-center justify-content-center">
      <div className="col-12 col-sm-8 pb-4 pt-5 px-2 px-md-5 filtro">
        <div className="mt-2">
          <p style={{ fontSize: 18 }} className="text-center">
            Please select the station and stallion
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex-column align-items-start"
          ref={form}
        >
          <div className="d-flex flex-column flex-sm-row col-12 px-0 px-lg-2 mx-auto justify-content-center">
            <span className="w-100 col-12 col-sm-6 col-lg-4 my-2 m-md-0 ml-0 pl-0 pr-0 pr-sm-1 ">
              <label className="mx-auto w-100 text-center">
                Stallion Station
              </label>
              <br />
              <select
                defaultValue="padrao"
                onChange={(e) => {
                  setaRaca(e.target.value);
                  setVendedorSelecionalo(e.target.value);
                }}
                className="w-100"
              >
                <option value="padrao" selected>
                  Select Station...
                </option>
                {vendedoresApi.map((vendedor) => {
                  return (
                    <option key={vendedor.vendedor} value={vendedor.vendedor}>
                      {vendedor.nome}
                    </option>
                  );
                })}
              </select>
            </span>
            <span className="w-100 col-12 col-sm-6 col-lg-4 my-2 m-md-0 mr-0 pr-0 pl-0 pl-sm-1">
              <label className="mx-auto w-100 text-center">Stallion</label>
              <br />
              <select
                defaultValue="padrao"
                onChange={(e) => setGaranhaoSelecionalo(e.target.value)}
                className="w-100"
              >
                <option value="padrao" selected>
                  Select Stallion...
                </option>
                {!racasVendedorApi.length && (
                  <option disabled>Select a seller</option>
                )}
                {racasVendedorApi.map((garanhao) => {
                  return (
                    <option
                      key={garanhao.idgaranhao}
                      value={garanhao.idgaranhao}
                    >
                      {garanhao.nome}
                    </option>
                  );
                })}
              </select>
            </span>
          </div>

          <div className="d-flex col-12">
            <span className="w-100 w-md-25 my-2 m-md-0 mx-md-4 ">
              <br />
              <div className="w-100 col-12 col-lg-8 mx-auto d-flex flex-column input">
                <input
                  value={cavaloVendedor}
                  onChange={async (e) => {
                    setCavaloVendedor(e.target.value);
                  }}
                  placeholder="Search"
                />
                <button
                  className="w-100 mt-2"
                  type="button"
                  onClick={() => {
                    context.setConfigFilter({
                      name: cavaloVendedor || null,
                      saller: vendedorSelecionalo || null,
                      idgaranhao: garanhaoSelecionalo || null,
                    });
                    history.push("/search/stallion");
                  }}
                >
                  Search
                </button>
              </div>
            </span>
          </div>
        </form>
      </div>
      {resCavalo && <FiltroCavalo cavalo={resCavalo} />}
    </div>
  );
}

import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Route from "./Auth/Route";

import Home from "./Pages/Home";
import MyAccount from "./Pages/MyAccount";
import AboutUs from "./Pages/AboutUs";
import Terms from "./Pages/Terms";
import Contact from "./Pages/Contact";
import SignIn from "./Pages/SignIn";
import Login from "./Pages/Login";
import Cart from "./Pages/Cart";
import Chekout from "./Pages/Chekout";
import Payment from "./Pages/Payment";
import SearchResulsts from "./Pages/SearchResults";
import PayResp from "./Pages/PayResp";
import PayRestContract from "./Pages/PayRestContract";
import PayUnique from "./Pages/PayUnique";
import ForgotPassword from "./Pages/ForgotPass";
import PayCartApp from "./Pages/PaymentCartApp";
import PayUniqueApp from "./Pages/PaymentUniqueApp";

import MyMares from "./Pages/MyMares";
import MyAddress from "./Pages/MyAdress";
import Orders from "./Pages/Orders";
import Settings from "./Pages/Settings";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Conduct from "./Pages/Conduct";
import TermsServiceCostumers from "./Pages/TermsServiceCostumers";
import TermsServicePartners from "./Pages/TermsServicePartners";
import PaymentContact from "./Pages/PaymentContact";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/terms" component={Terms} />
        <Route
          path="/terms-service-custumer"
          component={TermsServiceCostumers}
        />
        <Route
          path="/terms-service-providers"
          component={TermsServicePartners}
        />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/code-conduct" component={Conduct} />
        <Route path="/contact" component={Contact} />
        <Route path="/search/stallion" component={SearchResulsts} />
        <Route path="/signin" component={SignIn} unprivate />
        <Route path="/login" component={Login} unprivate />
        <Route
          path="/forgot-password/:token"
          component={ForgotPassword}
          unprivate
        />
        <Route path="/cart" component={Cart} private />
        <Route path="/chekout" component={Chekout} private />
        <Route path="/payment" exact component={Payment} />
        <Route path="/product/view" exact component={PayUnique} private />
        <Route path="/payment-app/:token/cart" component={PayCartApp} />
        <Route path="/payment-app/:id/:token/sale" component={PayUniqueApp} />
        <Route
          path="/payment-app/:id/:token/contract"
          component={PaymentContact}
        />
        <Route path="/payment-data" exact component={PayResp} />
        <Route path="/payment/:information/app" exact component={PayResp} />

        <Route
          path="/contract-payment-data"
          exact
          component={PayRestContract}
        />
        <Route
          path="/contract-payment/:information/app"
          exact
          component={PayRestContract}
        />

        {/* My account routes */}
        <Route path="/my-account/orders/:id" component={Orders} private />
        <Route path="/my-account/orders" component={Orders} private />
        <Route path="/my-account/my-mares" component={MyMares} private />
        <Route path="/my-account/my-address" component={MyAddress} private />
        <Route path="/my-account/settings" component={Settings} private />
      </Switch>
    </BrowserRouter>
  );
}

import { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FaTimes, FaPenSquare } from "react-icons/fa";
import Button from "../../../Components/Button";
import CreateMare from "../../../Components/Modals/RegisterAddress";
import api from "../../../services/api";

export default function ViewDateMare(props) {
  const [endereco, setEndereco] = useState({});
  const [loading, setLoading] = useState(true);
  const [mostModifyMare, setMostModifyMare] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get(`/endereco/${props.data}`);
      setEndereco(data);
      setLoading(false);
    })();
  }, [props.data, props]);

  return (
    <Modal {...props}>
      <Modal.Body>
        <CreateMare
          show={mostModifyMare}
          onHide={() => {
            props.onHide();
          }}
          data={props.data}
        />
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-danger mb-3"
            onClick={() => props.onHide()}
          >
            <FaTimes color="#990000" />
          </button>
        </div>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "70vh" }}
          >
            <Spinner animation="border" color="dark" variant="dark" />
          </div>
        ) : (
          <div className="px-3 table-responsive">
            <div className="d-flex justify-content-end">
              <Button onClick={() => setMostModifyMare(true)}>
                Edit Information
                <FaPenSquare size={30} className="ml-2" />
              </Button>
            </div>
            <h5 className="text-center">Address data</h5>
            <div className="table-responsive">
              <table className="w-75 mx-auto">
                <thead className="bg-light">
                  <tr>
                    <th>Name of the Street</th>
                    <th>Number</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-nowrap text-truncate col-6">
                      {endereco.rua}
                    </td>
                    <td className="text-nowrap text-truncate col-6">
                      {endereco.numero >= 1 ? endereco.numero : "None"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive">
              <table className="w-75 mx-auto">
                <thead className="bg-light">
                  <tr>
                    <th>State</th>
                    <th>City</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-nowrap text-truncate col-6">
                      {endereco.estado}
                    </td>
                    <td className="text-nowrap text-truncate col-6">
                      {endereco.cidade}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

import "./styles.css";
import { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";

import ArrowLeftImage from "../../assets/arrowLeft.svg";
import ArrowRightImage from "../../assets/arrowRight.svg";
import ModalAdicionais from "../ModalFiltroCavalo/Adicionais";
import { FunctionContext as Context } from "../../context";

export default function FiltroCavalo({ cavalo }) {
  const [indexImage, setIndexImage] = useState(0);
  const [indexImageModal, setIndexImageModal] = useState(0);
  const [qtd, setQtd] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [diaVendedor, setDiaVendedor] = useState([]);
  const [mesVendedor, setMesVendedor] = useState([]);
  const [mesVendedorValue, setMesVendedorValue] = useState(null);
  const [diaVendedorValue, setDiaVendedorValue] = useState(null);

  const { userToken } = useContext(Context);

  useEffect(() => {
    cavalo.imagens.map((cav, index) => {
      if(cav.padrao === 1) {
        setIndexImageModal(index);
        setIndexImage(index)
      }
    });
    setMesVendedor(cavalo.dias_funcionamento.mes);
  }, []);

  useEffect(() => {
    const diasVendedor = cavalo.dias_funcionamento.dia.filter((item) => {
      return item.mes == mesVendedorValue;
    });
    setDiaVendedor(diasVendedor);
  }, [mesVendedorValue]);

  useEffect(() => {
    cavalo.diaVendedor = diaVendedorValue;
    cavalo.mesVendedor = mesVendedorValue;
  }, [mesVendedorValue, diaVendedorValue]);

  return (
    <>
      <div className="container res-cavalo flex-column flex-lg-row">
        <div className="imagens col-12 col-lg-7 ">
          <span>
            {cavalo.imagens.map((imagem, index) => {
              return (
                <img
                  key={imagem.idimages}
                  onClick={() => setIndexImage(index)}
                  src={`${process.env.REACT_APP_API_URL}${imagem.diretorio}`}
                />
              );
            })}
          </span>
          <img
            src={`${process.env.REACT_APP_API_URL}${cavalo.imagens[indexImage].diretorio}`}
            className="w-75"
          />
        </div>

        <div className="info mx-2 my-2 my-lg-0 col-12 col-lg-4 mt-5 mt-lg-0">
          <h5>STALLION STATION: {cavalo.cavalo.vendedor}</h5>
          <h2>{cavalo.cavalo.garanhao}</h2>
          <p className="mt-4 mb-3">
            <strong>CHECK AVAILABILITY</strong>
          </p>
          <div className="d-flex justify-content-around">
            <label className="d-flex flex-column align-items-center w-50">
              Month
              <select
                className="w-75 p-2"
                onChange={(e) => {
                  setMesVendedorValue(e.target.value);
                }}
              >
                <option value="padrao" selected disabled>
                  Month
                </option>
                {mesVendedor.map((mes) => {
                  return (
                    <option value={mes.mes} key={mes.iddias_funcionamento}>
                      {mes.mes}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="d-flex flex-column align-items-center w-50">
              Day
              <select
                className="w-75 p-2"
                onChange={(e) => setDiaVendedorValue(e.target.value)}
              >
                <option value="padrao" selected disabled>
                  Day
                </option>
                {diaVendedor.map((dia) => {
                  return (
                    <option value={dia.dia} key={dia.iddias_funcionamento}>
                      {dia.dia}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>

          <h2 className="mt-4">$ {cavalo.cavalo.chutefee * qtd}</h2>
          <div className="d-flex mt-5">
            <button
              className="btn"
              onClick={() => {
                const value = qtd - 1;
                if (value <= 0) {
                  return setQtd(value+1);
                } else {
                  return setQtd(value);
                }
              }}
            >
              <img src={ArrowLeftImage} />
            </button>
            <input
              value={qtd}
              type="number"
              onChange={(e) => setQtd(e.target.value)}
              onBlur={() => {
                if (qtd > cavalo.cavalo.dosesRestantes) {
                  toast.error("You exceeded the purchase limit");
                  return setQtd(cavalo.cavalo.dosesRestantes);
                }
              }}
            />
            <button
              className="btn"
              onClick={() => {
                const value = qtd + 1;
                if (value > cavalo.cavalo.dosesRestantes) {
                  return setQtd(value-1);
                } else {
                  return setQtd(value);
                }
              }}
            >
              <img src={ArrowRightImage} />
            </button>
          </div>
          <div className="col-12 buttons flex-column flex-xl-row">
            <button
              className="w-100 col-12 col-xl-8 btn btn-outline-info mx-0 mx-sm-2"
              onClick={() => {
                if (!userToken) {
                  return (window.location.href = "/login");
                }
                if (mesVendedorValue && diaVendedorValue) {
                  cavalo.isCart = true;
                  return setShowModal(true);
                } else {
                  toast.error("please select the collection day");
                }
              }}
            >
              Add to cart
            </button>
            <button
              className="w-100 col-12 col-xl-8 btn btn-info bg-info mx-0 mx-sm-2 my-0 my-xl-4"
              onClick={() => {
                if (!userToken) {
                  return (window.location.href = "/login");
                }
                if (mesVendedorValue && diaVendedorValue) {
                  cavalo.isCart = false;
                  return setShowModal(true);
                } else {
                  toast.error("please select the collection day");
                }
              }}
            >
              Buy now
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
      <ModalAdicionais
        cavalo={cavalo}
        imgModal={cavalo.imagens[indexImageModal]}
        onHide={() => setShowModal(false)}
        show={showModal}
        quantidade={qtd}
      />
    </>
  );
}

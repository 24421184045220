import "./styles.css";
import { useHistory, useLocation } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";

import Footer from "../../Components/Footer";
import api from "../../services/api";
import Header from "../../Components/Header";
import { FunctionContext as Context } from "../../context";
import TrashImage from "../../assets/delete.svg";
import TableInfoProduct from "../../Components/TableInfoProduct";
import { toast } from "react-toastify";

export default function PayUnique() {
  const context = useContext(Context);
  const history = useHistory();
  const { state: cavalo } = useLocation();
  const [mostAdicionais, setMostAdicionais] = useState(false);
  const [cavaloDetails, setCavaloDetails] = useState({
    tipoSelected: [{ valor: 0 }],
    adicionalSelected: [{ preco: 0 }],
  });

  const total =
    (cavalo.isChutefee === 1 ? 0 : cavalo.chutefee) +
    (cavalo.precoAdicional || 0) +
    (cavalo.tipoSelected.length >= 1
      ? cavalo.free_shipping === 0 || !cavalo.free_shipping
        ? cavalo.tipoSelected[0].valor
        : 0
      : 0);

  const PrecoOrder = ({ item: cav }) => {
    const preco =
      (cav.isChutefee === 1 ? 0 : cav.chutefee) +
      (cav.precoAdicional || 0) +
      (cav.tipoSelected.length >= 1
        ? cav.free_shipping === 0 || !cav.free_shipping
          ? cav.tipoSelected[0].valor
          : 0
        : 0);

    if (preco >= 1) {
      return <strong>US ${preco.toFixed(2)}</strong>;
    } else {
      return <strong className="text-success">FREE</strong>;
    }
  };

  const adicional = !!cavalo.adicionalSelected?.length
    ? cavalo.adicionalSelected.map((item) => {
        const key = item.adicional;
        if (!key) {
          return {};
        }
        return { title: "Additional orders", [key]: item.preco };
      })[0]
    : {};

  const informations = [
    {
      title: "Order item",
      "Colection Date": `${cavalo.mes}/${cavalo.dia}`,
      Stallion: cavalo.garanhao,
      "type of Semen": cavalo.tipo_semem,
    },
    {
      title: "Delivery address",
      "Name of the street": cavalo.endereco.rua,
      Number: !!cavalo.endereco.numero ? cavalo.endereco.numero : "unnumbered",
      State: cavalo.endereco.estado,
      City: cavalo.endereco.cidade,
    },
    {
      title: "Details",
      Broodmare: cavalo.egua.nome,
      "Shipping Option": cavalo.tipoSelected[0].tipos,
      "Do you have a Chute Fee?": cavalo.isChutefee === 1 ? "Yes" : "No",
    },
    adicional || {},
  ];

  return (
    <>
      <Header />
      <div className="container mb-5">
        <h2 className="mb-5 mt-4">Checkout order information</h2>
        <div className="cart w-100 d-flex">
          {cavalo.length <= 0 && (
            <h1 className="mx-auto my-5">Cart is empty</h1>
          )}

          <div className="d-flex flex-column col-12 flex-lg-row justify-content-lg-between">
            <div className="cavalos col-12 col-md-12 col-lg-7 col-xl-8 mx-auto mx-lg-0">
              <div className="cavalo my-2 d-flex flex-column flex-lg-row">
                <img
                  src={`${process.env.REACT_APP_API_URL}${cavalo.diretorio}`}
                  className="col-12 col-lg-4"
                  style={{ height: 200 }}
                />
                <div className="d-flex flex-column mt-3 mt-sm-0 col-sm-8">
                  <span className="d-flex mb-0">
                    <h3>{cavalo.garanhao}</h3>
                    <div className="ml-auto mb-0">
                      <h5 className="text-right">US ${cavalo.chutefee}</h5>
                    </div>
                  </span>

                  <p className="mt-0 mb-2">
                    Stallion Station: <strong>{cavalo.nome}</strong>
                  </p>
                  <div className="d-flex flex-column flex-lg-row align-items-center">
                    <div className="d-flex flex-column">
                      <p className="mb-0">Pickup Schedule:</p>
                      <div className="d-flex" style={{ marginLeft: -16 }}>
                        <span
                          className="d-flex flex-row align-items-center  mr-3 bg-light px-3 py-2 "
                          style={{ borderRadius: 10 }}
                        >
                          <strong>Month</strong>
                          <p className="p-0 m-0 ml-3">{cavalo.mes}</p>
                        </span>
                        <span
                          className="d-flex flex-row align-items-center bg-light px-3 py-2 "
                          style={{ borderRadius: 10 }}
                        >
                          <strong>Day</strong>
                          <p className="p-0 m-0 ml-3">{cavalo.dia}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TableInfoProduct data={informations} />
            </div>

            <div className="total col-12 col-md-12 col-lg-4">
              <h3 className="text-center mb-4">Purchase summary</h3>
              <div className="order d-flex justify-content-between align-items-baseline">
                <p>{cavalo.garanhao}</p>
                <p>
                  <PrecoOrder item={cavalo} />
                </p>
              </div>
              <hr />
              <div className="d-flex justify-content-between align-items-baseline">
                <h3>Total</h3>
                <p>
                  {total >= 1 ? (
                    <strong>US ${total.toFixed(2)}</strong>
                  ) : (
                    <p className="text-success font-weight-bold">FREE</p>
                  )}
                </p>
              </div>
              <button
                className="btn btn-info w-100 my-2 py-3"
                onClick={() => {
                  history.push("/payment", {
                    cart: false,
                    data: cavalo.payment,
                    value: total,
                  });
                }}
              >
                Order Now
              </button>
              <br />
              <button
                className="btn btn-outline-info w-100 my-2 py-3"
                // onClick={() => (window.location.href = "/")}
                onClick={async () => {
                  try {
                    const resp = await api.post(
                      "/carrinho",
                      cavalo.CartAddItemData
                    );
                    toast.success(resp.data.message);
                    setTimeout(() => {
                      window.location.href = "/";
                    }, 2000);
                  } catch (error) {
                    console.log(error, error.response);
                    return toast.error(error?.response?.data?.message);
                  }
                  window.location.href = "/";
                }}
              >
                Continue Shopping
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

import "../styles.css";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import CursorImage from "../../../assets/cursor.svg";
import ExitModalImage from "../../../assets/exit.svg";
import TrashImage from "../../../assets/delete.svg";
import ModalInformacao from "../informacao";
import api from "../../../services/api";
import IntensMap from "../Components/Item";
import { FunctionContext } from "../../../context";
import Count from "../Components/Count";

export default function ModalAdicionais(props) {
  const { cavalo, quantidade, imgModal, cart } = props;
  const FunctionContextData = useContext(FunctionContext);
  const history = useHistory();

  const [qtd, setQtd] = useState(1);
  const [diaVendedor, setDiaVendedor] = useState("0/0");
  const [mesVendedor, setMesVendedor] = useState(0);
  const [shipping, setShipping] = useState({});
  const [adictional, setAdictional] = useState({});
  const [tiposEnviosData, setTiposEnviosData] = useState([]);
  const [adicionais, setAdicionais] = useState([]);
  const [broadmare, setBroadmare] = useState([]);
  const [address, setAddress] = useState([]);
  const [diasFuncionamento, setDiasFucnionamento] = useState([]);
  const [broadmareSelected, setBroadmareSelected] = useState(false);
  const [addressSelected, setAddressSelected] = useState(false);
  const [mostFrete, setMostFrete] = useState(false);
  const [cupom, setCupom] = useState("");

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/tipos-envio/${cavalo.garanhao}`);
      const mapData = data.map((item) => {
        if (item.standard === 1) {
          item.cheked = true;
          FunctionContextData.setSelectedTipoEnvio(item);
        } else {
          item.cheked = false;
        }

        return item;
      });

      setTiposEnviosData(mapData);

      const { data: dataAdicional } = await api.get(
        `/adicionais/${cavalo.garanhao}`
      );
      setAdicionais(dataAdicional);
    })();
  }, [cavalo]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(
        `/garanhao/dias-funcionamento/${cavalo.garanhao}`
      );
      setDiasFucnionamento(data);
    })();
  }, [FunctionContextData.cavalo, cavalo]);

  useEffect(() => {
    (async () => {
      if (FunctionContextData.userToken) {
        const brooadmares = await api.get("/eguas").catch((err) => {
          return toast.error(err.response.data.message);
        });
        setBroadmare(brooadmares.data);

        const addressUser = await api.get("/endereco").catch((err) => {
          return toast.error(err.response.data.message);
        });

        setAddress(addressUser.data);
      }
    })();
  }, [
    FunctionContextData.mostCreateBroodmare,
    FunctionContextData.mostCreateAddres,
    FunctionContextData.cavalo,
  ]);

  useEffect(() => {
    (async () => {
      const {
        data: { freeShipping },
      } = await api.post("/verifica-frete", {
        idegua: broadmareSelected,
        idgaranhao: FunctionContextData.cavalo.idgaranhao,
      });
      FunctionContextData.setCavalo({
        ...FunctionContextData.cavalo,
        freeShipping,
      });

      if (freeShipping) {
        toast.info("Free shipping will be validated once purchase is complete");
      }
    })();
  }, [broadmareSelected]);
  useEffect(() => {
    setQtd(quantidade >= 1 ? quantidade : cavalo?.cavalo?.quantidade);
  }, [quantidade]);

  useEffect(() => {
    FunctionContextData.setCavalo(cavalo);
    const preco =
      (cavalo?.cavalo?.adicionalSelected
        ? cavalo?.cavalo?.adicionalSelected[0]?.preco
        : 0) +
      (cavalo?.cavalo?.tipoSelected
        ? cavalo?.cavalo?.tipoSelected[0]?.valor
        : 0);

    setShipping(
      cavalo?.cavalo?.tipoSelected ? cavalo?.cavalo?.tipoSelected[0] : {}
    );
    setAdictional(
      cavalo?.cavalo?.adicionalSelected
        ? cavalo?.cavalo?.adicionalSelected[0]
        : {}
    );
  }, [cavalo]);

  const submitButton = async (e) => {
    e.preventDefault();
    if (!FunctionContextData.userToken) {
      return (window.location.href = "/login");
    }

    if (FunctionContextData.cavalo.chutefeePrepaid) {
      setCupom("");
      toast.info("Coupon removed successfully!");
      return FunctionContextData.setCavalo({
        ...FunctionContextData.cavalo,
        chutefeePrepaid: false,
        chutefeePrepaidValue: null,
      });
    }
    try {
      const { data } = await api.post("/ticket", {
        cupom,
        idvendedor: FunctionContextData.cavalo.central,
      });
      toast.success(data.message);
      FunctionContextData.setCavalo({
        ...FunctionContextData.cavalo,
        chutefeePrepaid: true,
        chutefeePrepaidValue: cupom,
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="d-flex modal-adicional flex-column">
          <img
            src={ExitModalImage}
            className="exit btn ml-auto"
            onClick={() => {
              if (mostFrete) {
                setMostFrete(false);
              } else {
                props.onHide();
              }
            }}
          />
          <div className="d-flex flex-column flex-xl-row">
            <div className=" col-xl-6 col-12">
              <h4 className="ml-4">{cavalo.nome}</h4>
              <img
                src={`${process.env.REACT_APP_API_URL}${
                  !!imgModal ? imgModal.diretorio : cavalo.cavalo.diretorio
                }`}
                className="col-12"
                style={{ height: 380 }}
              />
              <p className=" px-3 mt-3 font-weight-bold">
                {cavalo?.cavalo?.vendedor}
              </p>
              <div className="d-flex px-3 flex-column flex-lg-row">
                <div className="">
                  <h2 className="font-weight-bolder pb-3">
                    {cavalo?.cavalo?.garanhao}
                  </h2>
                </div>
                <table className="table mt-4 align-self-end mx-auto col-12">
                  <tbody>
                    <tr style={{ fontSize: 18 }}>
                      <td style={{ fontWeight: "bold" }}>Chute fee</td>
                      <td className="text-right">
                        <span
                          style={{
                            textDecoration: FunctionContextData.cavalo
                              .chutefeePrepaid
                              ? "line-through"
                              : "none",
                            fontWeight: "bold",
                          }}
                        >
                          +${FunctionContextData.cavalo.chutefee}
                        </span>
                        <span
                          className={
                            FunctionContextData.cavalo.chutefeePrepaid
                              ? "m-0 ml-3 p-0 text-success"
                              : "d-none"
                          }
                          style={{
                            textDecoration: "none",
                            fontWeight: "bold",
                          }}
                        >
                          Free
                        </span>
                      </td>
                    </tr>
                    {FunctionContextData.selectedTipoEnvio?.idtipos_envio && (
                      <tr style={{ fontSize: 18 }}>
                        <td style={{ fontWeight: "bold" }}>Shipping</td>
                        <td className="text-right">
                          <span
                            style={{
                              textDecoration:
                                FunctionContextData.cavalo.freeShipping &&
                                FunctionContextData.selectedTipoEnvio
                                  .standard === 1
                                  ? "line-through"
                                  : "none",
                              fontWeight: "bold",
                            }}
                          >
                            +${FunctionContextData.selectedTipoEnvio.valor}
                          </span>
                          <span
                            className={
                              FunctionContextData.cavalo.freeShipping &&
                              FunctionContextData.selectedTipoEnvio.standard ===
                                1
                                ? "m-0 ml-3 p-0 text-success"
                                : "d-none"
                            }
                            style={{
                              textDecoration: "none",
                              fontWeight: "bold",
                            }}
                          >
                            Free
                          </span>
                        </td>
                      </tr>
                    )}
                    {FunctionContextData.selectedAdicionais.idadicionais && (
                      <tr style={{ fontSize: 18 }}>
                        <td style={{ fontWeight: "bold" }}>Additional</td>
                        <td
                          style={{ fontWeight: "bold" }}
                          className="text-right"
                        >
                          +${FunctionContextData.selectedAdicionais.preco}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <h5 className="font-weight-bolder">Total</h5>
                      </td>
                      <td className="text-right">
                        <strong>
                          $ {FunctionContextData.total.toFixed(2)}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {!mostFrete && (
              <>
                <div
                  className=" col-xl-6 col-12 "
                  style={{ minHeight: "100%", marginTop: 35 }}
                >
                  <div className="inputChutefee mb-3 mb-md-2 d-flex flex-column flex-md-row align-items-center w-100">
                    <span>
                      <h6>Do you have a chute fee</h6>
                      <a>Request coupon from seller</a>
                    </span>
                    <form
                      className="form col-12 col-md-7"
                      onSubmit={submitButton}
                    >
                      <input
                        className="input"
                        onChange={(e) => setCupom(e.target.value)}
                        value={cupom}
                      />
                      <button
                        style={{
                          backgroundColor: FunctionContextData.cavalo
                            .chutefeePrepaid
                            ? "#990000"
                            : "#04c2af",
                        }}
                        className="btn"
                        type="submit"
                      >
                        {FunctionContextData.cavalo.chutefeePrepaid ? (
                          <img src={TrashImage} className="remove-coupon" />
                        ) : (
                          "insert"
                        )}
                      </button>
                    </form>
                  </div>
                  <h5 className="mt-4 mb-0">Select the collection date</h5>
                  <div className="d-flex mb-3">
                    <select
                      onChange={(e) => {
                        setDiaVendedor(e.target.value);
                        // alert(e.target.value);
                      }}
                      className="form-control"
                    >
                      <option>Select the collection date</option>
                      {diasFuncionamento.map((dia) => {
                        console.log(dia);
                        return (
                          <option
                            value={new Intl.DateTimeFormat("en-us", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }).format(new Date(dia.data_disponivel))}
                          >
                            {new Intl.DateTimeFormat("en-us", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }).format(new Date(dia.data_disponivel))}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <span className="font-weight-bolder">Broodmare</span>
                    <div className="d-flex justify-content-between">
                      <select
                        className="col-10 py-3 w-100 px-2"
                        style={{ height: 55, borderRadius: 12 }}
                        onChange={(e) => setBroadmareSelected(e.target.value)}
                        defaultValue="0"
                      >
                        <option selected disabled value="0">
                          Choose the mare that will receive the semen ...
                        </option>
                        {broadmare.map((mare) => {
                          return (
                            <option value={mare.idegua} key={mare.idegua}>
                              {mare.nome}
                            </option>
                          );
                        })}
                        {broadmare.length <= 0 && (
                          <option disabled>
                            Please, create a new broadmare.
                          </option>
                        )}
                      </select>
                      <button
                        className="btn btn-outline-info px-3 bg-light"
                        style={{
                          fontSize: 30,
                          borderRadius: 8,
                          textAlign: "center",
                          height: 55,
                        }}
                        onClick={() =>
                          FunctionContextData.setMostCreateBroodmare(true)
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <br />
                  <div className="mb-4">
                    <span className="font-weight-bolder">Address</span>
                    <div className="d-flex justify-content-between">
                      <select
                        className="col-10 py-3 w-100 px-2"
                        style={{ height: 55, borderRadius: 12 }}
                        onChange={(e) => setAddressSelected(e.target.value)}
                        defaultValue="0"
                      >
                        <option selected disabled value="0">
                          Enter the shipping address....
                        </option>
                        {address.map((ad) => {
                          return (
                            <option value={ad.idenderecos} key={ad.idenderecos}>
                              {ad.rua}
                            </option>
                          );
                        })}
                        {address.length <= 0 && (
                          <option disabled>
                            Please, create a new address.
                          </option>
                        )}
                      </select>
                      <button
                        className="btn btn-outline-info px-3 bg-light"
                        style={{
                          fontSize: 30,
                          borderRadius: 8,
                          textAlign: "center",
                          height: 55,
                        }}
                        onClick={() => {
                          FunctionContextData.setMostCreateAddres(true);
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {mostFrete && (
              <>
                <ModalInformacao
                  show={mostFrete}
                  onHide={() => setMostFrete(false)}
                />
              </>
            )}
          </div>
          {mostFrete && (
            <div style={{ display: "flex" }} className="ml-auto">
              <button
                className="btn btn-info p-3 px-5 align-self-end mt-5 mt-xl-4 mx-3"
                onClick={async () => {
                  if (!addressSelected) {
                    return toast.error(
                      "Please select or create a shipping address"
                    );
                  }
                  if (!broadmareSelected) {
                    return toast.error(
                      "Please select or create the mare that will receive the semen"
                    );
                  }

                  if (FunctionContextData.tipoSemen.length <= 0) {
                    return toast.error(
                      "Please enter the type of semen you want"
                    );
                  }

                  try {
                    const resp = await api.post("/carrinho", {
                      idegua: Number(broadmareSelected),
                      idgaranhao: Number(FunctionContextData.cavalo.idgaranhao),
                      idendereco: Number(addressSelected),
                      dia: Number(diaVendedor.split("/")[1]),
                      mes: Number(diaVendedor.split("/")[0]),
                      tipos_envio_idtipos_envio: Number(
                        FunctionContextData.selectedTipoEnvio.idtipos_envio
                      ),
                      chutefee: !!FunctionContextData.cavalo.chutefeePrepaid,
                      adicionais_idadicionais: Number(
                        FunctionContextData.selectedAdicionais.idadicionais
                      ),
                      tipo_semem: FunctionContextData.tipoSemen,
                      cupom,
                      free_shipping: !!FunctionContextData.cavalo.freeShipping
                        ? 1
                        : 0,
                    });
                    toast.success(resp.data.message);
                    setTimeout(() => {
                      window.location.href = "/cart";
                    }, 2000);
                  } catch (error) {
                    return toast.error(error.response.data.message);
                  }
                }}
              >
                Add to Cart
              </button>

              <button
                className="btn btn-success p-3 px-5 align-self-end mt-5 mt-xl-4"
                onClick={async () => {
                  if (!addressSelected) {
                    return toast.error(
                      "Please select or create a shipping address"
                    );
                  }
                  if (!broadmareSelected) {
                    return toast.error(
                      "Please select or create the mare that will receive the semen"
                    );
                  }
                  if (FunctionContextData.tipoSemen.length <= 0) {
                    return toast.error(
                      "Please enter the type of semen you want"
                    );
                  }

                  console.log(
                    {
                      dia: Number(diaVendedor.split("/")[1]),
                      mes: Number(diaVendedor.split("/")[0]),
                    },
                    diaVendedor
                  );

                  history.push("/product/view", {
                    idgaranhao: cavalo.garanhao,
                    tipos_envio_idtipos_envio:
                      FunctionContextData.selectedTipoEnvio.idtipos_envio,
                    diretorio: cavalo.diretorio,
                    garanhao: cavalo.nome,
                    dia: Number(diaVendedor.split("/")[1]),
                    mes: Number(diaVendedor.split("/")[0]),
                    nome: cavalo.vendedor,
                    chutefee: cavalo.chutefee,
                    adicionais_idadicionais: Number(
                      FunctionContextData.selectedAdicionais.idadicionais
                    ),
                    precoAdicional:
                      FunctionContextData.selectedAdicionais.preco,
                    tipoSelected: [FunctionContextData.selectedTipoEnvio],
                    tipo_semem: FunctionContextData.tipoSemen,
                    vendedor_iduser: cavalo.central,
                    idegua: Number(broadmareSelected),
                    idtipos_envio: Number(
                      FunctionContextData.selectedTipoEnvio.idtipos_envio
                    ),
                    payment: {
                      idegua: Number(broadmareSelected),
                      idgaranhao: Number(cavalo.idgaranhao),
                      idendereco: Number(addressSelected),
                      dia: Number(diaVendedor.split("/")[1]),
                      mes: Number(diaVendedor.split("/")[0]),
                      tipos_envio_idtipos_envio: Number(
                        FunctionContextData.selectedTipoEnvio.idtipos_envio
                      ),
                      chutefee: !!FunctionContextData.cavalo.chutefeePrepaid,
                      adicionais_idadicionais: Number(
                        FunctionContextData.selectedAdicionais.idadicionais
                      ),
                      tipo_semem: FunctionContextData.tipoSemen,
                      nativeItem: cavalo,
                    },
                    endereco: address.filter((item) => {
                      return item.idenderecos === Number(addressSelected);
                    })[0],
                    adicionalSelected:
                      [FunctionContextData.selectedAdicionais] || {},
                    isChutefee: !FunctionContextData.cavalo.chutefeePrepaid
                      ? 1
                      : 0,
                    egua: broadmare.filter((item) => {
                      return item.idegua === Number(broadmareSelected);
                    })[0],
                    CartAddItemData: {
                      idegua: Number(broadmareSelected),
                      idgaranhao: Number(FunctionContextData.cavalo.idgaranhao),
                      idendereco: Number(addressSelected),
                      dia: Number(diaVendedor.split("/")[1]),
                      mes: Number(diaVendedor.split("/")[0]),
                      tipos_envio_idtipos_envio: Number(
                        FunctionContextData.selectedTipoEnvio.idtipos_envio
                      ),
                      chutefee: !!FunctionContextData.cavalo.chutefeePrepaid,
                      adicionais_idadicionais: Number(
                        FunctionContextData.selectedAdicionais.idadicionais
                      ),
                      tipo_semem: FunctionContextData.tipoSemen,
                      cupom,
                      free_shipping: !!FunctionContextData.cavalo.freeShipping
                        ? 1
                        : 0,
                    },
                  });
                }}
              >
                Order Now
              </button>
            </div>
          )}
          {!mostFrete && (
            <div className="ml-auto">
              <button
                className="btn btn-info py-4 px-5"
                onClick={() => {
                  if (!addressSelected) {
                    return toast.error(
                      "Please select or create a shipping address"
                    );
                  }
                  if (!broadmareSelected) {
                    return toast.error(
                      "Please select or create the mare that will receive the semen"
                    );
                  }

                  if (
                    Number(diaVendedor.split("/")[0]) <= 0 &&
                    Number(diaVendedor.split("/")[1]) <= 0
                  ) {
                    return toast.error("Please select the collection date");
                  }
                  setMostFrete(true);
                }}
              >
                Next
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

import { useContext, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { FunctionContext } from "../../context";
import api from "../../services/api";

export default function ModalRegisterAddress(props) {
  const context = useContext(FunctionContext);
  const [name, setName] = useState("");
  const [number, setNumber] = useState();
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [loading, setLoading] = useState();
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/api/estados");
      setEstados(data);
    })();
  }, [context.mostCreateAddres]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/api/cidade/${State}`);
      setCidades(data);
    })();
  }, [State]);

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    await api
      .post("/endereco", {
        rua: name,
        numero: number,
        cidade: City,
        estado: State,
      })
      .then((res) => {
        setName("");
        setNumber("");
        setState("");
        setCity("");
        props.onHide();
        toast.success("address successfully raised!");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    setLoading(false);
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="col-8 mx-auto py-5">
        <h3>Register a new address</h3>
        <br />
        <form className="d-flex flex-column" onSubmit={submitForm}>
          <label className="d-flex flex-column">
            Address
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
            />
          </label>
          <label className="d-flex flex-column">
            Number
            <input
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              className="form-control"
              type="number"
            />
          </label>
          <label className="d-flex flex-column">
            State
            <select
              value={State}
              onChange={(e) => setState(e.target.value)}
              className="form-control"
            >
              <option>Select your state</option>
              {estados.map((item) => {
                return (
                  <option value={item.state_name}>{item.state_name}</option>
                );
              })}
            </select>
          </label>
          <label className="d-flex flex-column">
            City
            <select
              value={City}
              onChange={(e) => setCity(e.target.value)}
              className="form-control"
            >
              <option>Select your state</option>
              {cidades.map((item) => {
                return <option value={item.city_name}>{item.city_name}</option>;
              })}
            </select>
          </label>

          <button type="submit" className="btn btn-info align-self-end mt-4">
            {loading && <Spinner animation="border" />}
            {!loading && "SAVE"}
          </button>
        </form>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
}

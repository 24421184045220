import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

export default function TermsServiceCostumers() {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <h2 className="mb-5 text-center">FAST SEMEN TERMS OF SERVICE</h2>
        <h3 className="mb-5 text-center">For Customers</h3>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          This terms of service document (the “Terms of Service”) describes the
          terms under which Fast Semen LLC offers you access to the Fast Semen
          Platform.
          <br />
          <br />
          <h5>
            Please read this Terms of Service carefully, it constitutes a legal
            agreement between you and Fast Semen. By accessing the Fast Semen
            Platform, you agree to all terms in this Terms of Service.
          </h5>
          <p>
            SECTIONS 19 AND 20 OF THIS AGREEMENT CONTAIN PROVISIONS THAT GOVERN
            HOW CLAIMS AGAINST OR BY FAST SEMEN ARE RESOLVED, INCLUDING, WITHOUT
            LIMITATION, ANY CLAIMS THAT AROSE OR WERE ASSERTED BEFORE THE
            EFFECTIVE DATE OF THIS AGREEMENT. IN PARTICULAR, SECTION 19 SETS
            FORTH AN ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS,
            REQUIRE DISPUTES TO BE SUBMITTED TO BINDING AND FINAL ARBITRATION.
            YOU WILL, WITH LIMITED EXCEPTIONS, ONLY BE PERMITTED TO PURSUE
            CLAIMS AND SEEK RELIEF AGAINST FAST SEMEN ON AN INDIVIDUAL BASIS,
            NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE
            ACTION OR PROCEEDING. YOU ARE WAIVING YOUR RIGHT TO SEEK RELIEF IN A
            COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS. PLEASE SEE
            SECTION 19 FOR MORE INFORMATION REGARDING THIS ARBITRATION
            AGREEMENT, THE POSSIBLE EFFECTS OF THIS ARBITRATION AGREEMENT, AND
            HOW TO OPT OUT OF THE ARBITRATION AGREEMENT.
          </p>
          <br />
          These Terms of Service are effective as of February 3, 2021.
          <br />
          1. Definitions. When used in this Terms of Service and capitalized,
          <div className="ml-5 pl-1">
            <br />
            A. “Account” means the account you set up by entering your
            information into the Platform and is required to use the
            Marketplace;
            <br />
            <br />
            B. “Agreement” means this Terms of Service;
            <br />
            <br />
            C. “Customer” means the customer who searches for or places an order
            for Products or communicates with a Provider to purchase or consider
            purchasing a Product via the Platform;
            <br />
            <br />
            D. “Fast Semen” means Fast Semen LLC, a Texas limited liability
            company, and its managers, members, officers, investors, employees,
            agents, and other representatives;
            <br />
            <br />
            E. “Marketplace" means Fast Semen’s proprietary, online, marketplace
            connection platform that allows Customers to purchase products from
            or through Providers;
            <br />
            <br />
            F. “Orders” means orders for Products through the Platform from
            Customers;
            <br />
            <br />
            G. “Party” or “Parties” means Fast Semen, You, or both as context
            requires to refer to the parties to this Terms of Service;
            <br />
            <br />
            H. “Personal Information” means any information exchanged under this
            Agreement that (i) identifies or can be used to identify an
            individual (including without limitation, names, telephone numbers,
            addresses, signatures, email addresses, or other unique
            identifiers); or (ii) that can reasonably be used to authenticate an
            individual (including without limitation, name, contact information,
            precise location information, access credentials, persistent
            identifiers, and any information that may be considered ‘personal
            data’ or ‘personal information’ under applicable law);
            <br />
            <br />
            I. “Platform” means the application, website, and services offered
            by Fast Semen, including the Marketplace, enabling Providers and
            Customers to connect and transact business;
            <br />
            <br />
            J. “Products” includes the semen and other goods offered for sale by
            Provider through the Platform;
            <br />
            <br />
            K. “Provider” means the person contracting with Fast Semen to use
            the Platform, the entities the person represents, its agents and
            representatives, and the equine semen collection facilities under
            its control that will use the Platform;
            <br />
            <br />
            L. “Section” when followed by numbers or a combination of numbers
            and letters means that section in this Terms of Service; and
            <br />
            <br />
            M. “Shipping” means depositing a package and all necessary fees to a
            person, courier service, or the US Postal Service to be transported
            and delivered to the addressee on the package.
            <br />
            <br />
            N. “User” or “Users” means all individuals and other persons who
            access or use the Platform, including, without limitation, any
            organizations that register accounts or otherwise access or use the
            Platform through their respective representatives. Except as
            otherwise provided in this Agreement, if you do not agree to be
            bound by the Agreement, you may not access or use the Platform.
            <br />
            <br />
            O. “You” means you, your heirs, assigns, successors, and
            representatives;
            <br />
            <br />
          </div>
          2. Acceptance of this Agreement.
          <div className="ml-5 pl-1">
            <br />
            A. If you install or use the Fast Semen mobile application or other
            software, access Fast Semens website or the Marketplace, create or
            use an Account, or make use of, whether directly or indirectly, the
            Platform or the services provided by Fast Semen,&nbsp; you hereby
            accept and enter into this Agreement with Fast Semen.
            <br />
            <br />
            B. You represent and warrant that:
            <div className="ml-5 pl-1">
              <br />
              (i) you have read, understand, and agree to be bound by this
              Agreement;
              <br />
              <br />
              (ii) you are of legal age in the jurisdiction in which you reside
              to form a binding contract with Fast Semen; and
              <br />
              <br />
              (iii) you have the authority to enter into the Agreement
              personally and, if applicable, on behalf of any organization for
              which you have created an account and to bind such organization to
              the Agreement.
              <br />
            </div>
            <br />
            <br />
            C. The term of this Agreement is perpetual until you cancel or
            terminate your Account by _________________.
          </div>
          3. Independent Business Relationship. &nbsp; Fast Semen provides the
          Marketplace to connect you to Providers to shop for or purchase
          Products. Fast Semen is not a merchant, seller of goods, or delivery
          service; it is an online connection platform. Provider and Fast Semen
          are independent businesses. Nothing in the Parties' agreements,
          relationship, or transactions will create or be construed as creating
          an agency, partnership, fiduciary relationship, or joint venture
          relationship between Fast Semen and any Provider or between Fast Semen
          and you. You acknowledge and agree that Fast Semen does not itself
          collect, package, delivery, or sell any Products, and has no
          responsibility or liability for the acts or omissions of any Provider.
          Fast Semen provides a technology platform facilitating the
          transmission of orders by you to Providers for pickup or delivery by
          Providers. Fast Semen will not assess or guarantee the suitability,
          legality, or ability of any Provider. You agree that Fast Semen is not
          responsible or liable for any acts or omissions by any Provider,
          including but not limited to the display, marketing, description,
          collection, packaging, or delivery of any Products. You agree that
          Fast Semen does not verify Providers’ compliance with applicable laws
          or regulations.
          <br />
          <br />
          You agree that the Products that you purchase will be collected,
          packaged, and delivered by the Provider you have selected; that title
          to the goods passes from the Provider to you upon Provider shipping to
          you or you picking up the Product from Provider; and you agree that
          Fast Semen never holds title to or acquires any ownership interest in
          any Product that you order through the Platform.
          <br />
          <br />
          4. Modifications. &nbsp; Fast Semen reserves the right to modify the
          terms and conditions of this Agreement or its policies relating to the
          Platform at any time, effective upon posting of an updated version of
          this Agreement through the Platform. You should regularly review this
          Agreement, as your continued use of the Platform after any such
          changes constitutes your agreement to such changes.
          <br />
          <br />
          5. Additional Terms and Policies.&nbsp; By using the Platform, you
          agree to be bound by this Agreement and acknowledge and agree to the
          collection, use, and disclosure of your Personal Information in
          accordance with Fast Semen's Privacy Policy, which is incorporated in
          this Agreement by reference. You also agree to abide by any additional
          Fast Semen policies for Users that are published on our website or
          mobile application. Certain features of our Platform may be subject to
          additional terms and conditions, which are incorporated herein by
          reference.
          <br />
          <br />
          6. Rules and Prohibitions. Without limiting other rules and
          prohibitions in this Agreement, by using the Platform, you agree that:
          <br />
          <br />
          <div className="ml-5 pl-1">
            A. You will only use the Platform for lawful purposes; you will not
            use the Platform for sending or storing any unlawful material or for
            deceptive or fraudulent purposes; and you will not engage in conduct
            that harms other Users, Fast Semen employees, or the Fast Semen
            community.
            <br />
            <br />
            B. You will only use the Platform in accordance with all applicable
            laws, including copyrights, trade secrets, or other rights of any
            third party, including privacy or publicity rights.
            <br />
            <br />
            C. You will only access the Platform using means explicitly
            authorized by Fast Semen.
            <br />
            <br />
            D. You will not use another User's account, impersonate any person
            or entity, or forge or manipulate headers or identifiers to disguise
            the origin of any content transmitted through the Platform.
            <br />
            <br />
            E. You will not use the Platform to cause nuisance, annoyance, or
            inconvenience.
            <br />
            <br />
            F. Other than to transact business with and purchase Products from
            or through Providers, you will not use the Platform, or any content
            accessible through the Platform, for any commercial purpose,
            including but not limited to contacting, advertising to, soliciting,
            or selling to, any Provider, other User, or Contractor, unless Fast
            Semen has given you prior permission in writing to do so.
            <br />
            <br />
            G. You will not copy or distribute any part of the Platform or any
            content displayed through the Platform, including Providers’ menu
            content and reviews, for republication in any format or media.
            <br />
            <br />
            H. You will not compile, directly or indirectly, any content
            displayed through the Platform except for your personal use.
            <br />
            <br />
            I. The information you provide to Fast Semen when you register an
            account or otherwise communicate with Fast Semen is accurate. You
            will promptly update your account or otherwise notify Fast Semen of
            any changes to such information, and you will provide Fast Semen
            with whatever proof of identity Fast Semen may reasonably request.
            <br />
            <br />
            J. You will keep secure and confidential your account password or
            any identification credentials we provide you which allows access to
            the Platform.
            <br />
            <br />
            K. You will use the Platform only for your own use and will not
            directly or indirectly resell, license, or transfer any part of the
            Platform or content displayed by the Platform to a third party.
            <br />
            <br />
            L. You will not use the Platform in any way that could damage,
            disable, overburden, or impair any Fast Semen server, or the
            networks connected to any Fast Semen server.
            <br />
            <br />
            M. You will not attempt to gain unauthorized access to the Platform
            or to any account, resource, computer system, or network connected
            to any Fast Semen server.
            <br />
            <br />
            N. You will not probe, scan, or test the vulnerability of any system
            or network or breach or circumvent any security or authentication
            measures Fast Semen may use to prevent or restrict access to the
            Platform or use of the Platform or the content therein.
            <br />
            <br />
            O. You will not deep-link to any Fast Semen websites or access Fast
            Semen websites manually or with any robot, spider, web crawler,
            extraction software, automated process or device to scrape, copy,
            index, frame, or monitor any portion of or any content on Fast Semen
            websites.
            <br />
            <br />
            P. You will not scrape or otherwise conduct any systematic retrieval
            of data or other content from the Platform.
            <br />
            <br />
            Q. You will not try to harm other Users, Providers, Fast Semen, or
            the Platform in any way whatsoever.
            <br />
            <br />
            R. You will not engage in threatening, harassing, racist, sexist, or
            discriminatory behavior, or any other behavior that Fast Semen deems
            inappropriate when using the Platform.
            <br />
            <br />
            S. You will report any errors, bugs, unauthorized access
            methodologies, or any breach of Fast Semen's intellectual property
            rights that you uncover in your use of the Platform.
            <br />
            <br />
            T. You will not attempt to undertake any of the foregoing.
            <br />
            <br />
          </div>
          In the event that Fast Semen believes or determines that you have
          breached any of the aforementioned, Fast Semen reserves the right to
          suspend or permanently deactivate your account at its sole discretion.
          <br />
          <br />
          7. Customer Account.&nbsp; You may be required to register for an
          Account to use parts of the Platform. You must provide accurate,
          current, and complete information during the registration process and
          at all other times when you use the Platform. You must update the
          information to keep it accurate, current, and complete. You are the
          sole authorized user of any Account you create through the Platform.
          You are solely and fully responsible for all activities that occur
          under your password or Account. You agree that you will monitor your
          account to prevent any unauthorized use, and you will accept full
          responsibility for any unauthorized use of your password or your
          Account. Should you suspect that any unauthorized party may be using
          your password or Account, you will notify Fast Semen immediately. Fast
          Semen will not be liable and you may be liable for losses, damages,
          liability, expenses, and fees incurred by Fast Semen or a third party
          arising from someone else using your Account, regardless of whether
          you have notified Fast Semen of such unauthorized use. If you provide
          any information that is untrue, inaccurate, not current, or
          incomplete, or Fast Semen has reasonable grounds to suspect that such
          information is untrue, inaccurate, not current, or incomplete, Fast
          Semen has the right to suspend or terminate your Account and refuse
          any and all current or future use of the Platform (or any portion
          thereof). Fast Semen may enable or require you to use a single pair of
          login credentials to use the Platform. You agree not to create an
          Account or use the Platform if you have been previously removed by
          Fast Semen, or if you have been previously banned from use of the
          Platform.
          <br />
          <br />
          8. Customer Content.
          <div className="ml-5 pl-1">
            A. Customer Content.&nbsp; Fast Semen may provide you with
            interactive opportunities through the Platform, including, by way of
            example, the ability to post Customer ratings and reviews
            (collectively, “Customer Content”). You represent and warrant that
            you are the owner of, or otherwise have the right to provide, all
            Customer Content that you submit, post, or otherwise transmit
            through the Platform. You further represent and warrant that any
            Customer Content that you submit, post, or otherwise transmit
            through the Platform (i) does not violate any third-party right,
            including any copyright, trademark, patent, trade secret, privacy
            right, right of publicity, or any other intellectual property or
            proprietary right; (ii) does not contain material that is false,
            intentionally misleading, or defamatory; (iii) does not contain any
            material that is unlawful; (iv) does not violate any law or
            regulation; and (v) does not violate this Agreement. You hereby
            grant Fast Semen a perpetual, irrevocable, transferable, fully paid,
            royalty-free, non-exclusive, worldwide, fully sublicenseable right
            and license to use, copy, display, publish, modify, remove, publicly
            perform, translate, create derivative works, distribute, or
            otherwise use the Customer Content in connection with Fast Semen’s
            business and in all forms now known or hereafter invented (“Uses”),
            without notification to or approval by you. You further grant Fast
            Semen a license to use your username, first name, and last initial,
            or other Customer profile information, including without limitation,
            your ratings history, to attribute Customer Content to you in
            connection with such Uses, without notification or approval by you.
            You agree that this license includes the right for other Customers
            to access and use your Customer Content in conjunction with
            participation in the Platform and as permitted through the
            functionality of the Platform. In the interest of clarity, the
            license granted to Fast Semen herein will survive termination of the
            Platform or your account. Fast Semen reserves the right in its sole
            discretion to remove or disable access to any Customer Content from
            the Platform, suspend or terminate your account at any time, or
            pursue any other remedy or relief available under equity or law if
            you post any Customer Content that violates this Agreement or any
            community or content guidelines Fast Semen may publish or that Fast
            Semen considers to be objectionable for any reason. You agree that
            Fast Semen may monitor or delete your Customer Content (but does not
            assume the obligation) for any reason in Fast Semen's sole
            discretion. Fast Semen may also access, read, preserve, and disclose
            any information as Fast Semen reasonably believes is necessary to
            (i) satisfy any applicable law, regulation, legal process, or
            governmental request, (ii) enforce this Agreement, including
            investigation of potential violations hereof, (iii) detect, prevent,
            or otherwise address fraud, security, or technical issues, (iv)
            respond to Customer support requests, or (e) protect the rights,
            property, or safety of Fast Semen, its users, and the public.
            <br />
            <br />
            B. Feedback.&nbsp; You agree that any submission of any ideas,
            suggestions, or proposals to Fast Semen through its suggestion,
            feedback, wiki, forum, or similar pages (“Feedback”) is at your own
            risk, and that Fast Semen has no obligations (including without
            limitation, obligations of confidentiality) with respect to such
            Feedback. You represent and warrant that you have all rights
            necessary to submit the Feedback and you hereby grant to Fast Semen
            a perpetual, irrevocable, transferable, fully paid, royalty-free,
            non-exclusive, worldwide, fully sublicenseable right and license to
            use, copy, display, publish, modify, remove, publicly perform,
            translate, create derivative works, distribute or otherwise use such
            Feedback.
            <br />
            <br />
            C. Ratings and Reviews.&nbsp; To the extent that you are asked to
            rate and post reviews of Providers or Products, such Ratings and
            Reviews are considered Customer Content and are governed by this
            Agreement. Ratings and Reviews are not endorsed by Fast Semen and do
            not represent the views of Fast Semen or its affiliates. Fast Semen
            will have no liability for Ratings and Reviews or for any claims for
            economic loss resulting from such Ratings and Reviews. Because Fast
            Semen strive to maintain a high level of integrity with respect to
            Ratings and Reviews posted or otherwise made available through the
            Platform, you agree that: (i) you will base any Rating or Review on
            first-hand experience with the Provider or Product; (ii) you will
            not provide a Rating or Review for any Provider or Product for which
            you have an ownership interest, employment relationship, or other
            affiliation, or for any of that company’s competitors; (iii) you
            will not submit a Rating or Review in exchange for payment, free or
            discounted Products or any other item from Provider, or other
            benefits from a Provider; (iv) any Rating or Review you submit will
            comply with the Federal Trade Commission’s Guides Concerning the Use
            of Endorsements and Testimonials in Advertising; and (v) your Rating
            or Review will comply with the terms of this Agreement. If Fast
            Semen determine, in its sole discretion, that any Rating or Review
            could diminish the integrity of the Ratings and Reviews or otherwise
            violates this Agreement, Fast Semen may remove such Customer Content
            without notice.
            <br />
          </div>
          <br />
          9. Communications with Fast Semen.&nbsp; By creating an Account, you
          electronically agree to accept and receive communications from Fast
          Semen, Providers, or third parties providing services to Fast Semen
          including via email, text message, calls, and push notifications to
          the cellular telephone number you provided to Fast Semen. You
          understand and agree that you may receive communications generated by
          automatic telephone dialing systems or which will deliver prerecorded
          messages sent by or on behalf of Fast Semen, its affiliated companies,
          or Providers, including but not limited to communications concerning
          orders placed through your Account on the Platform. Message and data
          rates may apply. If you do not wish to receive promotional emails,
          text messages, or other communications, you may change your
          notification preferences by accessing Settings in your Account. To opt
          out of receiving promotional text messages from Fast Semen, you must
          reply “STOP” from the mobile device receiving the messages. For
          purposes of clarity, delivery text messages between you and Providers
          are transactional text messages, not promotional text messages.
          <br />
          <br />
          By creating an Account, you also consent to the use of an electronic
          record to document your agreement. You may withdraw your consent to
          the use of the electronic record by emailing Fast Semen at
          contact@fastsemen.com with “Revoke Electronic Consent” in the subject
          line.
          <br />
          <br />
          To view and retain a copy of this disclosure, you will need (i) a
          device (such as a computer or mobile phone) with a web browser and
          Internet access and (ii) either a printer or storage space on such
          device. For a free paper copy, or to update our records of your
          contact information, email Fast Semen at contact@fastsemen.com with
          contact information and your mailing address.
          <br />
          <br />
          10. Intellectual Property Ownership.&nbsp; Fast Semen alone (and its
          licensors, where applicable) shall own all right, title and interest,
          including all related intellectual property rights, in and to the
          Platform. This Agreement is not a sale and does not convey to you any
          rights of ownership in or related to the Platform, or any intellectual
          property rights owned by Fast Semen. Fast Semen names, Fast Semen
          logos, and the product names associated with the Platform are
          trademarks of Fast Semen or third parties, and no right or license is
          granted to use them. You agree that you will not remove, alter, or
          obscure any copyright, trademark, service mark, or other proprietary
          rights notices incorporated in or accompanying the Platform.
          <br />
          <br />
          11. Payment Terms.
          <div className="ml-5 pl-1">
            <br />
            A. Prices & Charges.&nbsp; You understand that the prices for
            Products displayed through the Platform are controlled exclusively
            by the Provider, and Fast Semen has no obligation to itemize its
            costs, profits, or margins when publishing such prices. In the event
            that the charge to your payment method may incorrectly differ from
            the total amount, including subtotal and fees displayed to you at
            checkout, Fast Semen reserves the right to make an additional charge
            to your payment method after the initial charge so that the total
            amount charged is consistent with the total amount displayed to you
            at checkout. All payments will be processed by Fast Semen or its
            payments processor, using the preferred payment method designated by
            you or in your Account. If your payment details change, your card
            provider may provide Fast Semen with updated card details. Fast
            Semen may use these new details or details from other cards on file
            in order to help prevent any interruption to your use of the
            Platform. If you would like to use a different payment method or if
            there is a change in payment method, please update your Account.
            <br />
            <br />
            B. No Refunds.&nbsp; Charges paid by you for completed and delivered
            orders are final and non-refundable. Fast Semen has no obligation to
            provide refunds or credits, but may grant them, in each case in Fast
            Semen’s sole discretion.
            <br />
            <br />
          </div>
          <br />
          12. Third-Party Interactions.
          <div className="ml-5 pl-1">
            <br />
            A. Third-Party Websites, Applications, and Advertisements.&nbsp; The
            Platform may contain links to third-party websites (“Third-Party
            Websites”) and applications (“Third-Party Applications”) and
            advertisements (“Third-Party Advertisements”) (collectively,
            “Third-Party Websites & Advertisements”). When you click on a link
            to any Third-Party Websites & Advertisements, Fast Semen will not
            warn you that you have left Fast Semen’s website or Platform and
            will not warn you that you are subject to the terms and conditions
            (including privacy policies) of another website or destination. Such
            Third-Party Websites & Advertisements are not under the control of
            Fast Semen. Fast Semen is not responsible for any Third-Party
            Websites & Advertisements. Fast Semen does not review, approve,
            monitor, endorse, warrant, or make any representations with respect
            to such Third-Party Websites & Advertisements, or their products or
            services. You use all links in Third-Party Websites & Advertisements
            at your own risk. You should review applicable terms and policies,
            including privacy and data gathering practices of any Third-Party
            Websites & Applications, and make whatever investigation you feel
            necessary or appropriate before proceeding with any transaction with
            any third party.
            <br />
            <br />
            B. App Stores.&nbsp; You acknowledge and agree that the availability
            of the Platform is dependent on the third party from which you
            received the application license, e.g., the Apple iPhone or Android
            app stores (“App Store”). You acknowledge and agree that this
            Agreement is between you and Fast Semen and not with the App Store.
            Fast Semen, not the App Store, is solely responsible for the
            Platform, including the mobile application(s), the content thereof,
            maintenance, support services and warranty therefor, and addressing
            any claims relating thereto (e.g., product liability, legal
            compliance or intellectual property infringement). In order to use
            the Platform, you must have access to a wireless network, and you
            agree to pay all fees associated with such access. You also agree to
            pay all fees (if any) charged by the App Store in connection with
            the Platform. You agree to comply with, and your license to use the
            Platform is conditioned upon your compliance with, all applicable
            third-party terms of agreement (e.g., the App Store’s terms and
            policies) when using the Platform. You represent and warrant that
            you are not located in a country that is subject to a U.S.
            Government embargo, or that has been designated by the U.S.
            Government as a “terrorist supporting” country, and you represent
            and warrant that you are not listed on any U.S. Government list of
            prohibited or restricted parties. You acknowledge and agree that
            each App Store (and its affiliates) is an intended third-party
            beneficiary of this Agreement and has the right to enforce the terms
            and conditions of this Agreement.
            <br />
          </div>
          <br />
          13. Social Media Guidelines.&nbsp; Fast Semen maintains certain social
          media pages for the benefit of the Fast Semen community. By posting,
          commenting, or otherwise interacting with these pages, you agree to
          abide by Fast Semen's Social Media Community Guidelines.
          <br />
          <br />
          14. Indemnification.&nbsp; You agree to indemnify and hold harmless
          Fast Semen and its officers, directors, employees, agents, and
          affiliates (each, an “Indemnified Party”), from and against any
          losses, claims, actions, costs, damages, penalties, fines, and
          expenses, including without limitation, attorneys’ fees and expenses,
          that may be incurred by an Indemnified Party arising out of, relating
          to or resulting from (a) your Customer Content; (b) your misuse of the
          Platform; (c) your breach of this Agreement or any representation,
          warranty, or covenant in this Agreement; or (d) your violation of any
          applicable laws, rules, or regulations through or related to the use
          of the Platform. In the event of any claim, allegation, suit, or
          proceeding alleging any matter potentially covered by the agreements
          in this Section 14, you agree to pay for the defense of the
          Indemnified Party, including reasonable costs and attorneys’ fees
          incurred by the Indemnified Party. Fast Semen reserves the right, at
          its own cost, to assume the exclusive defense and control of any
          matter otherwise subject to indemnification by you, in which event you
          will fully cooperate with Fast Semen in asserting any available
          defenses. This provision does not require you to indemnify any
          Indemnified Party for any unconscionable commercial practice by such
          party, or for such party’s negligence, fraud, deception, false
          promise, misrepresentation or concealment, suppression or omission of
          any material fact in connection with the Platform. You agree that the
          provisions in this section will survive any termination of your
          Account, this Agreement, or your access to the Platform.
          <br />
          <br />
          15. Disclaimer of Warranties.&nbsp; YOU EXPRESSLY UNDERSTAND AND AGREE
          THAT TO THE FULLEST EXTENT OF LAW, YOUR USE OF THE PLATFORM IS
          ENTIRELY AT YOUR OWN RISK. CHANGES ARE PERIODICALLY MADE TO THE
          PLATFORM, TERMS OF SERVICE, AND OTHER POLICIES, AND MAY BE MADE AT ANY
          TIME WITHOUT NOTICE TO YOU. THE PLATFORM IS PROVIDED ON AN “AS IS”
          BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. FAST SEMEN MAKES NO
          WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY, RELIABILITY,
          COMPLETENESS, OR TIMELINESS OF THE CONTENT MADE AVAILABLE THROUGH THE
          PLATFORM, OR THE SERVICES, SOFTWARE, TEXT, GRAPHICS, OR LINKS.
          <br />
          <br />
          FAST SEMEN DOES NOT WARRANT THAT THE PLATFORM WILL OPERATE ERROR-FREE
          OR THAT THE PLATFORM IS FREE OF COMPUTER VIRUSES AND OTHER HARMFUL
          MALWARE. IF YOUR USE OF THE PLATFORM RESULTS IN THE NEED FOR SERVICING
          OR REPLACING EQUIPMENT OR DATA, FAST SEMEN WILL NOT BE RESPONSIBLE FOR
          THOSE ECONOMIC COSTS.
          <br />
          <br />
          16. Internet Delays. The Platform may be subject to limitations,
          delays, and other problems inherent in the use of the Internet and
          electronic communications. Except as set forth in Fast Semen's Privacy
          Policy or as otherwise required by applicable law, Fast Semen is not
          responsible for any delays, delivery failures, or damage, loss, or
          injury resulting from such problems.
          <br />
          <br />
          17. Breach And Limitation of Liability.
          <div className="ml-5 pl-1">
            <br />
            A. General. You understand and agree that a key element of the
            Platform and this Agreement is the Parties’ mutual desire to keep
            the Platform simple and efficient, and to provide the Platform at
            low cost. You understand and agree to the limitations on remedies
            and liabilities set forth in this Section 17 to keep thePlatform
            simple and efficient, and costs low, for all Users.
            <br />
            <br />
            B. Cap on Liability. TO THE FULLEST EXTENT PERMITTED BY LAW, FAST
            SEMEN’S AGGREGATE LIABILITY SHALL NOT EXCEED THE GREATER OF AMOUNTS
            ACTUALLY PAID BY OR DUE FROM YOU TO FAST SEMEN IN THE SIX MONTH
            PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM.
            THIS CAP ON LIABILITY SHALL APPLY FULLY TO RESIDENTS OF NEW JERSEY.
            <br />
            <br />
            C. Disclaimer of Certain Damages. TO THE FULLEST EXTENT OF LAW, FAST
            SEMEN SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY INDIRECT,
            PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL, OR OTHER
            DAMAGES OF ANY TYPE OR KIND (INCLUDING PERSONAL INJURY, LOST
            PROFITS, PAIN AND SUFFERING, EMOTIONAL DISTRESS, AND LOSS OF DATA,
            REVENUE, USE, AND ECONOMIC ADVANTAGE).
            <br />
            <br />
          </div>
          18. Payment Processing.&nbsp; Payment processing services on the
          Platform are provided by Adyen and are subject to the Adyen Terms and
          Conditions and Adyen pricing. You agree to be bound by the Ayden terms
          and conditions, as the same may be modified by Adyen from time to
          time. As a condition of Fast Semen enabling payment processing
          services through Adyen, you authorize Fast Semen and Ayden to share
          information related to you and your transaction to the extent that it
          is necessary to resolve a problem with your transaction.
          <br />
          <br />
          19. Dispute Resolution.&nbsp; PLEASE READ THE FOLLOWING SECTION
          CAREFULLY. IT REQUIRES PROVIDER TO ARBITRATE DISPUTES WITH FAST SEMEN
          AND LIMITS THE MANNER IN WHICH PROVIDER CAN SEEK RELIEF. THIS SECTION
          16 OF THIS AGREEMENT WILL BE REFERRED TO AS THE “ARBITRATION
          AGREEMENT”.
          <div className="ml-5 pl-1">
            A. Scope of Arbitration Agreement. Any dispute, controversy or claim
            arising out of, relating to, or in connection with this contract,
            including the breach, termination, or validity thereof, will be
            finally resolved by binding arbitration, rather than in court,
            except that (i) the Parties may assert claims in small claims court
            if the Parties’ claims qualify, so long as the matter remains in
            such court and advances only on an individual (non-class,
            non-representative) basis; and (ii) the Parties may seek injunctive
            relief in court for infringement or other misuse of intellectual
            property rights (such as trademarks, trade dress, domain names,
            trade secrets, copyrights, and patents). The Parties agree that,
            because both are businesses that mutually benefit from streamlined
            and confidential resolution, this Arbitration Agreement will apply
            to all disputes arising from or relating to the subject matter of
            this Agreement or the relationship between the Parties and their
            personnel. In that regard, this Arbitration Agreement will be
            binding upon and enforceable by not only the Parties, but also their
            affiliates, and their owners, officers, directors, managers,
            employees, and other representatives. This Arbitration Agreement
            will apply, without limitation, to all claims that arose or were
            asserted before the Effective Date of this Agreement.
            <br />
            <br />
            B. Informal Resolution. The Parties agree that good-faith, informal
            efforts to resolve disputes often can result in a prompt, low-cost,
            and mutually beneficial outcome. The Parties, therefore, agree that,
            before either Party demands arbitration against the other, the
            Parties will personally meet and confer, via telephone or
            videoconference, in a good-faith effort to resolve informally any
            claim covered by this mutual Arbitration Agreement. If a Party is
            represented by counsel, the Party’s counsel may participate in the
            conference, but the Party must also fully participate in the
            conference. The Party initiating the claim must give notice to the
            other Party in writing of its intent to initiate an informal dispute
            resolution conference. Such conference must occur within 60 days
            after the other Party receives such notice, unless an extension is
            mutually agreed upon by the Parties. To notify Fast Semen that
            Provider intends to initiate an informal dispute resolution
            conference, email contact@fastsemen.com , providing your name,
            telephone number associated with your Fast Semen account (if any),
            the email address associated with your Fast Semen account, and a
            description of your claim. In the interval between the Party
            receiving such notice and the informal dispute resolution
            conference, the Parties will be free to attempt to resolve the
            initiating Party’s claims. Engaging in an informal dispute
            resolution conference is a requirement that must be fulfilled before
            commencing arbitration. The statute of limitations and any filing
            fee deadlines will be tolled while the Parties engage in the
            informal dispute resolution process required by this paragraph.
            <br />
            <br />
            C. Arbitration Rules and Forum. This Arbitration Agreement is
            governed by the Federal Arbitration Act in all respects. If, for
            whatever reason, the rules and procedures of the Federal Arbitration
            Act cannot apply, the state law governing arbitration agreements in
            the State of Texas shall apply. Before a Party may begin an
            arbitration proceeding, that Party must send notice of an intent to
            initiate arbitration and certifying completion of the informal
            dispute resolution conference pursuant to section 16.B. If this
            notice is being sent to Fast Semen, it must be sent by email to the
            counsel who represented Fast Semen in the informal dispute
            resolution process, or if there was no such counsel then by email to
            contact@fastsemen.com . The arbitration will be conducted by JAMS
            under its rules and pursuant to the terms of this Agreement, but in
            the event of a conflict between the two, the provisions of this
            Arbitration Agreement shall supersede any and all conflicting
            arbitration administrator’s rules or procedures. Arbitration demands
            filed with JAMS must include (i) the name, telephone number, mailing
            address, and e-mail address of the Party seeking arbitration; (ii) a
            statement of the legal claims being asserted and the factual bases
            of those claims; (iii) a description of the remedy sought and an
            accurate, good-faith calculation of the amount in controversy,
            enumerated in United States Dollars (any request for injunctive
            relief or attorneys’ fees shall not count toward the calculation of
            the amount in controversy unless such injunctive relief seeks the
            payment of money); and (iv) the signature of the Party seeking
            arbitration. Disputes involving claims and counterclaims under
            $250,000, not inclusive of attorneys’ fees and interest, shall be
            subject to JAMS’s most current version of the Streamlined
            Arbitration Rules and procedures available at
            http://www.jamsadr.com/rules-streamlined-arbitration/; all other
            claims shall be subject to JAMS’s most current version of the
            Comprehensive Arbitration Rules and Procedures, available at
            http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS’s
            rules are also available at www.jamsadr.com (under the Rules/Clauses
            tab) or by calling JAMS at 800-352-5267. Payment of all filing,
            administration, and arbitration fees will be governed by JAMS’s
            rules. If JAMS is not available to arbitrate, the Parties will
            select an alternative arbitral forum. The Parties may choose to have
            the arbitration conducted by telephone, video conference, or in
            person in the State of Texas or at another mutually agreed location.
            <br />
            <br />
            D. Arbitrator Powers. The arbitrator, and not any federal, state, or
            local court or agency, shall have exclusive authority to resolve any
            dispute relating to the interpretation, applicability,
            enforceability, or formation of this Arbitration Agreement
            including, but not limited to any claim that all or any part of this
            Arbitration Agreement is void or voidable. All disputes regarding
            the payment of arbitrator or arbitration-organization fees including
            the timing of such payments and remedies for nonpayment, shall be
            determined exclusively by an arbitrator, and not by any court. The
            arbitration will decide the rights and liabilities, if any, of you
            and Fast Semen. The arbitration proceeding will not be consolidated
            with any other matters or joined with any other proceedings or
            parties. The arbitrator will have the authority to grant motions
            dispositive of all or part of any claim or dispute. The arbitrator
            will have the authority to award monetary damages and to grant any
            non-monetary remedy or relief available to an individual under
            applicable law, the arbitral forum’s rules, and this Agreement
            (including this Arbitration Agreement). The arbitrator will issue a
            written statement of decision describing the essential findings and
            conclusions on which any award (or decision not to render an award)
            is based, including the calculation of any damages awarded. The
            arbitrator shall follow the applicable law. The arbitrator has the
            same authority to award relief on an individual basis that a judge
            in a court of law would have. The arbitrator’s decision is final and
            binding on you and Fast Semen.
            <br />
            <br />
            E. Waiver of Jury Trial. THE PARTIES WAIVE ANY CONSTITUTIONAL AND
            STATUTORY RIGHTS TO SUE IN COURT AND RECEIVE A JUDGE OR JURY TRIAL.
            The Parties are instead electing to have claims and disputes
            resolved by arbitration if no informal resolution can be had. There
            is no judge or jury in arbitration, and court review of an
            arbitration award is limited.
            <br />
            <br />
            F. Waiver of Class or Consolidated Actions. THE PARTIES AGREE TO
            WAIVE ANY RIGHT TO RESOLVE CLAIMS WITHIN THE SCOPE OF THIS
            ARBITRATION AGREEMENT ON A CLASS, COLLECTIVE, OR REPRESENTATIVE
            BASIS. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
            AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A
            CLASS BASIS. CLAIMS OF MORE THAN USER CANNOT BE ARBITRATED OR
            LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER USER. If,
            however, this waiver of class or consolidated actions is deemed
            invalid or unenforceable with respect to a particular claim or
            dispute, neither Party is entitled to arbitration of such claim or
            dispute. Instead, all such claims and disputes will then be resolved
            in a court, and all other provisions of this Section 16 (Dispute
            Resolution) will remain in force.
            <br />
            <br />
            G. Survival. This Arbitration Agreement will survive any termination
            of your relationship with Fast Semen.
            <br />
            <br />
            H. Severability. If any provision of this Section 19 is adjudged to
            be void or otherwise unenforceable, in whole or in part, the void or
            unenforceable provision will be severed and such adjudication will
            not affect the validity of the remainder of this Section 19. Nothing
            in this provision will prevent the Parties from participating in a
            class-wide settlement of claims.
            <br />
            <br />
            I. Survival. This Arbitration Agreement will survive any termination
            of the relationship between the Parties. <br />
            <br />
            J. Modification. Notwithstanding any provision in the Agreement to
            the contrary, the Parties agree that if Fast Semen makes any future
            material change to this Arbitration Agreement, it will not apply to
            any individual claim(s) that a Provider had already provided notice
            of to Fast Semen.
            <br />
          </div>
          <br />
          20. Litigation Class Action Waiver. To the extent allowed by
          applicable law, separate and apart from the Arbitration Agreement, you
          agree that any proceeding to litigate in court any dispute arising out
          of or relating to this Agreement will be conducted solely on an
          individual basis, and you agree not to seek to have any controversy,
          claim, or dispute heard as a class action, a representative action, a
          collective action, a private attorney-general action, or in any
          proceeding in which you act or propose to act in a representative
          capacity. You further agrees that no proceeding will be joined,
          consolidated, or combined with another proceeding, without the prior
          written consent of all parties to any such proceeding. If this waiver
          of litigation class or consolidated actions is deemed invalid or
          unenforceable, in whole or in part, the invalid or unenforceable
          provision will be severed and such adjudication will not affect the
          validity of the remainder of this Agreement. Nothing in this provision
          will prevent the Parties from participating in a class-wide settlement
          of claims.
          <br />
          <br />
          21. Exclusive Venue. To the extent the Parties are permitted under
          this Agreement to initiate litigation in a court, the Parties agree
          that all claims and disputes arising out of or relating to the
          Agreement will be litigated exclusively in the state or federal courts
          having jurisdiction in Erath County, Texas.
          <br />
          <br />
          22. Termination. If you violate this Agreement, Fast Semen may respond
          based on a number of factors including, but not limited to, the
          egregiousness of your actions and whether a pattern of harmful
          behavior exists.
          <br />
          In addition, at its sole discretion, Fast Semen may modify or
          discontinue the Platform, or may modify, suspend or terminate your
          access to the Platform, for any reason, with or without notice to you
          and without liability to you or any third party. In addition to
          suspending or terminating your access to the Platform, Fast Semen
          reserves the right to take appropriate legal action, including without
          limitation, pursuing civil, criminal, or injunctive redress. Even
          after your right to use the Platform is terminated, this Agreement
          will remain enforceable against you. All provisions which by their
          nature should survive to give effect to those provisions shall survive
          the termination of this Agreement.
          <br />
          <br />
          23. Procedure for Making Claims of Copyright Infringement. It is Fast
          Semen’s policy to terminate the privileges of any User who repeatedly
          infringes copyright upon prompt notification to Fast Semen by the
          copyright owner or the copyright owner’s legal agent. Without limiting
          the foregoing, if you believe that your work has been copied and
          posted on the Platform in a way that constitutes copyright
          infringement, please provide Fast Semen with the following
          information: (a) an electronic or physical signature of the person
          authorized to act on behalf of the owner of the copyright interest;
          (b) a description of the copyrighted work that you claim has been
          infringed; (c) a description of the location on the Platform of the
          material that you claim is infringing; (d) your address, telephone
          number, and e-mail address; (e) a written statement by you that you
          have a good faith belief that the disputed use is not authorized by
          the copyright owner, its agent, or the law; and (f) a statement by
          you, made under penalty of perjury, that the above information in your
          notice is accurate and that you are the copyright owner or authorized
          to act on the copyright owner’s behalf.
          <br />
          <br />
          24. General.
          <br />
          <div className="ml-5 pl-1">
            A. No joint venture, partnership, employment, or agency relationship
            exists between you, Fast Semen, or any third-party provider as a
            result of this Agreement or use of the Platform.
            <br />
            <br />
            B. This Agreement is governed by the laws of the State of Texas,
            without giving effect to any principles that provide for the
            application of the law of any other jurisdiction.
            <br />
            <br />
            C. Except as otherwise provided in this Agreement, if any provision
            of this Agreement is found to be invalid, the invalidity of such
            provision shall not affect the validity of the remaining provisions
            of this Agreement, which shall remain in full force and effect.
            <br />
            <br />
            D. In accordance with California Civil Code § 1789.3, you may report
            complaints to the Complaint Assistance Unit of the Division of
            Consumer Services of the California Department of Consumer Affairs
            by contacting them in writing at 400 R Street, Sacramento, CA 95814,
            or by telephone at (800) 952-5210.
            <br />
            <br />
            E. The following applies to any Software accessed through or
            downloaded from the Apple App Store (an “App Store Sourced
            Application”):
            <br />
            <br />
            <div className="ml-5 pl-1">
              (i) You acknowledge and agree that (i) the Agreement is concluded
              between you and Fast Semen only, and not Apple, and (ii) Fast
              Semen, not Apple, is solely responsible for the App Store Sourced
              Application and content thereof. Your use of the App Store Sourced
              Application must comply with the App Store Terms of Service.
              <br />
              <br />
              (ii) You acknowledge that Apple has no obligation whatsoever to
              furnish any maintenance and support services with respect to the
              App Store Sourced Application.
              <br />
              <br />
              (iii) In the event of any failure of the App Store Sourced
              Application to conform to any applicable warranty, you may notify
              Apple, and Apple will refund the purchase price, if any, for the
              App Store Sourced Application to you and to the fullest extent
              permitted by law, Apple will have no other warranty obligation
              whatsoever with respect to the App Store Sourced Application. As
              between Fast Semen and Apple, any other claims, losses,
              liabilities, damages, costs or expenses attributable to any
              failure to conform to any warranty will be the sole responsibility
              of Fast Semen.
              <br />
              <br />
              (iv) You and Fast Semen acknowledge that, as between Fast Semen
              and Apple, Apple is not responsible for addressing any claims you
              have or any claims of any third party relating to the App Store
              Sourced Application or your possession and use of the App Store
              Sourced Application, including, but not limited to: (i) product
              liability claims; (ii) any claim that the App Store Sourced
              Application fails to conform to any applicable legal or regulatory
              requirement; and (iii) claims arising under consumer protection or
              similar legislation.
              <br />
              <br />
              (v) You and Fast Semen acknowledge that, in the event of any
              third-party claim that the App Store Sourced Application or your
              possession and use of that App Store Sourced Application infringes
              that third party’s intellectual property rights, as between Fast
              Semen and Apple, Fast Semen, not Apple, will be solely responsible
              for the investigation, defense, settlement and discharge of any
              such intellectual property infringement claim to the extent
              required by the Terms.
              <br />
              <br />
              (vi) You and Fast Semen acknowledge and agree that Apple, and
              Apple’s subsidiaries, are third-party beneficiaries of the Terms
              as related to your license of the App Store Sourced Application,
              and that, upon your acceptance of the terms and conditions of the
              Terms, Apple will have the right (and will be deemed to have
              accepted the right) to enforce the Terms as related to your
              license of the App Store Sourced Application against you as a
              third-party beneficiary thereof.
              <br />
              <br />
              (vii) Without limiting any other terms of the Terms, you must
              comply with all applicable third-party terms of agreement when
              using the App Store Sourced Application.
            </div>
            <br />
            <br />
            F. Where Fast Semen requires that you provide an e-mail address, you
            are responsible for providing Fast Semen with your most current
            e-mail address. In the event that the last e-mail address you
            provided to Fast Semen is not valid, or for any reason is not
            capable of delivering to you any notices required or permitted by
            this Agreement, Fast Semen’s dispatch of the e-mail containing such
            notice will nonetheless constitute effective notice. You may give
            notice to Fast Semen through the following web form:
            contact@fastsemen.com. Such notice shall be deemed given on the next
            business day after such e-mail is actually received by Fast Semen.
            <br />
            <br />
            G. For contractual purposes, you (i) consent to receive
            communications from Fast Semen in an electronic form; and (ii) agree
            that all terms and conditions, agreements, notices, disclosures, and
            other communications that Fast Semen provides to you electronically
            satisfy any legal requirement that such communications would satisfy
            if they were in writing. You agree to keep your contact information,
            including email address, current. This subparagraph does not affect
            your statutory rights.
            <br />
            <br />
            H. This Agreement, and any rights and licenses granted hereunder,
            may not be transferred or assigned by you, but may be assigned by
            Fast Semen without restriction. Any attempted transfer or assignment
            in violation hereof will be null and void. This Agreement binds and
            inures to the benefit of each Party and the Party’s successors and
            permitted assigns.
            <br />
            <br />
            I. This Agreement is the final, complete, and exclusive agreement of
            the Parties with respect to the subject matter hereof and supersedes
            and merges all prior discussions between the Parties with respect to
            such subject matter. However, nothing in this Agreement shall
            supersede, amend, or modify the terms of any separate agreement(s)
            between you and Fast Semen relating to your work as an employee or
            independent contractor, including, without limitation, any
            Independent Contractor Agreement governing your efforts as a
            contractor.
          </div>
          <br />
          24. Contact Information.
          <br />
          <br /> Fast Semen welcomes your questions or comments:
          <br />
          <br /> Fast Semen LLC.
          <br />
          <br /> 330 Lookout Pt
          <br />
          <br /> 76401
          <br />
          <br /> Stephenville, TX
          <br />
          <br /> Help Form: contact@fastsemen.com <br />
          <br />
          Telephone Number: +1 936-662-6920
        </p>
      </div>
      <Footer />
    </>
  );
}

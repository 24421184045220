import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "./styles.css";

function Terms() {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <h1 className="mb-5">Termos of use</h1>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
          pulvinar vestibulum blandit. Duis at volutpat leo. Sed in semper erat.
          In efficitur, purus ac dictum ultrices, orci diam lacinia leo,
          volutpat sagittis nisl lectus ac odio. Orci varius natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Quisque et
          odio dolor. Mauris fringilla, orci et egestas blandit, metus orci
          egestas risus, nec gravida massa mauris et sapien. Suspendisse tempor
          ultricies condimentum. Maecenas quis aliquam quam. Sed id nibh sit
          amet risus fermentum semper quis in arcu. Aliquam ut dui nisi. Sed
          quis varius lectus, quis mattis metus. Cras vehicula dictum velit ut
          tincidunt. Pellentesque porttitor imperdiet fermentum. Cras eget
          tincidunt urna. Curabitur vulputate lacus dui, a tempor nunc tempus
          ut. Ut lobortis hendrerit tellus quis hendrerit. Aliquam lacinia ipsum
          est. Pellentesque sit amet tortor eu turpis mattis imperdiet at at
          ante. Phasellus faucibus nisl vel mi maximus, a eleifend eros
          tincidunt.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Terms;

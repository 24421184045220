import "./styles.css";
import RefusedPay from "../../../assets/recusado.svg";
export default function RefusedPayment() {
  return (
    <div className="screen">
      <img src={RefusedPay} />
      <br />

      <h4>YOUR ORDER WAS DECLINED!</h4>
      <br />
      <br />
      <h5>WHAT A SHAME! FOR SOME REASON, YOUR REQUEST WAS DECLINED.</h5>
    </div>
  );
}

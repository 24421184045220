import { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FaTimes, FaPenSquare } from "react-icons/fa";
import Button from "../../../Components/Button";
import CreateMare from "../../../Components/Modals/RegisterBroodmare";
import ModalWarn from "../../../Components/Modals/ModalWarn";
import api from "../../../services/api";
import { toast } from "react-toastify";

export default function ViewDateMare(props) {
  const [name, setName] = useState("");
  const [number, setNumber] = useState();
  const [breed, setBreed] = useState("");
  const [pregnants, setPregnants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mostModifyMare, setMostModifyMare] = useState(false);
  const [showModalWarn, setShowModalWarn] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get(`/eguas/${props.data}`);
      setName(data.nome);
      setNumber(data.numero_registro);
      setBreed(data.raca);
      const { data: pregnantsDb } = await api.get(
        `/eguas/pregnant/${props.data}`
      );
      setPregnants(pregnantsDb);
      setLoading(false);
    })();
  }, [props.data, props]);

  return (
    <Modal {...props}>
      <Modal.Body>
        <ModalWarn
          show={showModalWarn}
          onHide={() => {
            setShowModalWarn(false);
          }}
          function={async () => {
            await api.delete(`/eguas/delete/${props.data}`);
            toast.success("Mare successfully deleted!");
            setShowModalWarn(false);
            props.onHide();
          }}
        >
          <p className="h3 text-center text-danger">
            As soon as you delete this mare, all your purchases will continue
            with her until you cancel the purchase. <br />
            <br />
            <strong>
              Being aware of this, are you sure you want to continue?
            </strong>
          </p>
        </ModalWarn>
        <CreateMare
          show={mostModifyMare}
          onHide={() => {
            props.onHide();
          }}
          data={props.data}
        />
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-danger mb-3"
            onClick={() => props.onHide()}
          >
            <FaTimes color="#990000" />
          </button>
        </div>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "70vh" }}
          >
            <Spinner animation="border" color="dark" variant="dark" />
          </div>
        ) : (
          <div className="px-3">
            <div className="d-flex justify-content-end flex-column flex-md-row">
              <button
                className="btn btn-danger mx-3 mb-3 mb-md-0"
                onClick={() => setShowModalWarn(true)}
              >
                Delete
              </button>
              <Button onClick={() => setMostModifyMare(true)}>
                Edit Information
                <FaPenSquare size={30} className="ml-2" />
              </Button>
            </div>
            <h5>Mare data</h5>
            <div className="table-responsive">
              <table
                className=""
                style={{ maxWidth: window.innerWidth <= 992 ? "100%" : "75%" }}
              >
                <thead className="bg-light">
                  <tr>
                    <th className="text-truncate col-3">Broodmare</th>
                    <th className="text-truncate col-3">Breed</th>
                    <th className="text-truncate col-3">Registration Number</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-truncate col-3">{name}</td>
                    <td className="text-truncate col-3">{breed}</td>
                    <td className="text-truncate col-3">{number}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <br />
            <div className="d-flex">
              <h5>The mare is pregnant</h5>
              <span className="rounded p-1 px-3 mx-4 border border-success">
                {pregnants.length} Times
              </span>
            </div>
            <div className="table-responsive">
              <table className="table w-100 mt-3">
                <thead>
                  <th>Number</th>
                  <th>Stallion</th>
                  <th>Broodmare</th>
                  <th>Stallion Stations</th>
                  <th>Date</th>
                </thead>
                <tbody>
                  {pregnants.map((pregnant) => {
                    return (
                      <tr>
                        <td>{pregnant.idcompra}</td>
                        <td>{pregnant.garanhao}</td>
                        <td>{pregnant.egua}</td>
                        <td>{pregnant.vendedor}</td>
                        <td>{pregnant.prenha_data}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import "./styles.css";

function AboutUs() {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <h1 className="mb-5">About us</h1>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          Headquartered in Stephenville, Texas, Fastsemen is the first
          technology startup for the management of orders, payments, tracking,
          shipping and receiving equine semen in the world.
          <br />
          <br />
          Starting with operations in the USA and BRAZIL, our platform allows
          for a better connection between veterinarians and stallions,
          maximizing time, facilitating processing and reducing current
          operating costs in the current operating model, usually done by email,
          phone, fax and text message.
          <br />
          <br />
          With a few clicks on your cell phone, tablet or computer, you can
          order and receive orders from stallions available on the platform.
          <br />
          <br />
          In 2016, CEO-FOUNDER Leonardo Lopes identified a flaw in the
          precarious operating system that, in addition to raising costs with
          secretaries, corporate wear and human error, severely affected
          veterinarians who demand great control of their seas and greater
          punctuality and availability of semen. for your breeding programs.
          <br />
          <br />
          Using Fastsemen, a veterinarian can read your actions more securely,
          knowing which stallions are available on the day for your mare.
          Anticipate mating and optimize the time between your cycle
          <br />
          "Managing time and anticipating actions will lead to a gain in the
          cost of producing animals in the equine industry."
          <br />
          <br />
          Leonardo Lopes - CEO &amp; Founder
          <br />
          <br />
          "The goal of the APP is to make it easier for those who are already in
          a hurry in a period as intense as the breeding season".
          <br />
          <br />
        </p>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;

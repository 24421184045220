import { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import api from "../../../services/api";
import { toast } from "react-toastify";

export default function ModalDataUser(props) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState(null);
  const [phone, setPhone] = useState("");
  const [telefoneEwhatsapp, setTelefoneEwhatsapp] = useState(false);

  useEffect(() => {
    (async () => {
      const {
        data: [data],
      } = await api.get("/usuario");
      setName(data.nome);
      setEmail(data.email);
      setDate(data.data_nasc);
      setPhone(data.telefone);
    })();
  }, [props]);

  const submitForm = async (e) => {
    e.preventDefault();

    await api.put("/usuario", {
      nome: name,
      email: email,
      data_nasc: date,
      telefone1: phone,
      telefoneEwhatsapp: 0,
    });

    toast.success("data successfully modified!");
    props.onHide();
  };
  return (
    <Modal {...props}>
      <Modal.Body>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-danger"
            onClick={() => props.onHide()}
          >
            <FaTimes color="#990000" />
          </button>
        </div>
        <h3>Update data</h3>
        <form className="d-flex flex-column" onSubmit={submitForm}>
          <label className="d-flex flex-column">
            Name
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
            />
          </label>
          <label className="d-flex flex-column">
            Email
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              type="email"
            />
          </label>
          <label className="d-flex flex-column">
            Date of birth
            <input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="form-control"
              type="date"
            />
          </label>
          <label className="d-flex flex-column">
            Phone
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control"
              type="tel"
            />
          </label>

          <button type="submit" className="btn btn-info align-self-end mt-4">
            {loading && <Spinner animation="border" />}
            {!loading && "To save"}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

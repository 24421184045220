import "./styles.css";
import PendingPay from "../../../assets/pendente.svg";
export default function PendingPayment() {
  return (
    <div className="screen">
      <img src={PendingPay} />
      <br />

      <h4>YOUR ORDER IS PENDING APPROVAL</h4>
      <br />
      <br />
      <h5>YOUR ORDER IS IN THE APPROVAL PROCESS.</h5>
    </div>
  );
}

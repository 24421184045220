import { useEffect, useRef } from "react";
import { useField } from "@unform/core";
export default function Input({ name, type, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    switch (type) {
      case "file":
        registerField({
          name: fieldName,
          ref: inputRef.current,
          path: "files[0]",
        });
        break;

      default:
        registerField({
          name: fieldName,
          ref: inputRef.current,
          path: "value",
        });
        break;
    }
  }, [fieldName, registerField]);

  return <input ref={inputRef} {...rest} type={type} />;
}

import "./styles.css";

import TelImage from "../../assets/telefone.svg";
import MailImage from "../../assets/mail.svg";
import LocationImage from "../../assets/location.svg";
import SendImage from "../../assets/send.svg";
import LogoImage from "../../assets/logoWhite.svg";
import FacebookImage from "../../assets/facebook.svg";
import InstagranImage from "../../assets/instagran.svg";
import YoutubeImage from "../../assets/youtube.svg";
import CardsImage from "../../assets/cards-brands.png";
import AppStoreImage from "../../assets/AppStore.png";
import GooglePlayImage from "../../assets/GooglePlay.png";
import { useState } from "react";
import api from "../../services/api";
import { toast, ToastContainer } from "react-toastify";

export default function Footer() {
  const [user, setUser] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    await api.post("/newsletter", { email: user });
    setUser("");
    toast.success("very happy for signing up for our newsletter!");
  };
  return (
    <>
      <ToastContainer />
      <footer className="footer d-flex flex-column flex-lg-row justify-content-lg-between align-items-start">
        <img src={LogoImage} />
        <ul>
          <li>
            <a href="tel:+19366626920">
              <img src={TelImage} />
              +1 936 662 6920
            </a>
          </li>
          <li>
            <img src={MailImage} />
            <a href="malito:contact@fastsemen.com">contact@fastsemen.com</a>
          </li>
          <li>
            <img src={LocationImage} />
            <p>
              330 Lookout Point <br /> 76401
              <br />
              Stephenville, Texas
            </p>
          </li>
          <li className="ml-5 d-flex flex-column">
            <h6>Connect with us</h6>
            <div className="d-flex ">
              <a className="m-0" href="https://www.facebook.com/Fast-Semen-108747850863816/" target="blank">
                <img src={FacebookImage} />
              </a>
              <a className="m-0" href="https://www.instagram.com/fastsemenusa/" target="blank">
                <img src={InstagranImage} />
              </a>
              <a className="m-0" href="https://www.facebook.com/Fast-Semen-108747850863816/" target="blank">
                <img src={YoutubeImage} />
              </a>
            </div>
          </li>
        </ul>

        <ul className="d-flex flex-column align-items-start align-self-start">
          <li>Stallion Station Admin</li>
          <li><a href="/about-us">About us</a></li>
          <li><a href="/terms">Terms of Use</a></li>
          <li><a href="/privacy-policy">Privacy policy</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>

        <div>
          <h5>Download our app now!</h5>
          <div className="d-flex align-items-center align-items-sm-start flex-column flex-sm-row">
            <a>
              <img style={{ width: 150 }} src={GooglePlayImage} href="https://apps.apple.com/in/app/fastsemen/id1602576046" target="_blank"/>
            </a>
            <a className="ml-0 ml-sm-2 mt-3 mt-sm-0" href="https://apps.apple.com/in/app/fastsemen/id1602576046" target="_blank">
              <img style={{ width: 150 }} src={AppStoreImage} />
            </a>
          </div>
          <br />
          <div>
            <h4>Newsletter</h4>
            <p>Enter your e-mail and subscribe now!</p>
            <form onSubmit={handleSubmit}>
              <input
                value={user}
                onChange={(e) => setUser(e.target.value)}
                placeholder="Enter your email"
                type="text"
              />
              <button type="submit">
                <img src={SendImage} />
              </button>
            </form>
          </div>
        </div>
      </footer>
      <div className="subfooter d-flex flex-column flex-sm-row">
        <p className="mb-sm-0">Copyright &copy; 2020 WebPeople</p>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { FunctionContext as Context } from "../../../../context";

export default function IntensMap({ item }) {
  const {
    setTotal,
    setSelectedTipoEnvio,
    selectedTipoEnvio,
    setSelectedAdicionais,
    selectedAdicionais,
  } = useContext(Context);

  return (
    <div key={item.key} className="itemMap mb-0">
      <label
        className="item d-flex flex-row pb-0 mb-0  align-items-center justify-content-between pl-2 pr-4"
        style={{ borderTop: "none" }}
      >
        <span className="w-75">
          <span className="d-flex">
            <h4 className="text-truncate w-100">{item.name}</h4>
            {item.type === "envio" && item.nativeItem?.standard === 1 && (
              <Badge variant="info" className="align-self-start ml-3">
                Standard
              </Badge>
            )}
          </span>
          <h6>+${item.value}</h6>
        </span>
        <input
          onChange={() => {
            if (item.type === "envio") {
              setSelectedTipoEnvio(item.nativeItem);
            } else {
              setSelectedAdicionais(item.nativeItem);
            }
          }}
          type="radio"
          name={item.type}
          style={{ width: 20, height: 20 }}
          defaultChecked={
            item.type === "envio"
              ? selectedTipoEnvio.idtipos_envio === item.key
                ? true
                : false
              : item.type === "adicional" && selectedAdicionais === item.key
              ? true
              : false
          }
        />
      </label>
    </div>
  );
}

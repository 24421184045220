import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import api from "../../services/api";
import "./styles.css";
import Recaptcha from "react-recaptcha";
import { Modal, Button, Form, Select } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
export default function SignIn() {
  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [isWhatsapp, setIsWhatsapp] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("");
  const [userType, setUserType] = useState(0);
  console.log("birthday", birthday);
  const handleClose = () => setShow(false);
  const handleSave = () => {
    verifyEmailCode();
  };
  const handleShow = () => setShow(true);
  const recaptchaLoaded = () => {
    console.log("loaded");
  };
  const verifyCallback = (response) => {
    if (response) {
      setIsVerify(true);
    }
  };
  useEffect(() => {
    if (acceptTerms && isVerify) {
      buttonSubmit.current.disabled = false;
    } else {
      buttonSubmit.current.disabled = true;
    }
  }, [acceptTerms, isVerify]);

  const buttonSubmit = useRef(null);

  const convertToFormattedDate = (dateString) => {
    const selectedDate = new Date(dateString);
    if (!isNaN(selectedDate.getTime())) {
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
      const day = String(selectedDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    } else {
      return reverseString(dateString);
    }
  };
  function reverseString(str) {
    return str.split("-").reverse().join("-");
  }
  const saveuser = async (e) => {
    const bday = convertToFormattedDate(birthday);

    try {
      await api.post("/usuario", {
        nome: name,
        email,
        data_nasc: bday,
        senha: password,
        telefone1: phone,
        telefoneEwhatsapp: 0,
        userType: userType,
      });
      handleClose();
      toast.info("User created successfully");
      setTimeout(() => {
        window.location.href = "/login";
      }, 5000);
    } catch (err) {
      console.log("err", err);
      if (err.response.status === 409) {
        toast.info(err.response.data.message);
      } else {
        toast.error(err.response.data.message);
      }
    }
  };
  const verifyEmailCode = async (e) => {
    try {
      const { data } = await api.post("/verifyEmailCode", {
        email,
        code,
      });

      saveuser();
    } catch (err) {
      if (err.response.status === 409) {
        toast.info(err.response.data.message);
      } else {
        toast.error(err.response.data.message);
      }
    }
  };
  const submitForm = async (e) => {
    e.preventDefault();

    try {
      if (password.length < 6) {
        return toast.error("Please enter a password of at least 6 characters");
      }
      if (userType == 0) {
        return toast.info("Please select the Veterinarian or Mare Owner");
      }

      const { data } = await api.post("/sendEmailCode", {
        email,
      });

      toast.info("We sent a verification code");
      setShow(true);
    } catch (err) {
      console.log("err", err);
      if (err.response.status === 409) {
        toast.info(err.response.data.message);
      } else {
        toast.error(err.response.data.message);
      }
    }
  };
  return (
    <>
      <Header />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Verify Email</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>You're almost done!</div>

          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> We sent a launch code to {email}</Form.Label>
              <Form.Control
                type="email"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                placeholder="Enter Code"
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container sigin">
        <h2 className="mb-4 mt-5">Create your account</h2>
        <form
          className="d-flex flex-column p-0 p-sm-5 col-12 form"
          onSubmit={submitForm}
        >
          <h4 className="ml-3">Enter your personal details!</h4>
          <span className="d-flex flex-column flex-md-row justify-content-between">
            <label className="d-flex flex-column col-12 col-md-6">
              Name
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control text-dark"
              />
            </label>
            <label className="d-flex flex-column col-12 col-md-6">
              Birthday
              <input
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                className="form-control text-dark"
                type="date"
              />
            </label>
          </span>
          <span className="d-flex flex-column flex-md-row justify-content-between">
            <label className="d-flex flex-column col-12 col-md-6">
              E-mail
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                type="email"
              />
            </label>
            <label className="d-flex flex-column col-12 col-md-6">
              Phone
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="form-control"
                type="tel"
              />
            </label>
          </span>
          <span className="d-flex flex-column flex-md-row justify-content-between">
            <label className="d-flex flex-column col-12 col-md-6">
              Password
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control text-dark"
                type="password"
              />
            </label>

            <label
              className="d-flex flex-row col-12 col-md-6 col-mt-10"
              style={{ marginTop: 30 }}
            >
              <p className="d-flex p-0 ml-0 mt-10">
                <input
                  type="checkbox"
                  className="mr-2 d-flex "
                  style={{ width: 20, height: 20, color: "#aaa" }}
                  checked={userType == 1}
                  onChange={() => setUserType(1)}
                />
                Veterinarian
              </p>
              <p className="d-flex p-10 ml-10" style={{ marginLeft: 10 }}>
                <input
                  type="checkbox"
                  className="mr-2 d-flex "
                  style={{ width: 20, height: 20, color: "#aaa" }}
                  checked={userType == 2}
                  onChange={() => setUserType(2)}
                />
                Mare Owner
              </p>
            </label>
          </span>
          <span className="d-flex flex-column flex-md-row justify-content-between">
            <label className="px-3 d-flex flex-column flex-sm-row">
              <p className="d-flex p-0 m-0">
                <input
                  type="checkbox"
                  className="mr-2 d-flex "
                  style={{ width: 20, height: 20, color: "#aaa" }}
                  checked={acceptTerms}
                  onChange={() => setAcceptTerms(!acceptTerms)}
                />
                I accept the Terms of use and &nbsp;
              </p>
              <a href="/terms" target="blank">
                Privacy Policies
              </a>
              .
            </label>

            <div className="mb-5">
              <Recaptcha
                sitekey="6LfIHaMeAAAAAEw1x81kYPEIb2DFCPnqrDDXjrZ6"
                render="explicit"
                size="normal"
                onloadCallback={recaptchaLoaded}
                verifyCallback={verifyCallback}
              />
            </div>
          </span>

          <button
            ref={buttonSubmit}
            type="submit"
            className="btn btn-primary align-self-end px-4"
            disabled
          >
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
}

import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import Axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import api from "../../services/api";
import { uuid } from "uuidv4";

import { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import ContactCheckoutForm from "../Payment/ContactCheckoutForm.js";
import { Container } from "../Payment/styles";

import { useLocation, useParams } from "react-router";
import { FunctionContext } from "../../context";

// import Header from "../../Components/Header/index.js";
// import Footer from "../../Components/Footer/index.js";
import { apiUrl } from "../../config/settings.json";
// import { useLocation } from "react-router";
// import { FunctionContext } from "../../context";

const stripePromise = loadStripe(
  "pk_test_51J0Q7rGyZdtWCCRHX59VbqanLWjxQFCX78FLHoTQ4Z2n5SEWsMzRCIxlnw4EfrloiT4A2XH5FLYveNVAZO2qqI3f00zkJlJpuV"
);

export default function PaymentContact() {
  const context = useContext(FunctionContext);
  const [clientSecret, setClientSecret] = useState("");
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    (async () => {
      const { data: item } = await api.get(
        `/api/pagamentos/contract-payment/${params.id}`
      );
      console.log("item contract", item);
      const data = {
        idgaranhao: item.idgaranhao,
        idegua: item.idegua,
        tipo_semem: item.tipos_semen,
        tipos_envio_idtipos_envio: item.idtipos_envio,
        idendereco: item.enderecos_comprador_idenderecos,
        chutefee: item.enderecos_comprador_idenderecos,
        dia: item.dia,
        mes: item.mes,
        adicionais_idadicionais: item.adicionais_idadicionais,
        quantity: item.quantity,
      };

      const total = item.valor;
      console.log("total", total);
      await localStorage.setItem("@TOKEN", params.token);
      // const cartItems = await api.get("/carrinho");
      // const total = cartItems.data.reduce((acc, cav) => {
      //   const preco =
      //     (cav.isChutefee === 1 ? 0 : cav.preco) +
      //     (cav.precoAdicional || 0) +
      //     (cav.tipoSelected.length >= 1
      //       ? cav.free_shipping === 0
      //         ? cav.tipoSelected[0].valor
      //         : 0
      //       : 0);

      //   return +preco + +acc;
      // }, 0);
      // alert(total);
      // Create PaymentIntent as soon as the page loads
      await localStorage.setItem(
        "@PAYMENT_DATA",
        JSON.stringify({
          amount: {
            value: total * 100,
          },
          cavalo: data,
        })
      );
      // console.log({
      //   amount: {
      //     value: location.state.value * 100,
      //   },
      //   cart: !!location.state.cart ? true : false,
      //   cavalo: location.state.data,
      // });

      fetch(`${apiUrl}create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: total * 100 }),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));

      // console.log({
      //   amount: {
      //     currency: "USD",
      //     value: +location.state.value,
      //   },
      //   cart: !!location.state.cart ? true : false,
      //   cavalo: location.state.data,
      // });
    })();
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Header />
      <Container>
        <div className="container my-5">
          <h2>Payment Method</h2>
          <div
            className="mt-5 p-4"
            style={{
              backgroundColor: "#fff",
              borderRadius: 15,
              boxShadow: " 0px 2px 6px #00000029",
            }}
          >
            <h3>Insert your card details!</h3>
            <div className="App">
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <ContactCheckoutForm />
                </Elements>
              )}
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FunctionContext as Context } from "../../context";
import api from "../../services/api";

export default function AsideFilter() {
  const context = useContext(Context);
  const history = useHistory();

  const [vendedoresApi, setVendedorApi] = useState([]);
  const [vendedorSelecionalo, setVendedorSelecionalo] = useState("padrao");
  const [racaVendedorApi, setRacaVendedorApi] = useState([]);
  const [garanhaoSelecionalo, setGaranhaoSelecionalo] = useState("padrao");
  const [cavaloVendedor, setCavaloVendedor] = useState("");
  useEffect(() => {
    setCavaloVendedor(context.configFilter.name);
    setVendedorSelecionalo(context.configFilter.saller);
    setGaranhaoSelecionalo(context.configFilter.idgaranhao);
    api.get("/vendedor").then((res) => {
      return setVendedorApi(res.data.vendedores);
    });
    if (context.configFilter.saller) {
      setaRaca(context.configFilter.saller);
    }
  }, []);

  const setaRaca = async (idvendedor) => {
    const { data } = await api.get(`/garanhao/${idvendedor}`);
    setRacaVendedorApi(data);
  };
  return (
    <div className="bg-dark p-4 position-sticky">
      <h6 className="text-light text-center">
        Please select the station and stallion
      </h6>
      <label className="text-light w-100 mb-3">
        Station
        <select
          value={vendedorSelecionalo}
          onChange={(e) => {
            setaRaca(e.target.value);
            setVendedorSelecionalo(e.target.value);
          }}
          className="w-100 form-control"
        >
          <option value="padrao">Select station...</option>
          {vendedoresApi.map((vendedor) => {
            return (
              <option key={vendedor.vendedor} value={vendedor.vendedor}>
                {vendedor.nome}
              </option>
            );
          })}
        </select>
      </label>
      <label className="text-light w-100 mb-3">
        Stallion
        <select
          value={garanhaoSelecionalo}
          onChange={(e) => setGaranhaoSelecionalo(e.target.value)}
          className="w-100 form-control"
        >
          <option value="padrao">Select stallion...</option>
          {!racaVendedorApi.length && <option disabled>Select a seller</option>}
          {racaVendedorApi.map((garanhao) => {
            return (
              <option key={garanhao.idgaranhao} value={garanhao.idgaranhao}>
                {garanhao.nome}
              </option>
            );
          })}
        </select>
      </label>
      <h5 className="text-light text-center">--------- OR ---------</h5>
      <div className="w-100 col-12 mx-auto d-flex flex-column input p-0">
        <input
          value={cavaloVendedor}
          onChange={async (e) => {
            setCavaloVendedor(e.target.value);
          }}
          placeholder="Input stallion name..."
          className="form-control"
        />
        <button
          className="w-100 mt-2 btn text-light"
          style={{ backgroundColor: "#04C2AF" }}
          type="button"
          onClick={() => {
            context.setConfigFilter({
              name: cavaloVendedor || null,
              saller: vendedorSelecionalo || null,
              idgaranhao: garanhaoSelecionalo || null,
            });
          }}
        >
          Find
        </button>
      </div>
    </div>
  );
}

import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

export default function TermsServicePartners() {
  return (
    <>
      <Header />
      <div className="container mt-5">
        <h2 className="mb-5 text-center">FAST SEMEN TERMS OF SERVICE</h2>
        <h3 className="mb-5 text-center">For Providers</h3>
        <p style={{ fontSize: 22, fontWeight: 500 }}>
          This terms of service document (the “Terms of Service”) describes the
          terms under which Fast Semen LLC offers Providers access to the Fast
          Semen Platform. By entering into a Marketplace Agreement with Fast
          Semen, Providers agree to all terms in this Terms of Service.
          <br />
          <br />
          These Terms of Service are effective as of February 3, 2021.
          <br />
          <br />
          1. Definitions. When used in this Terms of Service and capitalized,
          <br />
          <div className="ml-5 pl-1">
            A. “Agreement” means this Terms of Service in conjunction with the
            Marketplace Agreement;
            <br />
            <br />
            B. “Customer” means the customer who searches for or places an order
            for Products or communicates with a Provider to purchase or consider
            purchasing a Product via the Platform;
            <br />
            <br />
            C. “Fast Semen” means Fast Semen LLC, a Texas limited liability
            company, and its managers, members, officers, investors, employees,
            agents, and other representatives;
            <br />
            <br />
            D. “Listing” means Provider’s name; logo; regular business hours;
            list, description, and prices of Products; shipping fees charged by
            Provider to ship Products to Customers; and any other terms of sale
            Provider desires to publish for Customers on the Marketplace;
            <br />
            <br />
            E. “Marketplace" means Fast Semen’s proprietary, online
            communication platform that connects Providers and Customers,
            allowing Customers to purchase products from or through Providers;
            <br />
            <br />
            F. “Marketplace Agreement” means the contract between Provider and
            Fast Semen to allow Provider to use the Platform, including all
            commissions and fees charged by Fast Semen;
            <br />
            <br />
            G. “Orders” means orders for Products through the Platform from
            Customers;
            <br />
            <br />
            H. “Party” or “Parties” means Fast Semen, Provider, or both as
            context requires to refer to the parties to these Terms of Service;
            <br />
            <br />
            I. “Personal Information” means any information exchanged under this
            Agreement that (i) identifies or can be used to identify an
            individual (including without limitation, names, telephone numbers,
            addresses, signatures, email addresses, or other unique
            identifiers); or (ii) that can reasonably be used to authenticate an
            individual (including without limitation, name, contact information,
            precise location information, access credentials, persistent
            identifiers, and any information that may be considered ‘personal
            data’ or ‘personal information’ under applicable law);
            <br />
            <br />
            J. “Platform” means the application, website, and services offered
            by Fast Semen, including the Marketplace and Provider Portal,
            enabling Providers and Customers to connect and transact business;
            <br />
            <br />
            K. “Products” includes the semen and other goods offered for sale by
            Provider through the Platform;
            <br />
            <br />
            L. “Provider” means the person contracting with Fast Semen to use
            the Platform, the entities the person represents, its agents and
            representatives, and the equine semen collection facilities under
            its control that will use the Platform;
            <br />
            <br />
            M. "Provider Portal" means the online website, accessible at
            www.fastsemen.com or the versios avaible at PlayStore and AppleStore
            through which Provider may and regularly should review and confirm
            its transactions, fees and charges, account, and Product listings on
            the Platform;
            <br />
            <br />
            N. “Section” when followed by numbers or a combination of numbers
            and letters means that section in this Terms of Service.
            <br />
            <br />
            O. “Shipping” means depositing a package and all necessary fees to a
            person, courier service, or the US Postal Service to be transported
            and delivered to the addressee on the package; and
            <br />
            <br />
            P. “Term” means the term of the agreement between Fast Semen and
            Provider that is stated in the Listing Agreement.
          </div>
          <br />
          <br />
          2. The Parties' Relationship; Exclusivity.
          <br />
          <div className="ml-5 pl-1">
            A. Fast Semen provides the Marketplace using proprietary, web-based
            technology that connects Providers and Customers. Fast Semen
            provides the Platform to display Provider’s Listing on the
            Marketplace. Fast Semen is not a merchant, seller of goods, or
            delivery service; it is an online connection platform. Provider and
            Fast Semen agree they are independent businesses whose relationship
            is governed by the Marketplace Agreement, this Terms of Service, and
            any applicable terms between the Parties. Nothing in the Parties'
            agreements, relationship, or transactions will create or be
            construed as creating an agency, partnership, fiduciary
            relationship, or joint venture relationship between Fast Semen and
            Provider (or Provider’s employees, representatives, or clients) or
            between Fast Semen and Customers. Except as expressly set forth in
            the Marketplace Agreement, this Terms of Service, and any applicable
            terms between the Parties, each Party will be responsible for its
            own expenses, profits, and losses.
            <br />
            <br />
            B. Provider agrees to an exclusive relationship with Fast Semen.
            Provider will not use any similar or competing service to the
            service provided by Fast Semen. Provider agrees that all
            transactions between Provider and any willing purchaser of semen
            from or through Provider will be transacted on the Platform.
            Provider will not make any direct sales of semen outside of the
            Platform.
            <br />
          </div>
          3. Marketplace Responsibilities. For Providers that have executed a
          Marketplace Agreement with Fast Semen, Fast Semen and Provider will
          have the following responsibilities during the Term:
          <br />
          <br />
          <div className="ml-5 pl-1">
            A. Fast Semen Responsibilities. Fast Semen will, in a timely manner:
          </div>
          <br />
          <br />
          <div className="ml-5 pl-1">
            (i) Display Provider’s Listing on the Marketplace;
            <br />
            <br />
            (ii) Accept Orders from Customers, and display the status of Orders
            on the Platform;
            <br />
            <br />
            (iii) Forward each Order to the relevant Provider via email so that
            the Provider can collect and deliver the Product to the Customer;
            and
            <br />
            <br />
            (iv) Pay the Provider the amount paid by the Customer, less the
            commission rate and fees due to Fast Semen, as detailed in the
            Marketplace Agreement.
            <br />
            <br />
          </div>
          B. Provider Responsibilities. Provider will, in a timely manner:
          <br />
          <br />
          <div className="ml-5 pl-1">
            (i) Exclusively use and only accept orders for or actions to
            purchase semen through the Platform;
            <br />
            <br />
            (ii) Enter and upload the Listing and any additional information to
            the Provider Portal to be displayed on the Marketplace;
            <br />
            <br />
            (iii) Monitor Provider’s Listing, promptly make updates via the
            Provider Portal to reflect the most up-to-date Products, pricing,
            and other information;
            <br />
            <br />
            (iv) Accept all Orders from the Platform;
            <br />
            <br />
            (v) Confirm Orders to Fast Semen and Customer;
            <br />
            <br />
            (vi) Collect and deliver ordered Products to Customers within the
            time-frame stated by Provider in the Listing;
            <br />
            <br />
            (vii) Fulfill Orders, including collecting the correct Product;
            ensuring appropriate packaging of the Product; shipping the Product
            by appropriate method to the address provided by Customer, or making
            the Product available for Customer to pick up; and following any
            special instructions included in the Order;
            <br />
            <br />
            (viii) On an ongoing basis, review and confirm the transaction,
            commissions and fees on Orders via the Provider Portal, and promptly
            communicate to Fast Semen any inaccuracies; and
            <br />
            <br />
            (ix) Have appropriate devices or equipment to receive Orders, send
            confirmations, and communicate with Fast Semen and Customers.
            <br />
            <br />
          </div>
          4. Refunds and Re-Orders. In the event that Fast Semen, in its sole,
          reasonable discretion, finds it necessary to issue a refund or credit
          on an Order, Provider will bear the full cost of that refund or credit
          as applicable, unless the refund or credit is due to the fault of the
          Platform.
          <br />
          <br />
          5. Payment, Fees, Taxes, and Title. Payment, fees, and taxes will be
          addressed as follows:
          <br />
          <br />
          <div className="ml-5 pl-1">
            A. Payments for Orders. Payments for Orders fulfilled by Provider
            will be deposited directly to the Provider’s account by the payment
            processing service Adyen, subject to any holds imposed by Adyen.
            <br />
            <br />
            B. Collection of Fees. Fast Semen will calculate the commission and
            fees due for each Order as detailed in the Marketplace Agreement,
            and Fast Semen will send via email an invoice to Providers on a
            monthly basis to collect all sums due to Fast Semen. Provider will
            pay Fast Semen the total amount due stated on the invoice within 14
            days of receipt of the invoice. Provider agrees Fast Semen, in its
            sole discretion, may charge the Customer fees, including but not
            limited to a purchase price for the Platform.
            <br />
            <br />
            C. Taxes. Provider will be responsible for all taxes, duties, and
            other governmental charges on the sale of Products and for remitting
            such taxes, duties, and other governmental charges to the
            appropriate authorities.
            <br />
            <br />
            D. Duty to Review. Provider agrees, on an ongoing basis, to review
            and confirm transactions, commission, and fees on Orders and
            invoices via the Provider Portal. Provider agrees to communicate to
            Fast Semen in writing any claimed inaccuracies, allowing Fast Semen
            the opportunity to address and resolve any issues, which Fast Semen
            and Provider agree is in the best interests of both Parties and
            their commercial relationship. Provider agrees to communicate to
            Fast Semen any claimed inaccuracies or any issue with any
            transaction, commission, or fee within 60 days of the transaction,
            commission, or fee. Provider will be deemed to have waived any claim
            or objection regarding each transaction, commission, or fee if
            Provider does not communicate a written claim or objection to Fast
            Semen within such 60-day period.
            <br />
            <br />
            E. Title. Provider agrees that Provider holds title to the Products
            that Provider offers through the Platform until the Product is
            shipped from Provider, or picked up by Customer from Provider’s
            facility, and that title passes from the Provider to the Customer
            upon shipping to the Customer or Customer picking up the Product
            from Provider. Provider agrees that Fast Semen does not at any time
            hold title to or acquire any ownership interest in any Products.
          </div>
          6. Payment Processing. Payment processing services for Providers on
          the Platform are provided by Adyen and are subject to the Adyen Terms
          and Conditions and Adyen pricing. By agreeing to these Terms of
          Service, Provider agrees to be bound by the Ayden terms and
          conditions, as the same may be modified by Adyen from time to time. As
          a condition of Fast Semen enabling payment processing services through
          Adyen, Provider agrees to provide Fast Semen accurate and complete
          information about Provider’s representative and its business, and
          Provider authorizes Fast Semen to share it and transaction information
          related to Provider’s use of the payment processing services provided
          by Adyen.
          <br />
          <br />
          7. Provider Content and Trademark. During the Term, Provider grants to
          Fast Semen a royalty-free, non-exclusive, limited, revocable,
          non-transferable, non-sublicensable right and license to use and
          display Provider’s name, logo, photographs, trademarks, and other
          Listing materials provided to Fast Semen by Provider; to list Provider
          as a provider on the Platform; to reference Provider as a Fast Semen
          partner; and to promote Fast Semen’s Products and services.
          <br />
          <br />
          8. Confidential Information.
          <br />
          <br />
          <div className="ml-5 pl-1">
            A. In this Agreement, he term “Confidential Information” means any
            confidential or proprietary business, technical, or financial
            information or materials of a party (the “Disclosing Party”)
            provided to the other party (the “Receiving Party”) in connection
            with this Agreement, whether orally or in physical form.
            Confidential Information includes the Marketplace Agreement.
            <br />
            <br />
            B. Confidential Information does not include information that (i)
            was rightfully known to the Receiving Party without restriction on
            use or disclosure prior to such information's being disclosed to the
            Receiving Party in connection with this Agreement; (ii) was or
            becomes public domain other than by the fault of the Receiving
            Party; (iii) was or is received by the Receiving Party on a
            non-confidential basis from a third party that, to the Receiving
            Party's knowledge, was not at the time under any obligation to
            maintain its confidentiality; or (iv) the Receiving Party can
            demonstrate by documentary records was independently developed by
            the Receiving Party without access to, use of or reference to any
            Confidential Information.
            <br />
            <br />
            C. The Receiving Party agrees (i) to not access or use Confidential
            Information other than as necessary to exercise its rights or
            perform its obligations in accordance with this Agreement; (ii) to
            not disclose or permit access to Confidential Information other than
            to its employees, officers, directors, consultants, agents,
            independent contractors, service providers, subcontractors and legal
            advisors who need to know such Confidential Information for purposes
            of the Receiving Party's exercise of its rights or performance of
            its obligations under this Agreement, and prior to any such
            disclosure are bound by written confidentiality and restricted use
            obligations at least as protective of the Confidential Information
            as the terms set forth in this Section; and (iii) safeguard the
            Confidential Information from unauthorized use, access, or
            disclosure using at least the degree of care it uses to protect its
            own sensitive information and in no event less than a reasonable
            degree of care.
            <br />
            <br />
            D. If the Receiving Party is compelled by applicable law to disclose
            any Confidential Information then, to the extent permitted by
            applicable law, the Receiving Party will promptly notify the
            Disclosing Party in writing of such requirement so that the
            Disclosing Party can seek a protective order or other remedy or
            waive its rights and provide reasonable assistance to the Disclosing
            Party, at the Disclosing Party's sole expense, in opposing or
            seeking protective limitations on disclosure.
            <br />
            <br />
          </div>
          9. Data Privacy and Security.
          <br />
          <br />
          <div className="ml-5 pl-1">
            A. Personal Information. Provider agrees not to access, collect,
            store, retain, transfer, use, disclose, or otherwise process in any
            manner Personal Information, except as required to perform under
            this Terms of Service. Provider will keep Personal Information
            secure from unauthorized access. If Provider becomes aware of any
            unauthorized access to Personal Information, Provider will
            immediately notify Fast Semen, consult and cooperate with
            investigations and potentially required notices, and provide any
            information reasonably requested by Fast Semen. Provider agrees to
            implement and use security procedures, protocols or access
            credentials as reasonably requested by Fast Semen and will be
            responsible for damages resulting from Provider’s failure to comply.
            <br />
            <br />
            B. Third-Party Access. Provider will not allow any third party to
            use the Platform and will be responsible for damages resulting from
            sharing Provider’s login credentials with unauthorized third parties
            or otherwise permitting unauthorized access to Provider’s account.
            Providers may not allow any third party to copy, modify, rent,
            lease, sell, distribute, reverse engineer, or otherwise attempt to
            gain access to the source code of the Platform; damage, destroy or
            impede the services provided through the Platform; transmit
            injurious code; or bypass or breach any security protection on the
            Platform.
            <br />
          </div>
          10. Termination. Provider will cease to be bound by these Terms of
          Service on the date that the Marketplace Agreement terminates.
          <br />
          <br />
          11. Modifications. Fast Semen reserves the right, at its sole
          discretion, to change, suspend, or discontinue the Platform (including
          without limitation, the availability of any feature or content) at any
          time. Fast Semen may, at its sole discretion, remove Provider’s
          Listing from the Marketplace if Fast Semen determines that such
          Product or Listing could subject Fast Semen to undue regulatory risk
          or other liability. Fast Semen also may revise these Terms of Service
          from time to time. The changes will not be retroactive, and the most
          current version of the terms will be at 04-09-2021. Fast Semen will
          notify Providers of material revisions via email to the email address
          associated with Provider’s account. By continuing to access or use the
          Platform after those revisions become effective, Provider agrees to be
          bound by the revised Terms of Service.
          <br />
          <br />
          12. Representations and Warranties; Additional Responsibilities;
          Warranty Disclaimer.
          <br />
          <br />
          <div className="ml-5 pl-1">
            A. Each Party represents and warrants that it has the full right,
            power, and authority to enter into and perform its obligations under
            this Terms of Service without breaching any obligation to any third
            party.
            <br />
            <br />
            B. Each Party represents and warrants that it will comply with all
            applicable laws and regulations in its performance of this Terms of
            Service, including without limitation (i) all applicable data
            protection and privacy laws, and (ii) all applicable laws related to
            third-party intellectual property and other proprietary rights
            <br />
            <br />
            C. Provider further represents and warrants that (i) it will comply
            with all applicable laws, rules, standards and regulations relating
            to licenses, (ii) it will not disclose any information related to a
            Customer to a third party (except as required to comply with law or
            pursuant to a court order), (iii) it will comply with the guidelines
            Fast Semen publishes that govern any content Provider posts on the
            Platform or Provider Portal, and (iv) it will comply with its
            obligations under Section 3.B. of this Agreement.
            <br />
            <br />
            D. EXCEPT AS EXPRESSLY SET FORTH HEREIN, TO THE EXTENT PERMITTED BY
            APPLICABLE LAW, FAST SEMEN HEREBY EXPRESSLY DISCLAIMS ALL
            WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, REGARDING THE FAST SEMEN
            PLATFORM, EQUIPMENT, OR SERVICES, INCLUDING BUT NOT LIMITED TO ANY
            IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, SATISFACTORY QUALITY
            OR RESULTS, OR FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. Provider acknowledges that the operation of the
            Platform may from time to time encounter technical or other problems
            and may not necessarily continue uninterrupted or without technical
            or other errors. Fast Semen will not be responsible to Provider or
            others for any such interruptions, errors, or problems or an
            outright discontinuance of the Platform nor for any guarantee of
            results with respect to the Fast Semen services or Platform. Both
            Parties acknowledge that neither party has any expectation or has
            received any assurances for future business or that any investment
            by a Party will be recovered or recouped or that such Party will
            obtain any anticipated amount of profits by virtue of this Terms of
            Service.
            <br />
          </div>
          13. Indemnification.
          <br />
          <div className="ml-5 pl-1">
            A. Provider will defend, indemnify, and hold harmless Fast Semen
            from and against any and all claims, damages, losses and expenses
            (including reasonable attorney’s fees) (collectively “Losses”) with
            respect to any third-party claims arising out of or related to (i)
            the submission, receipt, and fulfillment of Orders; (ii) the
            violation of the intellectual property of the third party by
            Provider’s Listing; or (iii) Provider’s failure to comply with any
            applicable law, code, rule, or regulation.
            <br />
            <br />
            B. Fast Semen will defend, indemnify, and hold harmless Provider
            from and against any and all Losses with respect to Fast Semen’s
            failure to collect and pay taxes when required in accordance with
            Section 5.C. of these Terms of Service.
            <br />
            <br />
            C. In each case, the indemnified party must provide the indemnifying
            party with (i) prompt notice of any claims such that the
            indemnifying party is not prejudiced by any delay of such
            notification; (ii) the option to assume sole control over defense
            and settlement of any claim; and (iii) reasonable assistance in
            connection with such defense and settlement (at the indemnifying
            party’s expense). The indemnified party may participate in the
            defense or settlement of such a claim with counsel of its own choice
            and at its own expense; however, the indemnifying party may not
            enter into any settlement agreement that imposes any obligation on
            the indemnified party without the indemnified party’s express,
            prior, written consent.
            <br />
            <br />
            D. Fast Semen assumes no liability, and will have no liability, for
            any infringement claim pursuant to Section 12.D. above based on (i)
            Provider’s access to or use of the Platform following notice of such
            an infringement claim; (ii) any unauthorized modification of the
            Platform by Provider; or (iii) Provider’s combination of the
            Platform with third-party programs, services, data, hardware, or
            other materials which otherwise would not result in such
            infringement claim.
            <br />
            <br />
          </div>
          <br />
          14. Limitation of Liability. EXCEPT WITH RESPECT TO DAMAGES ARISING
          FROM VIOLATIONS OF LAW OR WILLFUL MISCONDUCT, AND AMOUNTS PAYABLE TO
          THIRD PARTIES UNDER SECTION 13 (INDEMNIFICATION), TO THE EXTENT
          PERMITTED BY APPLICABLE LAW, NEITHER PARTY WILL BE LIABLE TO THE OTHER
          UNDER THIS AGREEMENT, FOR INDIRECT, SPECIAL, PUNITIVE, OR
          CONSEQUENTIAL DAMAGES, OR FOR LOST PROFITS, LOST REVENUES, HARM TO
          GOODWILL, OR THE COSTS FOR PROCURING REPLACEMENT SERVICES, WHETHER
          BASED ON TORT, CONTRACT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
          SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
          <br />
          <br />
          15. Insurance. During the term of the Agreement and for one year
          after, each Party will maintain adequate insurance in amounts not less
          than as required by law or that is common practice in such Party’s
          business. In no event will the limits of any insurance policy be
          considered as limiting the liability of a Party under this Agreement.
          <br />
          <br />
          16. Dispute Resolution. PLEASE READ THE FOLLOWING SECTION CAREFULLY.
          IT REQUIRES PROVIDER TO ARBITRATE DISPUTES WITH FAST SEMEN AND LIMITS
          THE MANNER IN WHICH PROVIDER CAN SEEK RELIEF. THIS SECTION 16 OF THIS
          AGREEMENT WILL BE REFERRED TO AS THE “ARBITRATION AGREEMENT”.
          <br />
          <br />
          <div className="ml-5 pl-1">
            A. Scope of Arbitration Agreement. Any dispute, controversy or claim
            arising out of, relating to, or in connection with this contract,
            including the breach, termination, or validity thereof, will be
            finally resolved by binding arbitration, rather than in court,
            except that (i) the Parties may assert claims in small claims court
            if the Parties’ claims qualify, so long as the matter remains in
            such court and advances only on an individual (non-class,
            non-representative) basis; and (ii) the Parties may seek injunctive
            relief in court for infringement or other misuse of intellectual
            property rights (such as trademarks, trade dress, domain names,
            trade secrets, copyrights, and patents). The Parties agree that,
            because both are businesses that mutually benefit from streamlined
            and confidential resolution, this Arbitration Agreement will apply
            to all disputes arising from or relating to the subject matter of
            this Agreement or the relationship between the Parties and their
            personnel. In that regard, this Arbitration Agreement will be
            binding upon and enforceable by not only the Parties, but also their
            affiliates, and their owners, officers, directors, managers,
            employees, and other representatives. This Arbitration Agreement
            will apply, without limitation, to all claims that arose or were
            asserted before the Effective Date of this Agreement.
            <br />
            <br />
            B. Informal Resolution. The Parties agree that good-faith, informal
            efforts to resolve disputes often can result in a prompt, low-cost,
            and mutually beneficial outcome. The Parties, therefore, agree that,
            before either Party demands arbitration against the other, the
            Parties will personally meet and confer, via telephone or
            videoconference, in a good-faith effort to resolve informally any
            claim covered by this mutual Arbitration Agreement. If a Party is
            represented by counsel, the Party’s counsel may participate in the
            conference, but the Party must also fully participate in the
            conference. The Party initiating the claim must give notice to the
            other Party in writing of its intent to initiate an informal dispute
            resolution conference. Such conference must occur within 60 days
            after the other Party receives such notice, unless an extension is
            mutually agreed upon by the Parties. To notify Fast Semen that
            Provider intends to initiate an informal dispute resolution
            conference, email contact@fastsemen.com , providing your name,
            telephone number associated with your Fast Semen account (if any),
            the email address associated with your Fast Semen account, and a
            description of your claim. In the interval between the Party
            receiving such notice and the informal dispute resolution
            conference, the Parties will be free to attempt to resolve the
            initiating Party’s claims. Engaging in an informal dispute
            resolution conference is a requirement that must be fulfilled before
            commencing arbitration. The statute of limitations and any filing
            fee deadlines will be tolled while the Parties engage in the
            informal dispute resolution process required by this paragraph.
            <br />
            <br />
            C. Arbitration Rules and Forum. This Arbitration Agreement is
            governed by the Federal Arbitration Act in all respects. If, for
            whatever reason, the rules and procedures of the Federal Arbitration
            Act cannot apply, the state law governing arbitration agreements in
            the State of Texas shall apply. Before a Party may begin an
            arbitration proceeding, that Party must send notice of an intent to
            initiate arbitration and certifying completion of the informal
            dispute resolution conference pursuant to section 16.B. If this
            notice is being sent to Fast Semen, it must be sent by email to the
            counsel who represented Fast Semen in the informal dispute
            resolution process, or if there was no such counsel then by email to
            contact@fastsemen.com . The arbitration will be conducted by JAMS
            under its rules and pursuant to the terms of this Agreement, but in
            the event of a conflict between the two, the provisions of this
            Arbitration Agreement shall supersede any and all conflicting
            arbitration administrator’s rules or procedures. Arbitration demands
            filed with JAMS must include (i) the name, telephone number, mailing
            address, and e-mail address of the Party seeking arbitration; (ii) a
            statement of the legal claims being asserted and the factual bases
            of those claims; (iii) a description of the remedy sought and an
            accurate, good-faith calculation of the amount in controversy,
            enumerated in United States Dollars (any request for injunctive
            relief or attorneys’ fees shall not count toward the calculation of
            the amount in controversy unless such injunctive relief seeks the
            payment of money); and (iv) the signature of the Party seeking
            arbitration. Disputes involving claims and counterclaims under
            $250,000, not inclusive of attorneys’ fees and interest, shall be
            subject to JAMS’s most current version of the Streamlined
            Arbitration Rules and procedures available at
            http://www.jamsadr.com/rules-streamlined-arbitration/; all other
            claims shall be subject to JAMS’s most current version of the
            Comprehensive Arbitration Rules and Procedures, available at
            http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS’s
            rules are also available at www.jamsadr.com (under the Rules/Clauses
            tab) or by calling JAMS at 800-352-5267. Payment of all filing,
            administration, and arbitration fees will be governed by JAMS’s
            rules. If JAMS is not available to arbitrate, the Parties will
            select an alternative arbitral forum. The Parties may choose to have
            the arbitration conducted by telephone, video conference, or in
            person in the State of Texas or at another mutually agreed location.
            <br />
            <br />
            D. Arbitrator Powers. The arbitrator, and not any federal, state, or
            local court or agency, shall have exclusive authority to resolve any
            dispute relating to the interpretation, applicability,
            enforceability, or formation of this Arbitration Agreement
            including, but not limited to any claim that all or any part of this
            Arbitration Agreement is void or voidable. All disputes regarding
            the payment of arbitrator or arbitration-organization fees including
            the timing of such payments and remedies for nonpayment, shall be
            determined exclusively by an arbitrator, and not by any court. The
            arbitration will decide the rights and liabilities, if any, of you
            and Fast Semen. The arbitration proceeding will not be consolidated
            with any other matters or joined with any other proceedings or
            parties. The arbitrator will have the authority to grant motions
            dispositive of all or part of any claim or dispute. The arbitrator
            will have the authority to award monetary damages and to grant any
            non-monetary remedy or relief available to an individual under
            applicable law, the arbitral forum’s rules, and this Agreement
            (including this Arbitration Agreement). The arbitrator will issue a
            written statement of decision describing the essential findings and
            conclusions on which any award (or decision not to render an award)
            is based, including the calculation of any damages awarded. The
            arbitrator shall follow the applicable law. The arbitrator has the
            same authority to award relief on an individual basis that a judge
            in a court of law would have. The arbitrator’s decision is final and
            binding on you and Fast Semen.
            <br />
            <br />
            E. Waiver of Jury Trial. THE PARTIES WAIVE ANY CONSTITUTIONAL AND
            STATUTORY RIGHTS TO SUE IN COURT AND RECEIVE A JUDGE OR JURY TRIAL.
            The Parties are instead electing to have claims and disputes
            resolved by arbitration if no informal resolution can be had. There
            is no judge or jury in arbitration, and court review of an
            arbitration award is limited.
            <br />
            <br />
            F. Waiver of Class or Consolidated Actions;. THE PARTIES AGREE TO
            WAIVE ANY RIGHT TO RESOLVE CLAIMS WITHIN THE SCOPE OF THIS
            ARBITRATION AGREEMENT ON A CLASS, COLLECTIVE, OR REPRESENTATIVE
            BASIS. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
            AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A
            CLASS BASIS. CLAIMS OF MORE THAN ONE PROVIDER CANNOT BE ARBITRATED
            OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER
            PROVIDER OR CUSTOMER. If, however, this waiver of class or
            consolidated actions is deemed invalid or unenforceable with respect
            to a particular claim or dispute, neither Party is entitled to
            arbitration of such claim or dispute. Instead, all such claims and
            disputes will then be resolved in a court, and all other provisions
            of this Section 16 (Dispute Resolution) will remain in force.
            <br />
            <br />
            G. Severability. If any provision of this Section 16 is adjudged to
            be void or otherwise unenforceable, in whole or in part, the void or
            unenforceable provision will be severed and such adjudication will
            not affect the validity of the remainder of this Section 16. Nothing
            in this provision will prevent the Parties from participating in a
            class-wide settlement of claims.
            <br />
            H. Survival. This Arbitration Agreement will survive any termination
            of the relationship between the Parties.
            <br />
            <br />
            I. Modification. Notwithstanding any provision in the Agreement to
            the contrary, the Parties agree that if Fast Semen makes any future
            material change to this Arbitration Agreement, it will not apply to
            any individual claim(s) that a Provider had already provided notice
            of to Fast Semen.
            <br />
          </div>
          17. Litigation Class Action Waiver. To the extent allowed by
          applicable law, separate and apart from the Arbitration Agreement,
          Provider agrees that any proceeding to litigate in court any dispute
          arising out of or relating to this Agreement will be conducted solely
          on an individual basis, and Provider agrees not to seek to have any
          controversy, claim or dispute heard as a class action, a
          representative action, a collective action, a private attorney-general
          action, or in any proceeding in which Provider acts or proposes to act
          in a representative capacity. Provider further agrees that no
          proceeding will be joined, consolidated, or combined with another
          proceeding, without the prior written consent of all parties to any
          such proceeding. If this waiver of litigation class or consolidated
          actions is deemed invalid or unenforceable, in whole or in part, the
          invalid or unenforceable provision will be severed and such
          adjudication will not affect the validity of the remainder of this
          Agreement. Nothing in this provision will prevent the Parties from
          participating in a class-wide settlement of claims.
          <br />
          <br />
          18. Ratings and Reviews. Fast Semen may make available to Customers a
          forum to rate and post reviews of Providers or Products. Ratings and
          reviews are not endorsed by Fast Semen and do not represent the views
          of Fast Semen or its affiliates. Fast Semen will have no liability for
          ratings and reviews or for any claims for economic loss resulting from
          such ratings and reviews. All Customers agree to terms governing
          ratings and reviews contained in the Terms of Service for Customers.
          <br />
          <br />
          19. Partner Code of Conduct. Provider agrees to comply with the
          Partner Code of Conduct which may be updated by Fast Semen from time
          to time.
          <br />
          <br />
          20. Communications from Fast Semen. Provider agrees to accept and
          receive communications from Fast Semen via email, text message, calls,
          and push notifications to the cellular telephone number Provider
          provides to Fast Semen. Provider acknowledges that Provider may
          receive communications generated by automatic telephone dialing
          systems or systems which will deliver prerecorded messages sent by or
          on behalf of Fast Semen. Provider may opt out of such communications
          in Provider’s Account Settings or by replying “STOP” from the mobile
          device receiving such messages.
          <br />
          <br />
          21. Attorneys’ Fees. In any legal action to enforce the terms of this
          Agreement, the prevailing party in such action will be entitled to
          recover its reasonable attorneys’ fees and costs from the other party.
          <br />
          <br />
          22. General Provisions. These Terms of Service and the Marketplace
          Agreement constitute an integrated agreement between the Parties,
          which supersedes all prior agreements and communications of the
          Parties, oral or written, with respect to the subject matter hereof.
          The rights and obligations set forth in this Agreement, which by their
          nature should, or by their express terms do, survive or extend beyond
          the termination or expiration of the Agreement will so survive and
          extend. This Agreement is governed by and interpreted in accordance
          with the laws of the State of Texas without regard to the conflicts of
          laws principles thereof. Provider hereby consent to exclusive
          jurisdiction in the courts having jurisdiction in Erath County, Texas.
          Provider may not assign this Agreement in whole or in part without
          Fast Semen’s prior written consent. Fast Semen may freely assign this
          Agreement. This Agreement is binding upon, and inures to the benefit
          of, the employees, representatives, agents, affiliates, franchisors,
          franchisees, and permitted successors and assigns of each party, but
          will not confer any rights or remedies upon any third party. All
          notices, requests, consents, and other communications under this
          Agreement must be in writing. If any provision of this Agreement is
          held to be invalid, illegal, or unenforceable for any reason, such
          invalidity, illegality, or unenforceability will not affect any other
          provisions of this Agreement, and this Agreement will be construed as
          if such invalid, illegal or unenforceable provision had never been
          contained here.
          <br />
          <br />
          <br />
          23.
        </p>
      </div>
      <Footer />
    </>
  );
}

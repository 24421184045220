import "./modals.css";

import { Modal, Spinner, Dropdown } from "react-bootstrap";
import api from "../../../services/api";
import { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { apiUrl } from "../../../config/settings.json";
import Button from "../../../Components/Button";
import { FaTruck } from "react-icons/fa";
import { toast } from "react-toastify";

export default function ModalOrders(props) {
  const [sale, setSale] = useState({});
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get(`/orders-details/${props.idsale}`);
      setSale(data);
      setLoading(false);
    })();
  }, [props, props.idsale]);

  const submitCode = async (e) => {
    e.preventDefault();
    try {
      await api.post("/orders/codigo-envio", { code, idcompra: props.idsale });
      toast.success("Code successfully inserted");
      setCode("");
      props.onHide();
      props.callApi();
    } catch (e) {
      console.log(e);
    }
  };
  const handleChange = (eventkey) => {
    // a.persist();
    setPregnantmare(true, eventkey);
  };
  const setPregnantmare = async (prenha, pregnantValue) => {
    await api.post("/eguas/prenha/", {
      prenha: prenha ? 1 : 0,
      idcompra: sale.saleData.idcompra,
      embriao: pregnantValue,
    });
    props.onHide();
    toast.success("Mare successfully modified as pregnant!");
  };

  useEffect(() => {
    console.log(sale);
  }, [sale, props]);

  return (
    <Modal {...props}>
      <Modal.Body
        className="bg-light"
        style={{ minWidth: "70vw", margin: "0 auto" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-danger"
            onClick={() => props.onHide()}
          >
            <FaTimes color="#990000" />
          </button>
        </div>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "70vh" }}
          >
            <Spinner animation="border" variant="dark" />
          </div>
        ) : (
          <div className="p-4">
            <div className="d-flex justify-content-between w-100">
              <h4 className="" style={{ color: "#646464" }}>
                Details | Order: {props.idsale}
              </h4>
            </div>
            <div
              style={{ background: "#fff" }}
              className="conteiner-modal p-4 mt-2"
            >
              <div className="d-flex flex-column flex-lg-row">
                <img src={`${apiUrl}/${sale?.saleData?.diretorio}`} />
                <div
                  className="px-3"
                  style={{
                    borderRight:
                      window.innerWidth <= 992 ? "none" : "2px solid #aaa",
                  }}
                >
                  <p>
                    STALLION STATION:
                    <strong>{sale?.saleData?.vendedor}</strong>
                  </p>
                  <h4>{sale?.saleData?.nome}</h4>
                  <h4>$ {sale?.saleData?.valor?.toFixed(2)}</h4>
                </div>
                <div className="ml-3">
                  <p className="m-0 p-0">Collection Date</p>
                  <div className="d-flex mb-3">
                    <span className="bg-light p-1">
                      Month: {sale?.saleData?.mes}
                    </span>
                    <span className="bg-light p-1 ml-3">
                      Day: {sale?.saleData?.dia}
                    </span>
                  </div>
                  {sale?.saleData?.code?.length >= 1 && (
                    <span className="bg-light p-1">
                      tracking code: <strong>{sale?.saleData?.code}</strong>
                    </span>
                  )}
                  <br />
                  <br />
                  {sale?.saleData?.prenha !== null && (
                    <span className="bg-light p-1">
                      Mare Is pregnant?{" "}
                      <strong>
                        {sale?.saleData?.prenha === 0
                          ? "No"
                          : sale?.saleData?.prenha === 1
                          ? `Yes - ${sale?.saleData?.embriao}`
                          : "not specified"}
                      </strong>
                    </span>
                  )}

                  {sale?.saleData?.status && sale?.saleData?.prenha === null && (
                    <p className="w-100 d-flex flex-column mt-1">
                      is your mare pregnant?
                      <span className="d-flex justify-content-between">
                        {/* <button
                          onClick={()=>{

                          }}
                          //onClick={() => setPregnantmare(true)}
                          className="btn btn-success"
                        >
                          Yes
                        </button> */}

                        <Dropdown onSelect={handleChange}>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            Yes
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="ICSI">ICSI</Dropdown.Item>
                            <Dropdown.Item eventKey="1st Embryo">
                              1st Embryo
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="2nd Embryo">
                              2nd Embryo
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="3rd Embryo">
                              3rd Embryo
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="Frozen Embryo">
                              Frozen Embryo
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <button
                          onClick={() => setPregnantmare(false, "")}
                          className="btn btn-primary"
                        >
                          No
                        </button>
                      </span>
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <h5>More Information</h5>
                <div>
                  {props?.param === "await-payment" ? (
                    <>
                      <button
                        className="mx-3 btn btn-danger"
                        onClick={async () => {
                          try {
                            await api.get(
                              `/produto-cancelamento/${props.idsale}`
                            );
                            toast.success(
                              "product status changed successfully"
                            );
                            props.onHide();
                            props.callApi();
                          } catch (e) {
                            toast.error(e.response.data.message);
                          }
                        }}
                      >
                        Calcel Order
                      </button>
                    </>
                  ) : props.param === "Delivery" ? (
                    <>
                      <Button
                        onClick={async () => {
                          try {
                            await api.get(`/produto-recebido/${props.idsale}`);
                            toast.success(
                              "product status changed successfully"
                            );
                            props.onHide();
                            props.callApi();
                          } catch (e) {
                            toast.error(e.response.data.message);
                          }
                        }}
                      >
                        Product already delivered
                      </Button>
                    </>
                  ) : props.param === "Payment-confirmed" ? (
                    <button
                      className="mx-3 btn btn-danger"
                      onClick={async () => {
                        try {
                          await api.get(
                            `/produto-cancelamento/${props.idsale}`
                          );
                          toast.success("product status changed successfully");
                          props.onHide();
                          props.callApi();
                        } catch (e) {
                          toast.error(e.response.data.message);
                        }
                      }}
                    >
                      Calcel Order
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {sale?.items?.map((item) => {
                const keys = !!item.data ? Object.keys(item?.data) : [];
                const values = !!item.data ? Object.values(item?.data) : [];
                return (
                  <div
                    className="table-responsive"
                    style={{ maxWidth: "50vw", overflow: "auto" }}
                  >
                    <h5>{item.title}</h5>
                    <table
                      className="w-100 col-12"
                      style={{ maxWidth: "100%" }}
                    >
                      <thead className="bg-light">
                        <tr>
                          {keys.map((key) => {
                            return (
                              <th className="text-capitalize text-truncate col-3">
                                {String(key).replace("_", " ")}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {values.map((val) => {
                            return (
                              <td className="text-capitalize text-truncate col-3">
                                {String(val).replace("_", " ")}
                              </td>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

import { Modal } from "react-bootstrap";
export default function ModalWarn(props) {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Body>
        <h3 className="text-center text-danger">ATTENTION</h3>
        <br />
        <br />
        {props.children}
        <br />
        <div className="d-flex justify-content-around">
          <button className="btn btn-success" onClick={props.onHide}>
            No
          </button>
          <button className="btn btn-danger" onClick={props.function}>
            Yes
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

import "../styles.css";
import { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";

import ArrowLeftImage from "../../../assets/arrowLeft.svg";
import ArrowRightImage from "../../../assets/arrowRight.svg";
import BackCircleGrayImage from "../../../assets/backcirclegray.svg";
import ExitModalImage from "../../../assets/exit.svg";
import { FunctionContext } from "../../../context";
import api from "../../../services/api";
import { toast } from "react-toastify";
import IntensMap from "../Components/Item";

export default function ModalInformacao(props) {
  const FunctionContextData = useContext(FunctionContext);
  const {
    cavalo,
    userToken,
    mostCreateAddres,
    mostCreateBroodmare,
    setSelectedTipoEnvio,
    setTipoSemen,
  } = FunctionContextData;

  const [qtd, setQtd] = useState(1);
  const [shipping, setShipping] = useState({});
  const [adictional, setAdictional] = useState({});
  const [broadmare, setBroadmare] = useState([]);
  const [broadmareSelected, setBroadmareSelected] = useState(false);
  const [address, setAddress] = useState([]);
  const [addressSelected, setAddressSelected] = useState(false);
  const [tiposEnviosData, setTiposEnviosData] = useState([]);
  const [adicionais, setAdicionais] = useState([]);

  useEffect(() => {
    (async () => {
      if (userToken) {
        const brooadmares = await api.get("/eguas").catch((err) => {
          return toast.error(err.response.data.message);
        });
        setBroadmare(brooadmares.data);

        const addressUser = await api.get("/endereco").catch((err) => {
          return toast.error(err.response.data.message);
        });

        setAddress(addressUser.data);
      }
    })();
  }, [mostCreateBroodmare, mostCreateAddres]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/tipos-envio/${cavalo.garanhao}`);
      const mapData = data.map((item) => {
        if (item.standard === 1) {
          item.cheked = true;
          setSelectedTipoEnvio(item);
        } else {
          item.cheked = false;
        }

        return item;
      });

      setTiposEnviosData(mapData);

      const { data: dataAdicional } = await api.get(
        `/adicionais/${cavalo.garanhao}`
      );
      setAdicionais(dataAdicional);
    })();
  }, [cavalo]);

  return (
    <>
      <div className="d-flex flex-column" style={{ marginTop: 35 }}>
        <div>
          <select
            className="form-control mb-3"
            onChange={(e) => setTipoSemen(e.target.value)}
          >
            <option selected disabled>
              Select the type of semen
            </option>
            <option value="fresh">Cooled Semen </option>
            <option value="frozen">Frozen Semen</option>
          </select>
          <div className="header">
            <div className="d-flex flex-row justify-content-between">
              <h4>Shipping type</h4>
            </div>
            <p className="p-0 m-0">Select shipping type</p>
          </div>
          <div
            className="itens mb-5 mb-sm-0"
            style={{
              height: tiposEnviosData.length * 50,
              display: "block",
            }}
          >
            {tiposEnviosData.map((tipo) => {
              return (
                <IntensMap
                  item={{
                    key: tipo.idtipos_envio,
                    name: tipo.tipos,
                    value: tipo.valor,
                    standard: tipo.standard,
                    type: "envio",
                    nativeItem: tipo,
                  }}
                  key={tipo.idtipos_envio}
                />
              );
            })}
          </div>
        </div>
        <br />
        <div className="mt-5">
          <div className="header mt-5 mt-sm-0">
            <div className="d-flex flex-row justify-content-between">
              <h4>Add to order</h4>
            </div>
            <p className="p-0 m-0">
              When selecting any additional, it will be counted directily in the
              total value of the order
            </p>
          </div>
          <div
            className="itens"
            style={{
              height: adicionais.length * 90,
              display: "block",
            }}
          >
            {adicionais.map((adicional) => {
              return (
                <IntensMap
                  item={{
                    key: adicional.idadicionais,
                    name: adicional.adicional,
                    value: adicional.preco,
                    type: "adicional",
                    nativeItem: adicional,
                  }}
                  key={adicional.idadicionais}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

import "./MyHorses.css";
import React, { useState, useEffect, useContext } from "react";
import { apiUrl } from "../../config/settings.json";

import { FaPlus } from "react-icons/fa";

import api from "../../services/api";
import Aside from "../../Components/Aside";
import Header from "../../Components/Header";
import Button from "../../Components/Button";
import { Spinner } from "react-bootstrap";
import ViewDataAddress from "../../Components/Modals/ViewDataAddress";
import ModalCrateAddress from "../../Components/Modals/RegisterAddress";
import { FunctionContext as Context } from "../../context";

export default function MyAddress() {
  const context = useContext(Context);
  const [addresses, setAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modifymare, setModifyMare] = useState(false);
  const [idAddress, setIdAddress] = useState(null);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      context.setShowAside(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await api.get("/endereco").catch(() => {
        setLoading(false);
        window.location.reload();
      });
      setAddress(data);
      setLoading(false);
    })();
  }, []);

  async function GetHorses() {
    setLoading(true);
    const { data } = await api.get("/endereco").catch(() => {
      setLoading(false);
      window.location.reload();
    });
    setAddress(data);
    setLoading(false);
  }

  return (
    <>
      <ModalCrateAddress
        show={showModal}
        onHide={() => {
          setShowModal(false);
          GetHorses();
        }}
      />
      <ViewDataAddress
        show={modifymare}
        onHide={() => {
          setModifyMare(false);
          GetHorses();
        }}
        GetHorses={GetHorses}
        data={idAddress}
        className="modal-order"
      />
      <Header />
      <div className="d-flex" style={{ minHeight: "90vh" }}>
        <div
          className="bg-dark col-12 col-sm-3 col-lg-2 px-0"
          style={{
            display: context.showAside ? "none" : "block",
          }}
        >
          <Aside />
        </div>
        <main
          className={
            context.showAside
              ? "p-3 px-5 bg-light col-12 h-100"
              : "d-none d-sm-block p-3 px-5 bg-light col-12 col-sm-9 col-lg-10 h-100"
          }
          style={{ minHeight: "90vh" }}
        >
          <h3 className="text-capitalize">My Address</h3>

          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <div style={{ background: "#fff" }} className="p-3 mt-5">
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowModal(true)}>
                  Register a new address <FaPlus color="#17a2b8" size={16} />
                </Button>
              </div>
              <div className="grid">
                {addresses.map((item) => {
                  return (
                    <p
                      className="px-3 py-2 text-truncate text-center col-12 mx-100 w-100"
                      style={{
                        boxShadow: "2px 2px 6px #aaa",
                        borderRadius: "30px",
                      }}
                      onClick={() => {
                        setModifyMare(true);
                        setIdAddress(item.idenderecos);
                      }}
                    >
                      {item.rua}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
}

import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import Axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import api from "../../services/api";
import { uuid } from "uuidv4";

import { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "../Payment/CheckoutForm.js";
import { Container } from "../Payment/styles";

import { useLocation, useParams } from "react-router";
import { FunctionContext } from "../../context";

// import Header from "../../Components/Header/index.js";
// import Footer from "../../Components/Footer/index.js";
import { apiUrl } from "../../config/settings.json";
// import { useLocation } from "react-router";
// import { FunctionContext } from "../../context";

const stripePromise = loadStripe(
  "pk_test_51J0Q7rGyZdtWCCRHX59VbqanLWjxQFCX78FLHoTQ4Z2n5SEWsMzRCIxlnw4EfrloiT4A2XH5FLYveNVAZO2qqI3f00zkJlJpuV"
);

class PaymentOld extends React.Component {
  constructor(props) {
    super(props);
    this.paymentContainer = React.createRef();
    this.paymentComponent = null;

    this.onSubmit = this.onSubmit.bind(this);
    this.onAdditionalDetails = this.onAdditionalDetails.bind(this);
    this.processPaymentResponse = this.processPaymentResponse.bind(this);
    this.state = { paymentMethodsResponse: {}, total: 0 };
  }

  handleOnChange(state, component) {
    if (!state.isValid) {
      return toast.error("Incorrect data");
    } else {
      Axios({
        url: "https://checkout-test.adyen.com/v65/payments",
        method: "POST",
        data: JSON.parse(state.data),
      });
    }
  }

  async onSubmit(state, component) {
    if (!state.isValid) {
      return toast.error("Incorrect data");
    } else {
      try {
        const { data: item } = await api.get(
          `/api/pagamentos/criar-acumulador/${this.props.match.params.id}`
        );
        console.log("SUnny Shah", item);
        const total = +item.chutefee + (+item.valor || 0) + (+item.preco || 0);

        const data = {
          idgaranhao: item.idgaranhao,
          idegua: item.idegua,
          tipo_semem: item.tipos_semen,
          tipos_envio_idtipos_envio: item.idtipos_envio,
          idendereco: item.enderecos_comprador_idenderecos,
          chutefee: item.enderecos_comprador_idenderecos,
          dia: item.dia,
          mes: item.mes,
          adicionais_idadicionais: item.adicionais_idadicionais,
        };
        api
          .post("/api/pagamentos/criar", {
            paymentMethod: state.data.paymentMethod,
            amount: {
              currency: "USD",
              value: total * 100,
            },
            cavalo: data,
          })
          .then(({ data }) => {
            if (data.action) {
              component.handleAction(data.action);
            } else {
              window.location.href = `/payment/${String(
                data.resultCode
              ).toLowerCase()}/app`;
            }
          });
      } catch (error) {
        toast.error("payment error, please continue later");
      }
    }
  }

  componentWillUnmount() {
    localStorage.clear();
  }

  async componentDidMount() {
    if (!this.props.match.params.id || !this.props.match.params.token) {
      window.location.href = "fastsemen://app";
    }

    localStorage.setItem("@TOKEN", this.props.match.params.token);

    const { data: item } = await api.get(
      `/api/pagamentos/criar-acumulador/${this.props.match.params.id}`
    );
    console.log("sunny shah", item.quantity);
    const total =
      +(item.chutefee * item.quantity) +
      (+item.valor || 0) +
      (+item.preco || 0);

    api
      .get("/pagamento/metodos")
      .then(({ data }) => {
        this.setState({ paymentMethodsResponse: data });
      })
      .catch((e) => alert(e));

    this.checkout = new AdyenCheckout({
      paymentMethodsConfiguration: {
        card: {
          name: "put your credit card",
        },
      },
      onSubmit: this.onSubmit,
      locale: "en_US",
      environment: process.env.REACT_APP_ENVIRONMENT,
      clientKey: process.env.REACT_APP_CLIENT_KEY,
      paymentMethodsResponse: this.state.paymentMethodsResponse,
    });

    this.checkout
      .create("card", {
        showPayButton: true,
        amount: {
          currency: "USD",
          value: total * 100,
        },
        hasHolderName: false,
        holderNameRequired: false,
      })
      .mount(this.paymentContainer.current);
  }

  processPaymentResponse(paymentRes) {
    if (paymentRes.action) {
      this.paymentComponent.handleAction(paymentRes.action);
    } else {
      switch (paymentRes.resultCode) {
        case "Authorised":
          window.location.href = "/payment/success";
          break;
        case "Pending":
        case "Received":
          window.location.href = "/payment/pending";
          break;
        case "Refused":
          window.location.href = "/payment/failed";
          break;
      }
    }
  }

  onAdditionalDetails(state, component) {
    this.props.submitAdditionalDetails(state.data);
    this.paymentComponent = component;
  }

  render() {
    return (
      <>
        <Header />
        <div className="container my-5">
          <h2>Payment Method1</h2>
          <div
            className="mt-5 p-4"
            style={{
              backgroundColor: "#fff",
              borderRadius: 15,
              boxShadow: " 0px 2px 6px #00000029",
            }}
          >
            <h3>Insert your card details!</h3>
            <div ref={this.paymentContainer} className="payment"></div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default function Payment() {
  const context = useContext(FunctionContext);
  const [clientSecret, setClientSecret] = useState("");
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    (async () => {
      const { data: item } = await api.get(
        `/api/pagamentos/criar-acumulador/${params.id}`
      );
      console.log("item Sunny", item);
      const data = {
        idgaranhao: item.idgaranhao,
        idegua: item.idegua,
        tipo_semem: item.tipos_semen,
        tipos_envio_idtipos_envio: item.idtipos_envio,
        idendereco: item.enderecos_comprador_idenderecos,
        chutefee: item.enderecos_comprador_idenderecos,
        dia: item.dia,
        mes: item.mes,
        adicionais_idadicionais: item.adicionais_idadicionais,
        quantity: item.quantity,
      };

      const total =
        +(item.chutefee * item.quantity) +
        (+item.valor || 0) +
        (+item.preco || 0);
      console.log("total", total);
      await localStorage.setItem("@TOKEN", params.token);
      // const cartItems = await api.get("/carrinho");
      // const total = cartItems.data.reduce((acc, cav) => {
      //   const preco =
      //     (cav.isChutefee === 1 ? 0 : cav.preco) +
      //     (cav.precoAdicional || 0) +
      //     (cav.tipoSelected.length >= 1
      //       ? cav.free_shipping === 0
      //         ? cav.tipoSelected[0].valor
      //         : 0
      //       : 0);

      //   return +preco + +acc;
      // }, 0);
      // alert(total);
      // Create PaymentIntent as soon as the page loads
      await localStorage.setItem(
        "@PAYMENT_DATA",
        JSON.stringify({
          amount: {
            value: total * 100,
          },
          cavalo: data,
        })
      );
      // console.log({
      //   amount: {
      //     value: location.state.value * 100,
      //   },
      //   cart: !!location.state.cart ? true : false,
      //   cavalo: location.state.data,
      // });

      fetch(`${apiUrl}create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: total * 100 }),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));

      // console.log({
      //   amount: {
      //     currency: "USD",
      //     value: +location.state.value,
      //   },
      //   cart: !!location.state.cart ? true : false,
      //   cavalo: location.state.data,
      // });
    })();
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Header />
      <Container>
        <div className="container my-5">
          <h2>Payment Method</h2>
          <div
            className="mt-5 p-4"
            style={{
              backgroundColor: "#fff",
              borderRadius: 15,
              boxShadow: " 0px 2px 6px #00000029",
            }}
          >
            <h3>Insert your card details!</h3>
            <div className="App">
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              )}
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

import React, { createContext, useState, useEffect } from "react";
import ModalRegisterAddress from "../Components/ModalRegisterBroodmare";
import ModalRegisterBroodmare from "../Components/ModalRegisterAddress";
import api from "../services/api";

export const FunctionContext = createContext({
  total: null,
  cavalo: null,
  username: null,
  setTotal: () => null,
  carrinho: null,
  userToken: null,
  setCavalo: () => null,
  setCarrinho: () => null,
  setUsername: () => null,
  configFilter: null,
  setUserToken: () => null,
  setConfigFilter: () => null,
  mostCreateAddres: null,
  selectedTipoEnvio: null,
  setMostCreateAddres: () => null,
  mostCreateBroodmare: null,
  setSelectedTipoEnvio: () => null,
  setMostCreateBroodmare: () => null,
  setSelectedAdicionais: () => null,
  selectedAdicionais: null,
  setTipoSemen: () => null,
  tipoSemen: null,
  showAside: false,
  setShowAside: () => null,
  paymentData: {},
  setPaymentData: () => null,
});

export default function FunctionContextProvider({ children }) {
  const [mostCreateAddres, setMostCreateAddres] = useState(false);
  const [mostCreateBroodmare, setMostCreateBroodmare] = useState(false);
  const [showAside, setShowAside] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const [carrinho, setCarrinho] = useState(0);
  const [configFilter, setConfigFilter] = useState({});
  const [selectedTipoEnvio, setSelectedTipoEnvio] = useState({});
  const [selectedAdicionais, setSelectedAdicionais] = useState({});
  const [cavalo, setCavalo] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [username, setUsername] = useState("");
  const [tipoSemen, setTipoSemen] = useState("");
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("@TOKEN");
    const username = localStorage.getItem("@USERNAME");
    setUserToken(token || null);
    setUsername(username);
  }, []);
  useEffect(() => {
    (async () => {
      if (userToken) {
        const cart = await api.get("/carrinho");
        let totalMap = 0;

        cart.data.map((item) => {
          totalMap =
            totalMap +
            (item.isChutefee === 1 ? 0 : item.chutefee) +
            (item.precoAdicional || 0) +
            (item.tipoSelected.length >= 1
              ? item.free_shipping === 0
                ? item.tipoSelected[0].valor
                : 0
              : 0);
          item.loading = false;
        });
        setCarrinho(totalMap);
      }
    })();
  }, [userToken]);

  useEffect(() => {
    setTotal(
      (!!cavalo?.chutefeePrepaid ? 0 : cavalo.chutefee) +
        (cavalo.freeShipping && selectedTipoEnvio.standard === 1
          ? 0
          : selectedTipoEnvio.valor || 0) +
        (selectedAdicionais.preco || 0)
    );
  }, [cavalo, selectedTipoEnvio, selectedAdicionais]);

  return (
    <>
      <FunctionContext.Provider
        value={{
          setShowAside,
          showAside,
          total,
          cavalo,
          username,
          setTotal,
          carrinho,
          userToken,
          setCavalo,
          tipoSemen,
          setCarrinho,
          setUsername,
          configFilter,
          setUserToken,
          setTipoSemen,
          setConfigFilter,
          mostCreateAddres,
          selectedTipoEnvio,
          selectedAdicionais,
          setMostCreateAddres,
          mostCreateBroodmare,
          setSelectedTipoEnvio,
          setSelectedAdicionais,
          setMostCreateBroodmare,
          paymentData,
          setPaymentData,
        }}
      >
        {children}
      </FunctionContext.Provider>
      <ModalRegisterBroodmare
        onHide={() => setMostCreateBroodmare(false)}
        show={mostCreateBroodmare}
      />
      <ModalRegisterAddress
        onHide={() => setMostCreateAddres(false)}
        show={mostCreateAddres}
      />
    </>
  );
}

import axios from "axios";

import { apiUrl } from "../config/settings.json";

const api = axios.create({
  baseURL: apiUrl,
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("@TOKEN");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err) => {
    if (localStorage.getItem("@TOKEN") && err.response?.status === 401) {
      window.location.reload();
      localStorage.clear();
      window.location.reload();
    } else if (err.response?.status === 408) {
      return Promise.reject(err);
    } else {
      return Promise.reject(err);
    }
  }
);

export default api;

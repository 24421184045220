import "./styles.css";
import { useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { toast } from "react-toastify";
import api from "../../services/api";
import ModalForgotPass from "../../Components/ModalForgotPass";

export default function Login() {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [modal, setModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await api.post("/login", { usuario, senha });
      localStorage.setItem("@TOKEN", res.data.token);
      localStorage.setItem("@USERNAME", res.data.username);
      toast.info(`Welcome ${res.data.usuario}`);
      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Header />
      <div className="container my-4">
        <h3>Sign in</h3>

        <div className="signin-conteiner px-3 py-5 my-5 d-flex flex-column flex-md-row justify-content-around">
          <form
            onSubmit={handleSubmit}
            className="col-12 col-md-5 d-flex flex-column"
          >
            <h2 className="mb-4">I already have an account</h2>

            <label className="d-flex flex-column w-100">
              E-mail or User
              <input
                value={usuario}
                onChange={(e) => setUsuario(e.target.value)}
                style={{ borderWidth: 2, borderRadius: 5 }}
                className="pl-1 form-control"
              />
            </label>
            <br />
            <label className="d-flex flex-column w-100">
              Password
              <input
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                type="password"
                style={{ borderWidth: 2, borderRadius: 5 }}
                className="pl-1 form-control"
              />
            </label>
            <button
              className="btn text-info align-self-start mt-2"
              onClick={() => {
                setModal(true);
              }}
              type="button"
            >
              Forgot password?
            </button>
            <br />
            <button type="submit" className="btn btn-primary ">
              Login
            </button>
          </form>
          <div className="divider" />
          <div>
            <h2 className="mb-3">Create Your account now!</h2>
            <p className="mb-4">Create your account to continue Ordering.</p>
            <a href="/signin" className="btn btn-primary font-weight-bold">
              CLICK HERE
            </a>
          </div>
        </div>
      </div>
      <Footer />
      <ModalForgotPass show={modal} onHide={() => setModal(false)} />
    </>
  );
}

import React from "react";
export default function Button(props) {
  return (
    <button
      className={props.className + " btn text-info px-3 py-1"}
      style={{ borderRadius: 30, boxShadow: "3px 3px 7px #aaa" }}
      {...props}
    >
      {props.children}
    </button>
  );
}

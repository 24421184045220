import "./MyHorses.css";
import React, { useState, useEffect, useContext } from "react";
import { apiUrl } from "../../config/settings.json";

import { FaPlus } from "react-icons/fa";

import api from "../../services/api";
import Aside from "../../Components/Aside";
import Header from "../../Components/Header";
import Button from "../../Components/Button";
import { Spinner } from "react-bootstrap";
import ViewDateMare from "../../Components/Modals/ViewDataMare";
import ModalCrateBroadmare from "../../Components/Modals/RegisterBroodmare";
import { FunctionContext as Context } from "../../context";

export default function MyHorses() {
  const context = useContext(Context);
  const [mare, setMare] = useState({ data: [], breeds: [] });
  const [raca, setRaca] = useState("all");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [modifymare, setModifyMare] = useState({ most: false, data: null });
  const [idegua, setIdEgua] = useState(null);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      context.setShowAside(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (raca === "all") {
        const { data } = await api.get("/comprador/eguas").catch(() => {
          setLoading(false);
          window.location.reload();
        });
        const mares = data.breeds.reduce((acc, curr) => {
          const maresId = acc.map((item) => item.idraca);
          console.log(maresId);
          if (maresId.includes(curr.idraca)) {
            return acc;
          } else {
            return [...acc, curr];
          }
        }, []);
        setMare({ ...data, breeds: mares });
      } else {
        const { data } = await api.get(`/comprador/eguas/${raca}`).catch(() => {
          setLoading(false);
          window.location.reload();
        });

        const mares = data.breeds.reduce((acc, curr) => {
          const maresId = acc.map((item) => item.idraca);
          console.log(maresId);
          if (maresId.includes(curr.idraca)) {
            return acc;
          } else {
            return [...acc, curr];
          }
        }, []);
        setMare({ ...data, breeds: mares });
      }
      setLoading(false);
    })();
  }, [raca]);

  async function GetHorses() {
    setLoading(true);
    if (raca === "all") {
      const { data } = await api.get("/comprador/eguas/").catch(() => {
        setLoading(false);
        window.location.reload();
      });
      const mares = data.breeds.reduce((acc, curr) => {
        const maresId = acc.map((item) => item.idraca);
        console.log(maresId);
        if (maresId.includes(curr.idraca)) {
          return acc;
        } else {
          return [...acc, curr];
        }
      }, []);
      setMare({ ...data, breeds: mares });
    } else {
      const { data } = await api.get(`/comprador/eguas/${raca}`).catch(() => {
        setLoading(false);
        window.location.reload();
      });
      const mares = data.breeds.reduce((acc, curr) => {
        const maresId = acc.map((item) => item.idraca);
        console.log(maresId);
        if (maresId.includes(curr.idraca)) {
          return acc;
        } else {
          return [...acc, curr];
        }
      }, []);
      setMare({ ...data, breeds: mares });
    }
    setLoading(false);
  }

  return (
    <>
      <ModalCrateBroadmare
        show={showModal}
        onHide={() => {
          setShowModal(false);
          GetHorses();
        }}
        data={idegua}
      />

      <ViewDateMare
        show={modifymare.most}
        onHide={() => {
          setModifyMare({ most: false, data: null });
          GetHorses();
        }}
        GetHorses={GetHorses}
        data={modifymare.data}
        className="modal-order"
      />
      <Header />
      <div className="d-flex" style={{ minHeight: "90vh" }}>
        <div
          className="bg-dark col-12 col-sm-3 col-lg-2 px-0"
          style={{
            display: context.showAside ? "none" : "block",
          }}
        >
          <Aside />
        </div>
        <main
          className={
            context.showAside
              ? "p-3 px-5 bg-light col-12 h-100"
              : "d-none d-sm-block p-3 px-5 bg-light col-12 col-sm-9 col-lg-10 h-100"
          }
          style={{ minHeight: "90vh" }}
        >
          <h3 className="text-capitalize">My Mares</h3>

          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "70vh" }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <div style={{ background: "#fff" }} className="p-3 mt-5">
              <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                <select
                  className="col-sm-4 form-control col-12 mb-4 mb-sm-0"
                  onChange={(e) => {
                    setRaca(e.target.value);
                  }}
                  value={raca}
                >
                  <option selected disabled value="all">
                    My list
                  </option>
                  <option value="all">All</option>
                  {mare.breeds.map((item) => {
                    return <option value={item.idraca}>{item.raca}</option>;
                  })}
                </select>
                <Button onClick={() => setShowModal(true)}>
                  Register a new mare <FaPlus color="#17a2b8" size={16} />
                </Button>
              </div>
              <div className="grid">
                {mare.data.map((item) => {
                  return (
                    <div
                      className="d-flex flex-column align-items-center btn"
                      onClick={() => {
                        setModifyMare({ most: true, data: item.idegua });
                      }}
                    >
                      <p
                        className="px-3 py-2 w-100 text-truncate"
                        style={{
                          boxShadow: "2px 2px 6px #aaa",
                          borderRadius: "30px",
                        }}
                      >
                        {item.nome}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
}

import React from "react";
import "./styles.css";

export default function TableInfoProduct({ data }) {
  return (
    <>
      <div className="table-responsive">
        {data.map((dataItem, index) => {
          const keys = Object.keys(dataItem);
          const values = Object.values(dataItem);
          return (
            <>
              {index >= 1 && <hr />}
              <h5 className="mt-4 text-dark">{dataItem.title}</h5>
              <table className="w-100 text-dark">
                <thead className="bg-dark text-light">
                  {keys.map((key, index) => {
                    console.log("Key", key);
                    return index > 0 ? (
                      <th className="p-0 text-nowrap text-truncate px-2 text-capitalize">
                        {key === "Colection Date"
                          ? "Pickup Date"
                          : key === "Name of the street"
                          ? "Street Name"
                          : key}
                      </th>
                    ) : (
                      ""
                    );
                  })}
                </thead>
                <tbody>
                  {values.map((val, index) => {
                    return index > 0 ? (
                      <td className="text-truncate text-nowrap px-2 ">{val}</td>
                    ) : (
                      ""
                    );
                  })}
                </tbody>
              </table>
            </>
          );
        })}
      </div>
    </>
  );
}

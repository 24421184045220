import { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import api from "../../../services/api";

export default function ModalRegisterBroodmare(props) {
  const [name, setName] = useState("");
  const [number, setNumber] = useState();
  const [breed, setBreed] = useState("");
  const [loading, setLoading] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/eguas/${props.data}`);
      setName(data.nome);
      setNumber(data.numero_registro);
      setBreed(data.raca);
    })();
  }, [props.data, props]);
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!props.data) {
      await api
        .post("/eguas", {
          nome: name,
          num_registro: number,
          raca: breed,
        })
        .then((res) => {
          setName("");
          setNumber("");
          setBreed("");
          props.onHide();
          toast.success("mare successfully raised!");
        });
    } else {
      await api
        .put("/eguas", {
          nome: name,
          num_registro: number,
          raca: breed,
          idegua: props.data,
        })
        .then((res) => {
          setName("");
          setNumber("");
          setBreed("");
          props.onHide();
          toast.success("mare successfully raised!");
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }

    setLoading(false);
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="col-8 mx-auto py-5">
        <h3>Register a new mare</h3>
        <br />
        <form className="d-flex flex-column" onSubmit={submitForm}>
          <label className="d-flex flex-column">
            Broodmare
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
            />
          </label>
          <label className="d-flex flex-column">
            Registration number
            <input
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              className="form-control"
            />
          </label>
          <label className="d-flex flex-column">
            Breed
            <input
              value={breed}
              onChange={(e) => setBreed(e.target.value)}
              className="form-control"
            />
          </label>
          <button type="submit" className="btn btn-info align-self-end mt-4">
            {loading && <Spinner animation="border" />}
            {!loading && "Save"}
          </button>
        </form>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
}

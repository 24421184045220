import "./styles.css";
import { Link, useHistory } from "react-router-dom";
import UserLightImage from "../../assets/user-light.svg";
import TelImage from "../../assets/telefone.svg";
import MailImage from "../../assets/mail.svg";
import UserDarkImage from "../../assets/user-dark.svg";
import CartImage from "../../assets/cart.svg";
import LogoImage from "../../assets/logo.svg";
import BellImage from "../../assets/bell.svg";
import api from "../../services/api";
import { FunctionContext as Context } from "../../context";
import { stallionConsole } from "../../config/settings.json";

import { Navbar, Nav, Badge, Popover, OverlayTrigger } from "react-bootstrap";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";

export default function Header() {
  const context = useContext(Context);
  const history = useHistory();

  const [usuario, setUsuario] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [openPopover, setOpenPopover] = useState(false);
  const [openPopoverUser, setOpenPopoverUser] = useState(false);
  const [openPopoverTerms, setOpenPopoverTerms] = useState(false);
  const [openPopoverTermsMobile, setOpenPopoverTermsMobile] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);

  const submitPopover = async (e) => {
    e.preventDefault();
    try {
      const res = await api.post("/login", { usuario, senha });
      localStorage.setItem("@TOKEN", res.data.token);
      localStorage.setItem("@USERNAME", res.data.username);
      context.setUserToken(res.data.token);
      context.setUsername(res.data.username);
      toast.info(`Welcome ${res.data.username}`);
      setOpenPopover(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  
  const submitEmail = async (e) => {
    e.preventDefault();

    try {
      const { data } = await api.post("/recuperar-senha", {
        email,
      });
      localStorage.setItem("@USEREMAIL", email);

      toast.info(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
 
  const popover = (
    <Popover id="popover-basic"  variant="dark">
      <Popover.Content
        style={{ background: "#222", color: "#fff",width:400 }}
        variant="dark"
      >
         
        {!forgotPass && (
          <>
            <br />
            <p
              style={{ fontWeight: "normal", fontSize: 18 }}
              className="font-weight-normal text-center"
            >
              Sign in
            </p>
            <form
              className="d-flex flex-column align-items-center w-100"
              onSubmit={submitPopover}
            >
              <label className="d-flex flex-column w-100">
                E-mail or User
                <input
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                  style={{ borderWidth: 2, borderRadius: 5 }}
                  className="pl-1 form-control"
                />
              </label>
              <label className=" d-flex flex-column w-100">
                Password
                <input
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  type="password"
                  style={{ borderWidth: 2, borderRadius: 5 }}
                  className="pl-1 form-control"
                />
              </label>
             
              <button
                className="align-self-end mt-0 text-light btn text-info"
                style={{ fontSize: 12 }}
                onClick={() => setForgotPass(true)}
                type="button"
              >
                Forgot Your Password?
              </button>
              <button
                type="submit"
                className="btn col-12 mt-1 text-light mb-3"
                style={{ backgroundColor: "#04c2af" }}
              >
                SignIn
              </button>
              
              <a
                className="link btn text-dark col-12"
                href="/signin"
                style={{ backgroundColor: "#e1e1e1" }}
              >
                Create Your Account
              </a>
            </form>
          </>
        )}
        {forgotPass && (
          <>
            <br />
            <h5>Enter your email.</h5>
            <form onSubmit={submitEmail}>
              <input
                className="form-control"
                placeholder="Please enter your email here"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                required
              />
              <button
                type="submit"
                className="btn btn-primary mt-2 col-12 w-100"
              >
                Submit
              </button>
            </form>
          </>
        )}
      </Popover.Content>
    </Popover>
  );

  const popoverUser = (
    <Popover style={{ width: 100 }}>
      <Popover.Content
        style={{ background: "#222", color: "#fff", width: "100%" }}
      >
        <ul>
          <li
            style={{ fontSize: 15, cursor: "pointer" }}
            className="w-100 my-3"
            onClick={() => {
              history.push("/my-account/orders?page=1");
            }}
          >
            My Account
          </li>
          <li
            style={{ fontSize: 15, cursor: "pointer" }}
            className="w-100 my-3"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Logout
          </li>
        </ul>
      </Popover.Content>
    </Popover>
  );
  const popoverTerms = (
    <Popover style={{ width: 200 }}>
      <Popover.Content
        style={{ background: "#222", color: "#fff", width: "100%" }}
      >
        <ul>
          <li className="mx-xl-3 text-dark text-center py-2">
            <a className="text-light" href="/code-conduct">
              Code Of Conduct
            </a>
          </li>
          <li className="mx-xl-3 text-dark text-center py-2">
            <a className="text-light" href="/terms-service-custumer">
              Customer Terms of Service
            </a>
          </li>
          <li className="mx-xl-3 text-dark text-center py-2">
            <a className="text-light" href="/terms-service-providers">
              Providers Terms of Service
            </a>
          </li>
        </ul>
      </Popover.Content>
    </Popover>
  );
  return (
    <div className="p-0">
      <div className="infoHeader d-flex justify-content-between">
        <div className="d-sm-flex align-self-center flex-row align-items-start justify-content-end justify-content-sm-between">
          <a href="tel:+19366626920">
            <img src={TelImage} alt="" />
            +1 936 662 6920
          </a>
          <a href="malito:contact@fastsemen.com">
            <img src={MailImage} alt="" />
            contact@fastsemen.com
          </a>
        </div>
        <div className="d-none d-md-flex align-items-center">
          <Link className="mx-xl-3 text-light my-sm-2 m-xl-0" to="/about-us">
            About us
          </Link>
          {/* <Link className="mx-xl-3 text-light my-sm-2 m-xl-0" to="/terms">
            Terms of use
          </Link> */}
          <OverlayTrigger
            overlay={popoverTerms}
            placement="bottom"
            trigger="click"
            style={{ outline: "none" }}
            show={openPopoverTerms}
          >
            <p
              className="mx-xl-3 text-light my-sm-2 m-xl-0"
              style={{ cursor: "pointer" }}
              onClick={() => setOpenPopoverTerms(!openPopoverTerms)}
            >
              Terms of use
            </p>
          </OverlayTrigger>
          <Link
            className="mx-xl-3 text-light my-sm-2 m-xl-0"
            to="/privacy-policy"
          >
            Privacy policy
          </Link>
          <a
            className="mx-xl-3 text-light my-sm-2 m-xl-0 link"
            target="_blank"
            rel="noopener noreferrer"
            href={stallionConsole}
          >
            Stallion Station Admin
          </a>
        </div>
      </div>

      <Navbar
        style={{ backgroundColor: "#2d2d2d", boxShadow: "3px 5px 8px #aaa" }}
        expand="xl"
        className="d-flex align-items-center py-2 px-0"
        variant="dark"
      >
        
        <Navbar.Brand href="/" style={{ backgroundColor: "#2d2d2d" }}>
          <img src={LogoImage} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => context.setShowAside(!context.showAside)}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          style={{ backgroundColor: "#fff" }}
        >
           
          <div
            className="m-sm-0 d-flex align-items-start align-items-xl-center flex-column flex-md-row w-10 col-12 p-0"
            style={{ backgroundColor: "#2d2d2d" }}
          >
            
            <Nav className="mx-sm-0 justify-content-between text-xl-center navbar d-flex flex-column flex-xl-row algin-items-start">
             
              <Link
                className="mx-md-3 text-light my-1 pl-3 align-self-start"
                to="/"
              >
                Home
              </Link>
              <Link
                className="mx-md-3 text-light my-1 pl-3 align-self-start"
                to="/contact"
              >
                Contact
              </Link>

              <div className="d-flex d-md-none flex-column align-items-start ml-0 pl-3">
                <OverlayTrigger
                  overlay={popoverTerms}
                  placement="bottom"
                  trigger="click"
                  style={{ outline: "none" }}
                  show={openPopoverTermsMobile}
                >
                  <p
                    className="mx-xl-3 text-light my-sm-2 m-xl-0"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpenPopoverTermsMobile(!openPopoverTermsMobile)}
                  >
                    Terms of use
                  </p>
                </OverlayTrigger>
                <Link
                  className="mx-xl-3 text-light my-1 m-xl-0 pl-1"
                  to="/about-us"
                >
                  About us
                </Link>
                <Link
                  className="mx-xl-3 text-light my-1 m-xl-0 pl-1"
                  to="/privacy-policy"
                >
                  Privacy policy
                </Link>
                <Link
                  className="mx-xl-3 text-light my-1 m-xl-0 pl-1"
                  to="/my-account"
                >
                  Stallion Station Admin
                </Link>
              </div>
            </Nav>
            <div className="d-flex ml-auto my-4 my-xl-0">
              <button className="d-flex align-items-center button mr-0">
                <img alt="" src={BellImage} style={{ width: 25, height: 25 }} />
              </button>
              {!localStorage.getItem("@TOKEN") && (
                <>
                  <OverlayTrigger
                    overlay={popover}
                    trigger="click"
                    placement="left" 
                    style={{ outline: "none" }}
                    show={openPopover}
                  >
                    <button
                      onClick={() => setOpenPopover(!openPopover)}
                      className="d-flex align-items-center button mx-3 text-light"
                      style={{ outline: "none" }}
                    >
                      <img src={UserDarkImage} alt="" className="mr-2" />
                      <strong>Login / Register</strong>
                    </button>
                  </OverlayTrigger>
                </>
              )}
              {localStorage.getItem("@TOKEN") && (
                <>
                  <button
                    className="d-flex align-items-center button mx-3 mt-4"
                    onClick={() => (window.location.href = "/cart")}
                  >
                    <img
                      src={CartImage}
                      alt=""
                      className="mb-4 align-self-center"
                      style={{ width: 25, height: 25 }}
                    />
                    <p className="text-left d-flex align flex-column text-light">
                      <strong className="m-0 ml-2">Cart</strong>
                      {context.carrinho >= 1 && <p>$ {context.carrinho}</p>}
                    </p>
                  </button>
                  <OverlayTrigger
                    overlay={popoverUser}
                    placement="bottom"
                    trigger="click"
                    style={{ outline: "none" }}
                    show={openPopoverUser}
                  >
                    <button
                      onClick={() => setOpenPopoverUser(!openPopoverUser)}
                      className="d-flex align-items-center button mx-3 text-light"
                      style={{ outline: "none" }}
                    >
                      <img src={UserDarkImage} alt="" className="mr-2" />
                      <strong>{context.username}</strong>
                    </button>
                  </OverlayTrigger>
                </>
              )}
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

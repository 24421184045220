import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm.js";
import { Container } from "./styles";
import Header from "../../Components/Header/index.js";
import Footer from "../../Components/Footer/index.js";
import { apiUrl } from "../../config/settings.json";
import { useLocation } from "react-router";
import { FunctionContext } from "../../context";
// import "./App.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
const stripePromise = loadStripe(
  "pk_test_51J0Q7rGyZdtWCCRHX59VbqanLWjxQFCX78FLHoTQ4Z2n5SEWsMzRCIxlnw4EfrloiT4A2XH5FLYveNVAZO2qqI3f00zkJlJpuV"
);

export default function Payment() {
  const context = useContext(FunctionContext);
  const [clientSecret, setClientSecret] = useState("");
  const location = useLocation();

  useEffect(() => {
    (async () => {
      // Create PaymentIntent as soon as the page loads
      await localStorage.setItem(
        "@PAYMENT_DATA",
        JSON.stringify({
          amount: {
            value: location.state.value * 100,
          },
          cart: !!location.state.cart ? true : false,
          cavalo: location.state.data,
        })
      );
      console.log({
        amount: {
          value: location.state.value * 100,
        },
        cart: !!location.state.cart ? true : false,
        cavalo: location.state.data,
      });
      fetch(`${apiUrl}create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: location.state.value * 100 }),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));

      console.log({
        amount: {
          currency: "USD",
          value: +location.state.value,
        },
        cart: !!location.state.cart ? true : false,
        cavalo: location.state.data,
      });
    })();
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      <Header />
      <Container>
        <div className="container my-5">
          <h2>Payment Method</h2>
          <div
            className="mt-5 p-4"
            style={{
              backgroundColor: "#fff",
              borderRadius: 15,
              boxShadow: " 0px 2px 6px #00000029",
            }}
          >
            <h3>Insert your card details!</h3>
            <div className="App">
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              )}
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
